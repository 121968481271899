/* ------------------------------------------------------------ *\
	Filters
\* ------------------------------------------------------------ */

.filters {
	margin-bottom: 48px; 
	position: relative; 
	z-index: 150; 

	.btn-close {
		position: absolute; 
		top: 7px; 
		right: 10px;
		display: none; 
	}

	h6 {
		font-size: 14px; 
		font-weight: 500; 
		text-transform: uppercase; 
		letter-spacing: 0.05em;
		color: #28306c;
		margin-bottom: 15px;
	}

	.filters__bar {
		display: flex;
		align-items: flex-start;

		.filter + .filter {
			margin-left: 47px;
		}
	}

	.filters__actions,
	.filters__body {
		display: none; 
	}
}

@media #{$tablet-portrait} {
	.filters {
		.filters__bar {
			.filter + .filter {
				margin-left: 15px;
			}
		}
	}
}

@media #{$mobile} {
	.filters {
		position: fixed; 
		top: 100%; 
		left: 0; 
		width: 100%; 
		height: 100vh; 
		overflow-y: auto;
		background: #fff; 
		opacity: 0; 
		visibility: hidden; 
		transition: opacity .4s, visibility .4s, top 1s .4s; 
		z-index: 250;
		padding: 42px 15px 0 15px; 

		h6 {
			font-size: 17px; 
			font-weight: 700;
			text-transform: none;
			margin-bottom: 18px;
		}

		.btn-close {
			display: block; 
		}

		.filters__bar {
			display: block;

			.filter__dropdown {
				display: none; 
			}

			.filter + .filter {
				margin: 25px 0 0; 
			}
		}

		.filters__body {
			display: block; 
			margin-top: 51px;

			.filter__dropdown {
				max-height: calc(100vh - 286px); 
				overflow-y: auto;
			}
		}

		&.active {
			opacity: 1; 
			visibility: visible; 
			top: 0;
			transition: opacity .4s, visibility .4s; 
		}

		&.filters-active {
			.filters__actions {
				display: block;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Filter
\* ------------------------------------------------------------ */

.filter {
	position: relative; 

	.filter__btn {
		display: inline-block; text-decoration: none;
		font-size: 14px; 
		color: #e84042; 
		letter-spacing: 0.05em;
		text-transform: uppercase;
		border: 2px solid #e84042;
		font-weight: 500;
		padding: 14px 17px; 
		transition: background .4s, color .4s; 

		strong {
			font-weight: 500;
		}

		span {
			display: inline-block; 
			margin-left: 13px;
			position: relative; 
			line-height: 0;
			
			i {
				width: 17px; 
				height: 10px;
				transition: opacity .4s; 

				+ i {
					position: absolute; 
					top: 0; 
					left: 0; 
					opacity: 0; 
				}
			}
		}
	}
	
	.filter__dropdown {
		position: absolute; 
		top: 100%; 
		left: 0; 
		width: 200px; 
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);
		background: #fff;  
		margin-top: 10px;
		opacity: 0; 
		visibility: hidden; 
		transition: opacity .4s, visibility .4s;
		max-height: 699px; 
		overflow-y: auto;

		.mCSB_scrollTools {
			opacity: 1 !important; 
			background: none !important; 
			width: 8px;
			right: 10px;

			.mCSB_dragger {
				width: 8px; 
				border-radius: 4px;
			}
		}

		.mCSB_dragger_bar {
			margin: 0;
			width: 8px;
			background: #474747 !important;
		}

		.mCSB_scrollTools .mCSB_draggerRail {
			width: 8px;
			background: none !important; 
		}

		ul {
			list-style: none;
			margin: 0;

			h6 {
				margin: 14px 17px; 
			}
		}

		h6 {
			margin: 15px 15px 10px; 
			font-size: 12px;
			letter-spacing: 0.05em;
		}

		li {
			border-top: 1px solid #e6e6e6;

			&:last-child {
				border-bottom: 1px solid #e6e6e6;
			}
		}

		.checkbox {
			&:hover {
				background: #e84042; 

				label {
					color: #fff; 

					&:after {
						border-color: #fff;
					}
				}
			}

			label {
				font-size: 12px; 
				letter-spacing: 0.05em;
				text-transform: uppercase; 
				padding-left: 45px;
				color: #e84042; 
				height: 40px; 
				display: flex;
				align-items: center; 
				transition: background .4s, color .4s; 

				&:after,
				&:before {
					top: 50%; 
					transform: translateY(-50%); 
					left: 16px;
				}

				&:after {
					border-color: #e84042;
					width: 14px; 
					height: 14px;
					transition: border-color .4s; 
				}

				&:before {
					width: 14px; 
					height: 14px;
					left: 16px;
					background: #fff; 
				}
			}

			input:checked {
				+ label {
					background: #e84042; 
					color: #fff; 
				}
			}
		}
	}

	.filter__actions {
		margin: 5px 20px 30px; 

		.link {
			font-size: 12px; 
		}

		p {
			margin: 0; 

			+ p {
				margin: 20px 0 0; 
			}
		}
	}

	/*  Filter Expanded  */
	&.expanded {
		.filter__btn {
			background: #e84042; 
			color: #fff; 

			span {
				i {
					opacity: 0; 

					+ i {
						opacity: 1; 
					}
				}
			}
		}

		.filter__dropdown {
			opacity: 1; 
			visibility: visible; 
		}
	}

	/*  Filter Active  */
	&.active {
		.filter__btn {
			background: #e84042; 
			color: #fff; 

			span {
				i {
					opacity: 0; 

					+ i {
						opacity: 1; 
					}
				}
			}
		}
	}

	/*  Filter City  */
	&--city {
		ul {
			.checkbox {
				label {
					&:after,
					&:before {
						left: 36px;
					}
				}	
			}
		}
	}
}

@media #{$mobile} {
	.filter {
		.filter__btn {
			display: block; 
			width: 100%;
			font-size: 11px; 
			padding: 8px 10px; 

			span {
				position: absolute; 
				top: 50%; 
				right: 13px; 
				transform: translateY(-50%); 
				margin: 0; 
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Filter Alt
\* ------------------------------------------------------------ */

.filter-alt {
	padding-top: 38px; 
	position: relative;
	background: #f6f6f6; 
	margin: 0 -15px; 
	display: none;

	.filter__bar {
		background: #e6e6e6; 
		position: absolute;
		top: 0; 
		left: 0;
		width: 100%;
		height: 38px;

		i {
			position: absolute; 
			top: 50%; 
			left: 10px; 
			width: 22px; 
			height: 14px; 
			background-size: 100% 100%; 
			transform: translateY(-50%); 
		}

		a {
			position: absolute; 
			top: 50%; 
			right: 15px; 
			transform: translateY(-50%); 
			color: #e03e3f; 
			font-weight: 700; 
			font-size: 11px;
			text-decoration: none;
		}  

		.ico-up {
			background-image: url(../images/temp/ico-angle-up-gray.png); 
			left: 17px; 

		} 

		.ico-down {
			background-image: url(../images/temp/ico-angle-down.png); 
			left: 55px;
		} 
	}

	.filter__dropdown {
		h6 {
			margin: 10px 27px;
			font-weight: 400;
			text-transform: uppercase; 
			font-size: 11px; 
		}

		ul {
			margin: 0;

			li + li {
				margin-top: -1px;
			}
		}

		.checkbox {
			label {
				border-top: 1px solid #efefef;
				border-bottom: 1px solid #efefef;
				padding: 9px 10px 9px 56px;
				color: #d93931; 
				text-transform: uppercase; 
				display: block;

				&:before {
					display: none; 
				}

				&:after {
					width: 20px; 
					height: 20px; 
					border-color: #d93931;
					top: 6px; 
					left: 27px;
					transition: background .4s;
				}
			}

			input:checked + label:after {
				background: #d93931;
			}
		}

		.filter__actions {
			display: none;
		}

		.mCSB_scrollTools {
			opacity: 1 !important; 
			background: none !important; 
			width: 6px;
			right: 5px;
			top: 10px;

			.mCSB_dragger {
				width: 6px; 
				border-radius: 4px;
			}
		}

		.mCSB_dragger_bar {
			margin: 0;
			width: 6px;
			background: #474747 !important;
		}

		.mCSB_scrollTools .mCSB_draggerRail {
			width: 6px;
			background: none !important; 
		}
	}

	&.active {
		display: block;
	}
}

// style for experiences-filters

.filters__btn_clear {
	margin: 0 0 0 47px;
	border: 2px solid #e84042;
	padding: 10px 24px;
	color: #e84042;
	background-color: #ffffff;
	font-size: 16px;
	text-transform: uppercase;
	line-height: 1.5;
}

@media (max-width: 767px) {
	.filters {
	& > h6 {
		margin: 20px 0;
	}
	  &__bar {
		& > .filter__country {
		  margin: 0 0 35px 0;
		}
		& > .filter__city {
		  margin: 0 0 35px 0 !important;      
		}
	  }
	  &__btn_clear {
		position: absolute;
		top: 62px;
		right: 16px;
		margin: 0 0 0 0;
		border: none;
		padding: 0 0 0 0;
		color: #28306c;
		background: none;
		font-size: 15px;
		font-weight: bold;
		text-transform: capitalize;
		cursor: pointer;
	  }
	}
  }

  @media (max-width: 1023px) {
	.filters__btn_clear {
		margin: 0 0 0 15px;
	}
  }