/* ------------------------------------------------------------ *\
	Widgets
\* ------------------------------------------------------------ */

.widgets {
	list-style: none outside none;
}

/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */

.widget {
	background: #D84E4A; 
	padding: 24px 30px 45px;

	.widget__ico {
		display: none;
	}


	.widget__head {
		margin-bottom: 30px;

		&-title {
			margin: 0; 
			font-size: 18px;
			font-weight: 700;

			@media screen and (max-width: 1200px){
				font-size: 16px;
			}

			@media screen and (max-width: 992px) {
				font-size: 14px;
			}
		}

		p {
			margin: 3px 0 0; 
			font-size: 12px; 
		}

		&--dark {
			color: #212020; 
			text-transform: uppercase;

			h6 {
				font-size: 14px;
			}
		}
	} 

	.article__body {
		a {
			text-decoration: none;
			text-align: left;
		}
	}

	.widget__image {
		padding: 0 11px;

		img {
			width: 100%;
		}
	}

	.widget__actions {
		margin: 54px 10px 0;

		.btn {
			width: 100%; 
			font-size: 15px;
		}

		&--primary {
			margin-top: 47px;
		}
	}

	/*  Widget Yellow  */
	&--yellow {
		background: #FFB74B; 
	}

	/*  Widget Blue  */
	&--blue {
		background: #29316A; 
		color: #ffffff; 
	}

	/*  Widget Green  */
	&--green {
		background: #128C8F; 
		color: #ffffff; 
	}
}

@media #{$small-desktop} {
	.widget {
		.widget__actions {
			.btn {
				white-space: normal;
			}
		}
	}
}

@media #{$tablet-portrait} {
	.widget {
		padding: 30px 15px; 

		.widget__image {
			padding: 0;
		}

		.widget__actions {
			.btn {
				font-size: 10px;
			}
		}
	}
}

@media #{$mobile} {
	.widget {
		padding: 46px 40px 60px; 
		position: relative; 
		overflow: hidden;

		.widget__head {
			position: relative; z-index: 5; 

			&-title {
				font-size: 18px;
			}

			&--dark {
				text-transform: none;

				h6 {
					margin-right: -5px;
				}
			}
		}

		.articles-small {
			position: relative;
			z-index: 5;
		}

		.widget__actions {
			margin: 42px 15px 0; 
			position: relative;
			z-index: 5;

			.btn {
				font-size: 15px;
				padding: 7px; 
			}

			&--primary {
				margin: 48px 0 0;
			}
		}

		.widget__ico {
			display: block; 
			position: absolute; 
			opacity: .21;

			&--size1 {
				top: 2%; 
				left: -87px;
			}

			&--size2 {
				top: 47%; 
				right: -120px;
			}

			&--size3 {
				bottom: -166px; 
				left: -130px;
			}
		}

		/*  Widget Blue  */
		&--blue {
			background: #fff; 
			color: #29316A; 
		}
	}
}

/* ------------------------------------------------------------ *\
	Widget Primary
\* ------------------------------------------------------------ */

.widget-primary {
	color: #fff; 

	h1 {
		font-size: 32px; 
		letter-spacing: 0.05em;
		text-transform: uppercase;
		margin-bottom: 38px;

		span {
			color: #fab64e; 
		}
	}
}

@media #{$mobile} {
	.widget-primary {
		h1 {
			font-size: 19px;
			text-align: center; 
			margin-bottom: 20px;
		}

		.form-location {
			padding: 0 35px 0 30px;

			.select {

				&:after {
					background-image: url(../../_build/assets/images/temp/ico-angle-down-white.png); 
					width: 18px; 
					height: 10px;
				}

				select {
					background: none; 
					color: #fff; 
					border: 1px solid #fff;
					height: 35px;
					padding: 0 13px;
					font-size: 13px; 
					font-weight: 500; 
				}
			}
		}
	}	
}


/* ------------------------------------------------------------ *\
	Widget Alt
\* ------------------------------------------------------------ */

.widget-alt {
	.checkbox {
		margin-left: 30px;
		margin-top: 17px;
	}

	.widget__actions {
		margin: 45px 28px 28px; 
		display: flex; 
		justify-content: space-between;

		
	}

	/*  Widget Alt Primary  */
	&--primary {
		padding: 25px 26px 25px 19px; 

		.list-radios {
			margin-bottom: 0;

			li:last-child {
				.radio {
					label {
						&:after,
						&:before {
							margin-top: -4px;
						}
					}		
				}
			}	
		}

		.widget__actions {
			margin: 44px 0 0; 
		}
	}

	/*  Widget Alt Secondary  */
	&--secondary {
		padding: 18px 23px 25px 27px; 

		.widget__actions {
			margin: 39px 0 0; 
		}
	}

	/*  Widget Alt Teritary  */
	&--teritary {
		padding: 23px 25px 25px 27px; 

		.widget__actions {
			margin: 49px 0 0; 
		}
	}
}

.widget-message {
	position: relative; 
	color: #fff; 

	+ .widget-message {
		margin-top: 40px;
	}

	h3 {
		font-weight: 700;
		margin-bottom: 18px;
	}

	h3, h6 {
		font-size: 1.2rem;
	}

	.widget__content {
		position: absolute; 
		top: 70px; 
		left: 50%; 
		width: 100%;
		max-width: 300px; 
		transform: translateX(-50%);
		padding-left: 7px;

		&--primary {
			top: 57px;
		}
	}
}

@media #{$mobile} {
	.widget-message {

		+ .widget-message {
			margin-top: 23px;
		}

		h3 {
			font-size: 13px; 
		}

		p {
			font-size: 10px;
		}
		.widget__content {
			padding: 0 7px 0 0;
			top: 30px; 

			&--primary {
				top: 25px; 
				padding: 0 0 0 4px; 
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Widget Secondary
\* ------------------------------------------------------------ */

.widget-secondary {
	position: relative; 
	text-align: center;

	h2 {
		margin: 0; 

		+ .form-location {
			margin-top: 24px;
		}
	}

	.form-location {
		margin: auto; 
		max-width: 450px; 
	}

	.widget__ico {
		position: absolute; 

		&--size1 {
			top: 35px; 
			left: 235px; 
		}

		&--size2 {
			top: -93px; 
			right: 146px; 
		}
	}
}

@media #{$large-desktop} {
	.widget-secondary {
		.widget__ico {
			&--size1 {
				left: 135px; 
			}

			&--size2 {
				right: 46px; 
			}
		}
	}
}

@media #{$small-desktop} {
	.widget-secondary {
		.widget__ico {
			&--size1 {
				left: 35px; 
			}

			&--size2 {
				right: 6px; 
			}
		}
	}
}

@media #{$tablet-portrait} {
	.widget-secondary {
		.widget__ico {
			&--size1 {
				left: -40px; 
			}

			&--size2 {
				right: -75px; 
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Widget Teritary
\* ------------------------------------------------------------ */

.widget-teritary {
	background: #29316A; 
	color: #fff; 
	text-align: center; 
	padding: 40px 20px 37px; 

	h4 {
		font-size: 18px; 
		font-weight: 700; 
		margin-bottom: 12px; 
	}

	img {
		width: 100%;
	}

	p {
		font-size: 14px; 
		line-height: 1.3;
		margin: 0;

		strong {
			font-weight: 700;
		}
	}

	h6 {
		color: #FFB74B; 
		font-size: 14px; 
		font-weight: 700; 
		font-family: 'Helvetica', sans-serif;
		margin: 14px 0 0;  
	}

	.btn {
		margin: 7px 0 0; 
	}

	.separator {
		display: inline-block; 
		width: 10px; 
		text-align: center;
	}

	.widget__image {
		max-width: 410px; 
		margin: 0 auto 20px; 
	}
}

@media #{$tablet-portrait} {
	.widget-teritary {
		br {
			display: none; 
		}

		p {
			strong {
				display: block; 
			}
		}
	}
}

@media #{$mobile} {
	.widget-teritary {
		padding: 27px 19px 34px; 

		h6 {
			margin: 20px 0 0; 
		}

		p {
			strong {
				display: inline; 
			}
		}

		.btn {
			margin: 11px 0 0; 
			min-width: 208px;
		}

		.widget__image {
			margin: 0 auto 10px; 
		}
	}
}

/* ------------------------------------------------------------ *\
	Widget Total
\* ------------------------------------------------------------ */

.widget-total {
	color: #343333; 

	ul {
		list-style: none; 
		margin: 0;

		h6 {
			font-size: 20px; 
			font-weight: 500;
			margin: 0; 

			strong {
				display: block; 
				font-weight: 700; 
			}
		}

		li {
			+ li {
				margin-top: 24px;
			}
		}

		.primary {
			display: flex;
			align-items: flex-start;

			h6 {
				flex: 0 0 45%;
				padding-right: 10px;
			}
		}
	}

	.widget__title {
		margin: 0 0 49px; 
		font-weight: 500;
		letter-spacing: 0;
	}

	.widget__foot {
		border-top: 1px solid #707070;
		border-bottom: 1px solid #707070;
		padding: 25px 0 32px 34px; 
		margin: 48px 3px 0 -30px; 

		h3 {
			margin: 0; 
			font-weight: 700;

			span {
				margin-left: 80px;
			}
		}
	}

	.form-code {
		margin-left: -30px;
		margin-top: 38px;
	}

}

@media #{$small-desktop} {
	.widget-total {
		padding-left: 0 !important;

		ul {
			h6 {
				font-size: 19px;
			}
		}

		.widget__foot {
			h3 {
				font-size: 20px;
			}
		}
	}
}

@media #{$tablet-portrait} {
	.widget-total {
		ul {
			h6 {
				font-size: 17px;
			}
		}

		.widget__foot {
			margin-left: 0; 
			margin-right: 0; 
			padding: 25px 0; 

			h3 {
				font-size: 17px;
			}
		}

		.form-payment {
			margin-left: 0;
		}
	}
}

@media #{$mobile} {
	.widget-total {
		ul {
			h6 {
				font-size: 15px;
			}
		}

		.widget__title {
			margin-bottom: 30px;
		}

		.widget__foot {
			h3 {
				font-size: 15px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Widget Map
\* ------------------------------------------------------------ */

.widget-map {
	background: #FFB74B; 
	padding: 14px 18px;

	img {
		width: 100%; 
	}
}
