.feature-list i {
  margin-bottom: 2vh;
}

.module-review-a-local {
  margin-bottom: 5vh;

  .row {
    margin: 0;
  }

  .form-resource {
    width: 100%;
    position: relative;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 5px 15px 20px 3px #65475a;
    overflow: hidden;

    .review-title {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 3vh auto;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      background-color: var(--red-basic);
      z-index: 5;

      & > h3 {
        font-size: 30px;
        color: var(--white);
        text-shadow: 1px 0 3px #071381;

        @media only screen and (max-width: 768px) {
          font-size: 19px;
        }
      }

      & > .ico-circles {
        width: 80px;
        height: 26px;
        margin-left: 10px;
        opacity: .5;

        @media only screen and (max-width: 768px) {
          width: 48px;
          height: 15px;
          margin-left: 6px;
        }
      }
    }

    label,
    label + span {
      color: var(--gray-dark);
    }

    input,
    textarea {
      display: block;
      width: 100%;
      min-height: 40px;
      padding: 6px 12px;
      font-size: 16px;
      border: 1px solid #707070;
      border-radius: 5px;
      color: #646464;
      text-overflow: ellipsis;
    }

    .review-user-data {
      margin: 0 auto;

      .user-data-wrap {
        display: flex;
        flex-direction: column;

        .datepicker-label {
          margin-bottom: 2vh;
        }

        .radio-stars-group {
          min-height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          text-align: center;
          margin-top: 3vh;

          label {
            margin: 0;
            position: relative;
          }

          input[type=radio] {
            display: none;
          }

          label::before {
            content: '';
            width: 15px;
            height: 15px;
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            border: 2px solid var(--red-basic);
            border-radius: 50%;
          }

          label::after {
            content: '';
            width: 5px;
            height: 5px;
            position: absolute;
            top: -15px;
            left: 50%;
            transform: translateX(-50%);
            background-color: var(--red-basic);
            border-radius: 50%;
            display: none;
          }

          input[type='radio']:checked + label::after {
            display: block;
          }
        }
      }

      p {
        min-height: 15px;
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 0;
        color: var(--red-basic);
      }
    }

    & > i {
      opacity: .5;
      position: absolute;

      @media only screen and (max-width: 576px) {
          display: none;
        }
    }

    & > .icon-cirlce-f {
      width: 176px;
      height: 196px;
      top: -20px;
      left: -60px;
    }

    & > .icon-cirlce-s {
      width: 117px;
      height: 130px;
      top: 300px;
      right: -20px;
    }

    & > .icon-cirlce-t {
      width: 58px;
      height: 65px;
      bottom: -10px;
      left: 30px;
    }

    .btn-lg {
      color: var(--red-basic);
      border: 3px solid #e84042;
      background-color: var(--white);

      &:hover,
      &:focus,
      &:active {
        color: var(--white);
        background-color: var(--red-basic);
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .radio-stars-group .fa-star {
    font-size: 15px;
  }
}

@media only screen and (max-width: 365px) {
  .radio-stars-group .fa-star {
    font-size: 11px;
  }
}

.consul-addReview {

  .form-resource {
    background-color: var(--white);
    box-shadow: none;
  }

  .consul-userData {
    margin-bottom: 3vh;

    .article__body > p {
      min-height: auto;
    }
  }
}

.js-anchor{
  height: 0px;
  margin: 0px;
  padding: 0px;
}