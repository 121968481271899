/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	color: #f63e3c !important;
	position: relative;

	.our-info {
		padding: 50px 0;
	}

	&__category-title {
		display: inline-block;
		margin-bottom: 16px;
	}

	.btn-chat {
		position: fixed;
		bottom: 0;
		right: 50%;
		margin-right: -721px;
		z-index: 200;
	}

	.footer__nav {
		a {
			text-decoration: none;
			letter-spacing: 0.025em;
			display: inline-block;
			position: relative;

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				height: 1px;
				background: #f63e3c;
				transition: width 0.4s;
			}

			&:hover {
				color: #f63e3c;

				&:after {
					width: 100%;
				}
			}
		}

		li {
			+ li {
				margin-top: 9px;
			}
		}
	}

	.footer__content {
		padding-top: 3px;

		.socials {
			margin: 20px 0 0;
		}
	}

	.our-cities {
		width: 100%;
		padding: 50px 0;
		background-color: var(--blue-basic);

		&__title {
			margin: 0 0 50px 0;
			color: #ffffff;
			font-size: 26px;
			font-weight: bold;
		}

		.our-cities-list {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			width: inherit;
			height: 200px;
			@media only screen and (max-width: 991px) {
				height: 250px;
			}
			@media only screen and (max-width: 767px) {
				height: 300px;
			}
			@media only screen and (max-width: 500px) {
				height: 600px;
			}

			li {
				width: 100px;
				margin: 0 7% 2px 0;
				color: #ffffff;
				font-size: 16px;
				list-style: none;

				&.continent {
					margin: 10px 7% 10px 0;
					color: var(--yellow-basic);
					font-size: 18px;
				}

				&:first-child {
					margin: 0 7% 10px 0;
				}

				a {
					text-decoration: none;

					&:hover {
						color: #ffffff;
						text-decoration: underline;
					}
				}
			}
		}
	}
}

@media #{$large-desktop} {
	.footer {
		.btn-chat {
			margin: 0;
			right: 15px;
		}
	}
}

@media #{$small-desktop} {
	.footer {
		.btn-chat {
			margin: 0 -555px 0 0;
			right: 50%;
		}
	}
}

@media (max-width: 1199px) {
	.footer {
		.btn-chat {
			margin: 0 -465px 0 0;
		}
	}
}

@media (max-width: 992px) {
	.footer {
		.btn-chat {
			margin: 0 -345px 0 0;
		}
	}
}

@media #{$mobile} {
	.footer {
		&__category-title {
			font-size: 13px;
			margin-bottom: 8px;
		}

		.btn-chat {
			bottom: 12px;
			z-index: 300;
			margin-right: -255px;
		}

		.col-lg-2 + .col-lg-2 {
			margin-top: 15px;
		}

		.footer__nav {
			font-size: 13px;

			li {
				+ li {
					margin-top: 6px;
				}
			}
		}

		.footer__content {
			padding: 0 0 18px;

			.socials {
				margin: 10px 0 0;

				ul {
					margin: 0;
					font-size: 28px;
				}
			}
		}
	}
}

@media (max-width: 576px) {
	.footer {
		.btn-chat {
			margin: 0;
			right: 15px;
		}
	}
}
