/* ------------------------------------------------------------ *\
	Steps
\* ------------------------------------------------------------ */

.steps {
	position: relative; 
	padding-left: 167px;

	&:after {
		content: '';
		position: absolute; 
		top: 1px; 
		bottom: 501px; 
		left: 43px;
		width: 4px; 
		background: #e6e6e6; 
	}

	&--primary {
		padding-left: 0 !important;

		&:after {
			display: none;
		} 
	}

	&--primary-alt {
		.step {
			+ .step {
				margin-top: 110px;	
			}

			&--primary {
				.step__body {
					padding: 0 60px 0 0; 
				}
			}
		}
	}
}

@media #{$tablet-portrait} {
	.steps {
		padding-left: 130px;
		
		&:after {
			bottom: 360px; 
		}
	}
}

@media #{$mobile} {
	.steps {
		padding-left: 38px;

		&:after {
			width: 2px; 
			left: 11px; 
			bottom: 290px; 
			bottom: 14%;
		}

		&--primary-alt {
			.step + .step {
				margin-top: 80px;
			}
		}
	}
}

@media(max-width: 465px){
	.steps {
		&:after {
			bottom: 13%;
		}
	}
}

/* ------------------------------------------------------------ *\
	Step
\* ------------------------------------------------------------ */

.step {
	position: relative; 
	z-index: 10;

	+ .step {
		margin-top: 96px;
	}

	.step__label {
		position: absolute; 
		top: -33px; 
		left: -167px;

		i {
			transform: rotate(-45deg); 
		}

		span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%); 
			color: #fff;
			font-size: 30px; 
			font-weight: 700; 
		} 
	}

	.step__image {
		position: relative; 
		max-width: 613px;
		max-height: 400px;

		&:after {
			content: '';
			position: absolute; 
			top: 0; 
			left: 0; 
			width: calc(100% - 5px); 
			height: 100%; 
			background: #118c8f;
			transform: translate(32px, -34px);  
			transition: opacity .4s, visibility .4s, transform .4s; 
			opacity: 0; 
			visibility: hidden; 
		}

		img {
			width: 100%;
			position: relative; 
			top: -34px; 
			left: 32px;
			z-index: 5;
		}
	}

	.step__body {
		color: #28306c; 
		padding-top: 39px;

		h3 {
			font-weight: 700; 
			line-height: 1.2;
			margin-bottom: 20px; 
		}

		p {
			margin: 0; 

			+ p {
				margin-top: 20px;
			}
		}
	}

	&--yellow {
		.step__image:after {
			background: #fab64e; 
		}

		.step__label {
			i {
				transform: rotate(119deg); 
			} 
		}
	}

	&--red {
		.step__image:after {
			background: #e84042; 
		}
	}

	&--blue {
		.step__image:after {
			background: #28306c; 
		}

		.step__label {
			i {
				transform: rotate(119deg); 
			} 
		}
	}

	&.animated {
		.step__image {
			&:after {
				transform: translate(0);
				opacity: 1; 
				visibility: visible;  
			}
		}
	}

	/*  Step Alt  */
	&--alt {
		display: flex;
		align-items: center; 

		.step__body {
			padding: 0 0 0 108px; 
			margin-top: -50px;

			p {
				font-size: 1.2rem;
				line-height: 1.2; 
				margin-bottom: 0; 
				letter-spacing: 0;

				em {
					font-weight: 500; 
					font-style: italic; 
					letter-spacing: 0.045em;
				}

				+ p {
					margin-top: 27px;
				}
			}

			&--primary {
				margin-top: -70px;
			}
		}

		.step__image {
			flex: 0 0 612px;
			&:after {
				height: calc(100% + 16px); 
			}
		}

		+ .step--alt {
			margin-top: 120px;
		}
	}

	/*  Step Alt  */
	&--alt-primary {
		justify-content: flex-end;
		padding-right: 35px; 

		.step__image {
			order: 1;
		}

		.step__body {
			padding: 0 135px 0 0; 

			&--primary {
				padding: 0 68px 0 0; 
			}
		}
	}
}

@media #{$large-desktop} {
	.step {
		&--alt {
			br {
				display: none;
			}

			.step__body,
			.step__image {
				flex: 0 0 50%;
				max-width: 50%;
			}
		}
	}
}

@media #{$tablet-portrait} {
	.step {
		.step__label {
			left: -130px; 
		}

		.step__image {
			img {
				left: 20px; 
				top: -20px;
			}
		}

		&--alt {
			.step__body {
				padding: 0 0 0 70px; 

				&--primary {
					margin-top: 0;
				}
			}

			.step__image {
				&:after {
					height: 100%;
				}
			}
		}

		&--alt-primary {
			.step__body {
				padding: 0 70px 0 0; 
			}
		}
			

	}
}

@media #{$mobile} {
	.step {

		+ .step {
			margin-top: 56px;
		}

		.step__image {
			margin-right: 12px;

			&:after {
				width: 100%;
				transform: translate(12px, -12px);
			}

			img {
				left: 12px; 
				top: -12px;
			}
		}

		.step__label {
			top: -48px;
			left: -73px; 

			i {
				transform: scale(.26) rotate(-45deg); 
			}

			span {
				font-size: 13px; 
				margin-top: -2px;
			}
		}


		.step__body {
			padding-top: 23px; 

			h3 {
				font-size: 13px; 
				margin-bottom: 17px;
			}

			p {
				margin-right: -4px;

				+ p {
					margin-top: 15px; 
				}
			}
		}

		&--alt {
			display: block; 
			padding: 0 20px; 

			.step__image,
			.step__body {
				max-width: 100%;
				margin: 0; 
			}

			.step__body {
				padding: 15px 0 0; 

				p {
					font-size: 13px;
				}
			}

			.step__image {
				margin-right: 9px;

				&:after {
					height: 100%;
				}
			}

			+ .step--alt {
				margin-top: 80px;
			}
		}
	}
}