/* ------------------------------------------------------------ *\
	animation Canvas;
\* ------------------------------------------------------------ */
#canvas_animation {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.canvas_animation-circle {
  display: none;
}