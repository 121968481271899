/* ------------------------------------------------------------ *\
	Link
\* ------------------------------------------------------------ */

.link {
	text-decoration: none; 
	font-weight: 700; 
	font-size: 14px; 

	&--alt {
		color: #e84042; 
		text-transform: uppercase; 
	}
}
