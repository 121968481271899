/* ------------------------------------------------------------ *\
	Search
\* ------------------------------------------------------------ */

.search {
	background: #fff; 
	padding: 10px 50px 10px 10px; 

	.search__field {
		width: 100%; 
		border: 1px solid #707070;
		border-radius: 2px; 
		height: 45px;
		padding: 0 15px; 
	}

	.search__btn {
		background: none; 
		border: none;
		position: absolute; 
		top: 50%; 
		right: 10px; 
		transform: translateY(-50%);
	}
}

@media #{$mobile} {
	.search {
		padding: 10px 40px 10px 15px; 

		.search__field {
			height: 35px;
		}

		.search__btn {
			right: 15px;
		}
	}
}
