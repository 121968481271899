/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */

[class^="list-"] {
	list-style: none outside none;
}

/* ------------------------------------------------------------ *\
	List Stars
\* ------------------------------------------------------------ */

.list-stars {
	li {
		display: inline-block; 
		vertical-align: middle;
		letter-spacing: 0.025em;

		 + li {
		 	margin-left: 5px;
		 }
	}
}

/* ------------------------------------------------------------ *\
	List Circles
\* ------------------------------------------------------------ */

.list-circles {
	white-space: nowrap;

	li {
		display: inline-block; 

		+ li {
			margin-left: 72px;
		}
	}
}

@media #{$mobile} {
	.list-circles {
		li {
			+ li {
				margin-left: 20px;
			}
		}
	}	
}

/* ------------------------------------------------------------ *\
	List Features
\* ------------------------------------------------------------ */

.list-features {
	a {
		text-decoration: none; 
		color: #fff !important;
		transition: padding-left .4s;  

		&:hover {
			padding-left: 10px;
			cursor: pointer;
		}
	}

	strong {
		position: relative; 
		display: inline-block; 
		padding-left: 55px;
		min-width: 190px;

		i {
			position: absolute; 
			top: 50%; 
			left: 0; 
			transform: translateY(-50%);
			font-size: 30px;

			@media screen and (max-width: 767px) {
				font-size: 24px;
			}
		}
	}

	& > li:last-child {
		i {
			margin-left: 5px;
		}
	}

	em {
		position: relative; 
		display: inline-block;
		font-style: normal;
		padding-left: 55px; 
		font-weight: 700;

		i {
			position: absolute; 
			top: 50%; 
			left: 0; 
			transform: translateY(-50%);
		}
	}

	li {
		+ li {
			margin-top: 25px;
		}
	}
}

@media #{$large-desktop} {
	.list-features {
		li {
			display: flex;
			align-items: flex-start;
		}

		em {
			min-width: 155px;
		}
	}
}

@media #{$mobile} {
	.list-features {
		li {
			+ li {
				margin-top: 30px;
			}
		}
		
		em,
		strong {
			padding-left: 44px; 
			font-size: 13px;
			min-width: 135px; 
		}

		span {
			font-size: 13px;
			line-height: 1.2;
			margin-right: -5px;
		}
	}
}	

/* ------------------------------------------------------------ *\
	List Cities
\* ------------------------------------------------------------ */

.list-cities {
	list-style: none; 
	font-size: 18px;
	line-height: 1.56;
	letter-spacing: 0.025em; 

	a {
		text-decoration: none; 
		display: inline-block; 
		position: relative; 

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0;
			height: 1px;
			background: #fff;
			transition: width .4s;
		}

		&:hover {
			color: #fff; 

			&:after {
				width: 100%;
			}
		}
	}

	ul {
		list-style: none; 

		span {
			text-transform: uppercase; 
			font-weight: 700;
		}
	}

	> li {
		+ li {
			margin-top: 36px;
		}
	}
}


/* ------------------------------------------------------------ *\
	List Gadgets
\* ------------------------------------------------------------ */
.list-gadgets {
		li {
			padding-left: 23px;
			position: relative;

			i {
				position: absolute;
				top: 50%;
				left: 7px;
				transform: translate(-50%, -50%);
			}

			+ li {
				margin-top: 11px;
			}

			@media screen and (min-width: 768px) {
				& {
					display: inline-block;
					padding-left: 0;
					color: var(--green-basic);
				}

				i {
					display: none;
				}

				& + li {
					margin-top: 0;
				}

				span {
					font-size: 1.1rem;
				}
			}
		}
}

/* ------------------------------------------------------------ *\
	List Services
\* ------------------------------------------------------------ */

.list-services {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px -40px !important; 
	padding: 0 0 60px; 
	text-align: center; 
	line-height: 1.2; 
	font-weight: 700; 
	color: #28306c;

	li {
		flex: 0 0 20%;
		max-width: 20%;
		padding: 53px 10px 40px; 
		position: relative;
		word-break: break-word;
	}

	i {
		position: absolute;
		top: 20px;
		left: 50%;
		transform: translate(-50%, -50%);  
	}
}

@media #{$small-desktop} {
	.list-services {
		padding: 0 0 60px;
	}
}

@media #{$mobile} {
	.list-services {
		padding: 0 10px 60px;

		li {
			flex: 0 0 50%;
			max-width: 50%;
			padding: 47px 10px 22px; 
		}
	}	
}

/* ------------------------------------------------------------ *\
	List Circles Alt
\* ------------------------------------------------------------ */

.list-circles-alt {
	li {
		position: relative; 
		padding-left: 62px;


		i {
			position: absolute; 
			top: 50%; 
			left: 0; 
			transform: translateY(-50%); 
		}

		+ li {
			margin-top: 38px;
		}

		&:nth-child(2) {
			i {
				transform: translateY(-50%) rotate(17deg); 
			}
		}

		&:nth-child(3) {
			i {
				transform: translateY(-50%) rotate(152deg); 
			}
		}
	}
}

@media #{$mobile} {
	.list-circles-alt {
		li {
			padding-left: 28px; 

			i {
				top: -7px;
				left: -8px;
				transform: translateY(0) scale(.6); 
			}

			+ li {
				margin: 22px 0 0; 
			}

			&:nth-child(2) {
				i {
					transform: scale(.6) rotate(17deg); 
				}
			}

			&:nth-child(3) {
				i {
					transform: scale(.6) rotate(152deg); 
				}
			}
		}
	}
}


/* ------------------------------------------------------------ *\
	LisT Dots
\* ------------------------------------------------------------ */

.list-dots {
	letter-spacing: 0.025em;
	
	li {
		position: relative; 
		padding-left: 14px;

		&:after {
			content: '';
			position: absolute; 
			top: 8px; 
			left: 0; 
			width: 5px; 
			height: 5px;  
			background: #28306c; 
			border-radius: 50%;
		}
	}
}

@media #{$mobile} {
	.list-dots {
		li {
			padding-left: 8px; 

			&:after {
				width: 3px; 
				height: 3px; 
				top: 6px;
				left: 3px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	List Radios
\* ------------------------------------------------------------ */

.list-radios {
	li {
		+ li {
			margin-top: 34px;
		}
	}
}

/* ------------------------------------------------------------ *\
	List Details
\* ------------------------------------------------------------ */

.list-details {
	li {
		display: flex; 
		justify-content: space-between;
		align-items: center; 
		
		+ li {
			margin-top: 29px;
		}

		&:last-child {
			margin-top: 34px;
		}
	}

	h6 {
		font-size: 11px; 
		margin: 0;

		span {
			display: block;
			font-size: 14px; 
		}
	}

	h5 {
		font-weight: 700;
		font-size: 14px; 
		letter-spacing: 0.04em;
	}
}

/* ------------------------------------------------------------ *\
	List Sliders
\* ------------------------------------------------------------ */

.list-sliders {
	h6 {
		display: flex; 
		justify-content: space-between;
		align-items: flex-start;
		font-size: 14px; 
		font-weight: 500;
		letter-spacing: 0.025em;
		margin-bottom: 17px;

		span {
			+ span {
				text-align: right; 
			}
		}
	}

	> li + li {
		margin-top: 33px;
	}
}

/* ------------------------------------------------------------ *\
	List Locations
\* ------------------------------------------------------------ */

.list-locations {
	li {
		position: relative; 
		padding-left: 39px; 

		i {
			position: absolute;
			top: 45%;
			left: 15px;
			transform: translate(-50%, -50%);
			font-size: 30px;

			@media screen and (max-width: 767px) {
				font-size: 25px;
			}
		}

		.fa-comment-o {
			transform: translate(-50%, -50%) scale(-1, 1);
		}

		+ li {
			margin-top: 30px;
		}

		&:last-child {
			padding-left: 0;
		}
	}

	img {
		vertical-align: middle;
		margin-right: 7px;
	}
}

@media #{$mobile} {
	.list-locations {
		li {
			padding-left: 30px; 
			letter-spacing: 0.025em;

			img {
				max-width: 100px;
				margin-right: 5px;
			}

			+ li {
				margin-top: 16px; 
			}

			&:last-child {
				padding-left: 0;
				margin-top: 13px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	List Features Alt
\* ------------------------------------------------------------ */

.list-features-alt {
	li {
		width: 50%;

		+ li {
			margin-top: 130px;
		}

		&:nth-child(odd) {
			margin-left: auto;
			padding-left: 17px;
		}

		&:nth-child(1) {
			padding-left: 17px;
		}

		&:nth-child(2) {
			padding-left: 195px;
		}

		&:nth-child(3) {
			margin-top: 197px;
		}

		&:nth-child(4) {
			margin-top: 81px;
			padding-left: 173px;
		}
	}

	h2 {
		margin: 25px 0 0; 
	}
}

@media #{$large-desktop} {
	.list-features-alt {
		position: relative; 
		z-index: 10; 

		br {
			display: none;
		}

		+ li {
			margin-top: 83px;
		}


		&:nth-child(2) {
			padding-left: 125px;
		}

		&:nth-child(3) {
			margin-top: 126px;
		}

		&:nth-child(4) {
			margin-top: 51px;
		}
	}
}

@media #{$tablet-portrait} {
	.list-features-alt {
		li {
			width: 60%;
			
			&:nth-child(2) {
				margin-top: 90px;
				padding-left: 0; 
			}

			&:nth-child(3) {
				margin-top: 90px;
			}

			&:nth-child(4) {
				margin-top: 90px;
				padding-left: 0; 
			}
		}
	}
}

@media #{$mobile} {
	.list-features-alt {
		li {
			width: 60%;

			&:nth-child(1) {
				padding-left: 5px; 
			}
			
			&:nth-child(2) {
				margin: 67px 0 0 37px;
				padding-right: 20px;
			}

			&:nth-child(3) {
				margin-top: 65px; 
				padding: 0;
				width: 71%;
			}

			&:nth-child(4) {
				margin: 53px 0 0 43px;
				padding-left: 0; 
				width: 62%;
			}
		}

		h2 {
			margin: 12px 0 0; 
		}
	}
}

/* ------------------------------------------------------------ *\
	List Steps
\* ------------------------------------------------------------ */

.list-steps {
	display: flex;
	margin: 0 -10px -10px; 
	text-align: center; 
	flex-wrap: wrap;

	span {
		display: inline-block; 
		background-repeat: no-repeat;
		background-position: center center;  
		width: 76px; 
		height: 74px; 
		font-size: 32px; 
		font-weight: 700; 
		margin-bottom: 18px;
		padding: 21px 0 0; 
	}

	h3 {
		font-weight: 700; 
		margin-bottom: 18px;
	}

	li {
		flex: 0 0 25%;
		max-width: 25%;
		padding: 0 10px 10px; 
	}
}

@media #{$large-desktop} {
	.list-steps {
		br {
			display: none; 
		}
	}
}

@media #{$tablet-portrait} {
	.list-steps {
		li {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
}

@media #{$mobile} {
	.list-steps {
		display: block; 
		margin: 0;

		li {
			max-width: 100%; 
			padding: 0; 

			+ li {
				margin: 30px 0 0; 
			}
		}

		span {
			font-size: 23px; 
			width: 58px; 
			height: 56px; 
			background-size: 90% 90%; 
			padding: 13px 0 0; 
		}

		h3 {
			margin-bottom: 10px;
		}
	}
}

/* ------------------------------------------------------------ *\
	List Services Alt
\* ------------------------------------------------------------ */

.list-services-alt {
	display: flex;
	margin: 0 -37px -65px; 
	text-align: center; 
	flex-wrap: wrap;
	justify-content: center;

	li {
		flex: 0 0 20%;
		max-width: 20%;
		padding: 65px 10px 65px; 
		position: relative; 

		i {
			position: absolute;
			top: 0px;
			left: 50%;
			transform: translate(-50%, -50%);  
		}
	}

	h3 {
		font-weight: 700; 
		margin-bottom: 17px;
	}

	p {
		margin: 0; 

		+ .btn {
			margin: 56px 0 0; 
		}
	}
}

@media #{$large-desktop} {
	.list-services-alt {
		margin: 0 -10px -65px; 
		
		br {
			display: none; 
		}
	}
}

@media #{$tablet-portrait} {
	.list-services-alt {
		li {
			flex: 0 0 33.33%;
			max-width: 33.33%;
		}
	}
}

@media #{$mobile} {
	.list-services-alt {
		margin: 0 -10px -65px;

		li {
			flex: 0 0 50%;
			max-width: 50%;
			padding-top: 50px; 
		}

		h3 {
			margin-bottom: 10px;
		}
	}
}

/* ------------------------------------------------------------ *\
	List Links
\* ------------------------------------------------------------ */

.list-links {
	li {
		display: inline-block; 
		vertical-align: middle;

		+ li {
			margin-left: 3px;
		}
	}
}