.how-it-works {
	width: 100%;
	padding: 50px 0 70px 0;
	background-color: #fafafa;

	@media screen and (min-width: 768px) {
		background-color: #fff;
		padding: 70px 15px;
	}

	.container {
		padding: 0 5px;
	}

	&__title {
		margin-bottom: 5vh;
		text-align: center;
		font-size: 2rem;
		font-weight: bold;

		@media screen and (min-width: 768px) {
			font-size: 1.75rem;
			text-align: left;
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		list-style-type: none;

		@media screen and (min-width: 768px) {
			flex-direction: row;
			justify-content: space-between;
			padding: 0 20px;
			margin-bottom: 20px;
		}

		&__item {
			position: relative;
			display: flex;
			justify-content: space-between;
			width: 100%;
			padding: 6vw;
			animation-duration: .5s;
			animation-timing-function: ease-out;
			animation-fill-mode: forwards;

			@media screen and (min-width: 768px) {
				flex-direction: column-reverse;
				justify-content: flex-end;
				width: 30%;
				padding: 10px;
				animation-name: orangeLayoutDesktop;
			}

			@media screen and (min-width: 992px) {
				padding: 30px 20px;
			}

			@media screen and (min-width: 1200px) {
				width: 28%;
			}

			&::before {
				content: '';
				width: 95%;
				height: 5px;
				position: absolute;
				top: 5px;
				left: 50%;
				transform: translateX(-50%);
				animation-name: orangeLayoutMobile;
				animation-duration: .5s;
				animation-timing-function: ease-out;
				animation-fill-mode: forwards;

				@media screen and (min-width: 768px) {
					display: none;
				}
			}

			&-block {
				flex-shrink: 0;
				max-width: 190px;
				color: #fff;

				@media screen and (min-width: 768px) {
					max-width: initial;
				}


				&:first-child {
					@media screen and (min-width: 768px) {
						position: absolute;
						top: 30%;
						transform: translateY(-50%);
					}
				}

				&:nth-child(2) {
					@media screen and (min-width: 768px) {
						max-width: initial;
						margin-right: 1vw;
					}
				}

				&:nth-child(3) {
					@media screen and (min-width: 768px) {
						padding-left: 55px;
					}
				}

				& > strong {
					display: block;
					margin-bottom: .5rem;
					font-size: 1.5rem;
					font-weight: bold;

					@media screen and (min-width: 768px) {
						margin: 1vh 0;
						font-size: 1.375rem;
					}
				}

				& > span {
					display: block;
					font-size: 1.2rem;
					line-height: 1;

					@media screen and (min-width: 768px) {
						display: initial;
						padding-right: 5px;
						font-size: 1.1875rem;
						line-height: 1.2;
					}
				}

				& > b {
					display: inline-block;
					font-size: 4.167rem;
					margin-top: -5px;

					@media screen and (min-width: 768px) {
						font-size: 7.5rem;
						font-weight: normal;
					}
				}

				& > img {
					height: 83px;

					@media screen and (min-width: 768px) {
						height: 108px;
					}
				}
			}

			&-block:nth-child(2) {
				margin-left: 5vw;

				@media screen and (min-width: 768px) {
					margin-left: 0;
				}
			}

			.triangular-label {
				display: none;
				position: absolute;
				right: -135px;
				top: 50%;
				border: 100px solid transparent;
				width: 0;
				height: 0;
				transform: translateY(-50%);

				@media screen and (min-width: 1200px) {
					display: block;
				}
			}
		}

		&__item:not(:last-child) {
			margin-bottom: 60px;

			@media screen and (min-width: 768px) {
				margin-bottom: 0;
			}

			&::after {
				content: '';
				position: absolute;
				bottom: -20px;
				left: 50%;
				width: 40px;
				height: 40px;
				background-color: inherit;
				transform: translateX(-50%) rotate(45deg);

				@media screen and (min-width: 768px) {
					display: none;
				}
			}
		}

		&__item:first-child {
			.triangular-label {
				border-left: 50px solid var(--blue-basic);
			}
		}

		&__item:nth-child(2) {
			.triangular-label {
				border-left: 50px solid var(--green-basic);
			}
		}
	}
}

@keyframes orangeLayoutMobile {
	100% {
		box-shadow: 0 -10px 0 0 #fab64e;
	}
}

@keyframes orangeLayoutDesktop {
	100% {
		box-shadow: -20px 20px 0 0 #fab64e;
	}
}