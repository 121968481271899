/* ------------------------------------------------------------ *\
	Testimonials
\* ------------------------------------------------------------ */

.testimonials {
	.row {
		margin: 0 -23px;
	}

	.col-lg-4 {
		padding: 0 23px; 
	}
}

@media #{$large-desktop} {
	.testimonials {
		.row {
			margin: 0 -15px;
		}

		.col-lg-4 {
			padding: 0 15px; 
		}
	}	
}

@media #{$tablet-portrait} {
	.testimonials {
		margin-bottom: -30px;
		
		.col-md-6 {
			margin-bottom: 30px;
		}
	}
}

@media #{$mobile} {
	.testimonials {
		margin-bottom: -25px;
		
		.col-md-6 {
			margin-bottom: 25px;
		}
	}
} 

/* ------------------------------------------------------------ *\
	Testimonial
\* ------------------------------------------------------------ */

.testimonial {
	background: #fff; 
	color: #28306c;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);

	.testimonial__head {
		position: relative;
		padding: 20px 36px 10px 36px;  

		a {
			display: block;
			margin: 0 0 19px 0;
			font-weight: bold;
			text-decoration: none;
		}

		p {
			font-size: 14px; 
			line-height: 1.2; 
			letter-spacing: 0;
			margin: 0;

			.fa-star {
				font-size: 15px;
			}

			.separator {
				text-align: center; 
				display: inline-block; 
				width: 17px;
			}
		}

		.testimonial__image {
			border-radius: 50%; 
			overflow: hidden; 
			width: 100px; 
			height: 100px; 
			position: absolute; 
			top: 50%; 
			left: 34px; 
			transform: translateY(-50%);
			margin-top: 10px;

			img {
				width: 100%;
			}
		}
	}

	.testimonial__content {
		margin: 14px 33px 0; 
		padding: 20px 2px 36px; 

		.review-title {
			display: inline-block;
			margin-bottom: 20px;
			font-weight: 700; 
			letter-spacing: 0.04em;
			font-family: Poppins-SemiBold, sans-serif;
			 & > a {
				 display: inline-block;
			 }
		}

		p {
			margin: 0;
			word-break: break-word;
			font-family: Poppins, sans-serif;

			+ p {
				margin: 20px 0 0; 
			}
		}

		.list-stars {
			margin-bottom: 24px;
		}

		.testimonial__created {
			font-size: 14px;
		}

		.testimonial__author {
			font-size: 14px; 
			margin: 5px 0 0; 
			letter-spacing: 0;

			span a {
				text-decoration: none;
			}
		}
	}
}

.reviews-host {
	.testimonial__head {
		a {
			margin: 0
		}
	}
}

.reviews-city,
.reviews-host {
	.testimonial__head {
		padding: 20px 36px 10px 36px;

		a {
			font-weight: normal;
		}
	}

	.testimonial__content {
		border-top: 2px solid #e6e6e6;

		& > p {
			margin: 0 0 20px 0;
		}
	}
}

@media #{$small-desktop} {
	.testimonial {
		.testimonial__head {
			.testimonial__image {
				width: 80px; 
				height: 80px;
			}
		}
	}
}

@media #{$mobile} {
	.testimonial {
		.testimonial__head {
			a {
				font-size: 14px; 
				margin-bottom: 12px;
			}

			p {
				font-size: 10px; 

				.separator {
					width: 11px; 
				}
			}
			
			.testimonial__image {
				width: 48px; 
				height: 48px;
			}
		}

		.testimonial__content {
			border-width: 1px;
			margin: 9px 33px 0; 
			padding: 13px 0 50px;

			.review-title {
				margin-bottom: 16px;
			}

			.list-stars {
				margin-bottom: 19px;
			}

			.testimonial__author {
				font-size: 12px; 
				margin: 10px 0 0; 
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Testimonial Alt
\* ------------------------------------------------------------ */

.testimonial-alt {
	display: flex;
	align-items: center; 
	font-size: 14px; 
	line-height: 1.29; 

	span {
		font-size: 14px; 
		line-height: 1.29; 
		font-weight: 500; 
		margin: 0;

		strong {
			display: block; 
			font-weight: 700; 
		}
	}

	p {
		color: #707070; 
		margin: 0;
	}

	.testimonial__image {
		width: 70px; 
		height: 70px; 
		border-radius: 50%; 
		overflow: hidden; 

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.testimonial__content {
		padding-left: 19px;
		position: relative; 
		top: 6px;
	}
}

@media #{$mobile} {
	.testimonial-alt {
		span {
			font-size: 12px; 
			margin-bottom: 3px;

			strong {
				margin-bottom: 3px;
			}
		}

		p {
			font-size: 12px; 
		}

		.testimonial__image {
			width: 50px; 
			height: 50px; 
		}

		.testimonial__content {
			top: 0; 
			padding-left: 10px;
		}
	}
}
