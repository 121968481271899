.content-page-carousel-block {
    position: relative;
    width: 100%;
    padding: 35px 0;

    @media screen and (min-width: 768px) {
        padding: 50px 0;
    }

    & .icon-cirlce-g1 {
        position: absolute;
        top: 31px;
        right: 12px;
    }

    &__title {
        margin-bottom: 30px;
        font-weight: bold;
        text-align: center;

        @media screen and (min-width: 768px) {
            margin-bottom: 45px;
            text-align: left;
        }
    }

    &.carousel-content-page {
        width: 100%;
        padding-bottom: 50px;

        @media screen and (min-width: 768px) {
            padding-bottom: 100px;
        }

        a {
            padding: 0 6px;
            color: #ffffff;
            text-decoration: none;

            &:hover,
            &:focus,
            &:active {
                color: #fff;
            }
        }

        .favorite-article-icon-box {
            right: 20px;
        }

        &.carousel-item-block {
            border-radius: 5px;
            overflow: hidden;

            &__img {
                width: 100%;
                height: 200px;
                background-color: #f6f6f6;

                & > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &.carousel-text-block {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                height: 120px;
                padding: 20px 15px;
                background-color: #fab64e;

                @media screen and (min-width: 768px) {
                    height: 150px;
                }

                &__title {
                    font-size: 1.5rem;
                    text-transform: capitalize;
                    line-height: 1;
                    font-weight: bold;

                    @media screen and (min-width: 768px) {
                        height: 150px;
                    }
                }

                & > span {
                    font-size: 1.0625rem;
                    text-transform: uppercase;
                    text-decoration: underline;
                    transition: 0.4s;

                    &:hover,
                    &:focus,
                    &:active {
                        color: #0056b3;
                    }
                }
            }
        }

        .slick-arrow {
            top: 30%;
            transform: translateY(0%);
        }
    }
}
