.host-form-description {

  .btn--red {
    text-transform: uppercase;
    margin: 23px 0;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 11px 0;
    }

    .arrows-group {
      margin-left: 20px;

      .ico-angle-down {
        display: none;
      }

      .ico-angle-down,
      .ico-angle-down-white {
        width: 20px;
        height: 10px;
      }
    }
  }

  button:hover,
  button:focus,
  button:active{
    .arrows-group .ico-angle-down {
      display: inline-block;
    }

    .arrows-group .ico-angle-down-white {
      display: none;
    }
  }

}

.thank-you__sucscess-message {
  min-height: 60vh;
  padding: 6vh 3vw;
  background-color: #f6f6f6;

  .see-expresses-btn-block {
    margin-top: 38px;
  }

  @media screen and (max-width: 991px) {
    .btn--red {
      font-size: 14px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

// first star centered to each others
.list-locations {
  .list-stars li:first-child {
    margin-top: 30px;

    @media screen and (max-width: 767px) {
      margin-top: 16px;
    }
  }
}

// smooth transparency change for textarea placeholder:
.placeholder-smooth-opacity::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
.placeholder-smooth-opacity::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
.placeholder-smooth-opacity:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
.placeholder-smooth-opacity:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
.placeholder-smooth-opacity:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
.placeholder-smooth-opacity:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
.placeholder-smooth-opacity:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
.placeholder-smooth-opacity:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}

// standard color for the error text
.form-alert-danger {
  color: var(--red-basic);
}