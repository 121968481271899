/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
	display: inline-block; 
	background: url('../../_build/assets/images/logo.png') no-repeat 0 0;
	width: 153px;
	height: 66px; 
	background-size: 100% 100%; 

	&--size1 {
		width: 160px; 
		height: 70px;
	}
}

.logo-secondary {
	display: inline-block; 
	background: url(../../_build/assets/images/logo-secondary.png) no-repeat 0 0;
	width: 175px;
	height: 76px; 
	background-size: 100% 100%; 
}


@media #{$tablet-portrait} {
	.logo-secondary {
		width: 145px;
		height: 63px; 
	}
}


@media #{$mobile} {
	.logo {
		width: 68px;
		height: 29px; 		
	}

	.logo-secondary {
		width: 87px;
		height: 38px; 
	}
}
