.hosting-restart-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    color: #FFFFFF;
    padding: 60px 30px 0 30px;
    text-align: center;

    @media screen and (min-width: 768px) {
        padding-top: 120px
    }

    @media screen and (min-width: 1024px) {
        justify-content: space-between;
    }

    @media screen and (min-width: 1440px) {
        width: 1200px;
        padding-top: 160px;
    }

    .content-holder {
        margin: 20px auto;
        width: 95%;

        @media screen and (min-width: 1024px) {
            margin: 60px auto;
            width: 60%;
        }

        @media screen and (min-width: 1200px) {
            margin: 10px auto;
            width: 75%;
        }

        @media screen and (min-width: 1440px) {
            margin: 10px auto;
            width: 85%;
        }

        .title {
            font-family: SysFalso-Italic, Poppins-Medium, Arial, sans-serif;
            display: block;
            font-weight: bold;
            font-size: 30px;
            margin: 20px auto 10px auto;

            @media screen and (min-width: 1024px) {
                font-size: 54px;
                margin: 20px auto 40px auto;
            }

            @media screen and (min-width: 1200px) {
                font-size: 60px;
                margin: 60px auto 50px auto;
            }

            @media screen and (min-width: 1440px) {
                font-size: 70px;
            }
        }

        .sub-title {
            font-size: 20px;
            line-height: 27px;
            margin: 40px auto 55px auto;

            @media screen and (min-width: 1024px) {
                font-size: 30px;
                line-height: 1.2;
                margin: 30px auto 55px auto;
            }

            @media screen and (min-width: 1440px) {
                font-size: 40px;
                line-height: 45px;
            }
        }

        .circles {
            display: flex;
            justify-content: center;
            margin-bottom: 25px;

            .circle {
                width: 45px;
                height: 48px;

                @media screen and (min-width: 1024px) {
                    width: 60px;
                    height: 65px;
                }

                @media screen and (min-width: 1440px) {
                    width: 75px;
                    height: 80px;
                }
            }

            .red-circle {
                background: url($page-404 + '#red-circle') center / contain no-repeat;
            }

            .green-circle {
                background: url($page-404 + '#green-circle') center / contain no-repeat;
            }

            .yellow-circle {
                background: url($page-404 + '#yellow-circle') center / contain no-repeat;
            }
        }

        .doodle {
            display: none;
            background: url('/theme/frontend/_assets/images/world-collage.svg') center / contain no-repeat;

            @media screen and (min-width: 1024px) {
                display: initial;
                position: absolute;
                right: 25px;
                bottom: 50px;
                width: 250px;
                height: 160px;
            }
            @media screen and (min-width: 1200px) {
                width: 312px;
                height: 180px;
                bottom: 0;
                right: 0;
            }

            @media screen and (min-width: 1440px) {
                width: 352px;
                height: 200px;
                bottom: 150px;
            }
        }
    }
}