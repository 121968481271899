.section-def {
  .mobile-experience-item {
    padding: 5vh;
    margin: 3vh 0;
    text-align: center;
    background-color: var(--blue-basic);
    color: #fff;

    .article-small {
      & > a {
        text-decoration: none;

        & > h4 {
          font-size: 2rem;
          font-weight: bold;
          margin-bottom: 2vh;
        }
      }

      .article__image {
        img {
          height: 150px;
        }
      }

      & > h6 {
        font-size: 1.3rem;
        font-weight: bold;
        margin-top: 2vh;
      }

      .article__body {
        p:first-child {
          display: none;
        }
      }
    }

    .see-expresses__btn {
      display: inline-block;
      margin-top: 3vh;
      padding: 1vh 2vh;
      color: #fff;
      border: 2px solid var(--red-basic);
      background-color: var(--red-basic);
      text-transform: uppercase;
      text-decoration: none;

      &:hover,
      &:focus,
      &:active {
        background-color: #fff;
        color: var(--red-basic);
      }
    }
  }

  #gtx-trans {
    visibility: hidden;
  }

  ul, ol {
    list-style-position: inside;
  }
}

