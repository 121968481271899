// ===== logo travel agent =====
.ta-box {
	position: absolute;
	top: 50%;
	right: 15px;
	transform: translate(0, -50%);

	.ta-open-btn {
		border: 0;
		background-color: transparent;
		cursor: pointer;

		.ta-icon {
			display: block;
			width: 22px;
			height: 22px;
			margin: 0 auto 4px;
			background: url('../../_build/assets/images/travel-agent-icon-blue.svg') no-repeat;
			background-size: cover;
		}

		.ta-name {
			margin: 0;
			color: var(--color-primary);
			font-size: 12px;
			font-weight: normal;
			text-align: center;
		}
	}

	.ta-content {
		position: absolute;
		top: 60px;
		right: -10px;
		display: none;
		width: 100px;
		border-radius: 3px;
		padding: 10px;
		background-color: #ffffff;
		text-align: center;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);

		&.show {
			display: block;
		}

		ul {
			margin: 0;
			padding: 0;

			li {
				list-style: none;

				.ta-logout-btn {
					border: 0;
					background-color: transparent;
					color: var(--color-primary);

					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.ta-box {
		right: 15px;

		.ta-open-btn {
			.ta-icon {
				color: #ffffff;
				background: url('../../_build/assets/images/travel-agent-icon-white.svg') no-repeat;
				background-size: cover;
			}

			.ta-name {
				color: #ffffff;
			}
		}

		.ta-content {
			position: absolute;
			top: 60px;
			right: -40px;
		}
	}

	.header {
		&.fixed {
			.ta-box {
				.ta-open-btn {
					.ta-icon {
						color: var(--color-primary);
						background: url('../../_build/assets/images/travel-agent-icon-blue.svg') no-repeat;
						background-size: cover;
					}

					.ta-name {
						color: var(--color-primary);
					}
				}
			}
		}
	}
}

// ===== popup travel agent =====
.popup-ta {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 10;
	transform: translate(-50%, -50%);
	max-width: 350px;
	width: 90%;
	padding: 80px 20px 20px 20px;
	border-radius: 3px;
	background-color: #ffffff;
	text-align: center;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

	.icon-box {
		position: absolute;
		top: -55px;
		left: 50%;
		transform: translate(-50%, 0);
		width: 110px;
		height: 110px;
		background-color: var(--blue-basic);
		border-radius: 50%;

		.lock-icon {
			display: inline-block;
			width: 65px;
			height: 80px;
			margin: 12px 0 0 12px;
			background: url('../../_build/assets/images/lock-icon.png') no-repeat;
			background-size: contain;
		}
	}

	.error-message {
		color: var(--red-basic);
		font-size: 14px;
	}

	// ===== popup success =====
	&.success {
		display: none;

		&.show {
			display: block;
		}

		.title {
			margin: 0 0 30px 0;
			color: var(--green-basic);
			font-family: 'Professor';
			font-size: 28px;
			font-weight: normal;
		}

		.text {
			margin: 0 0 10px 0;

			.name-agency {
				color: var(--green-basic);
			}
		}

		.message {
			margin: 0 0 30px 0;
		}

		.popup-start-booking-btn {
			display: block;
			width: 250px;
			margin: 0 auto 20px;
			border: 2px solid var(--red-basic);
			padding: 8px 0;
			color: #ffffff;
			background-color: var(--red-basic);
			text-transform: uppercase;

			&:hover {
				color: var(--red-basic);
				background-color: #ffffff;
			}
		}
	}

	// ===== popup log-in ====
	&.log-in {
		display: none;

		&.show {
			display: block;
		}

		.title {
			margin: 0 0 30px 0;
			font-size: 18px;
		}

		.field {
			width: 100%;
			margin: 0 0 10px 0;
			text-align: left;

			input {
				width: 100%;
				border: 1px solid #707070;
				padding: 10px;
				color: #707070;
				font-size: 14px;
			}
		}

		.ta-login-btn {
			display: block;
			width: 100%;
			margin: 20px 0 5px 0;
			border: 2px solid var(--red-basic);
			padding: 8px 0;
			color: #ffffff;
			background-color: var(--red-basic);
			text-transform: uppercase;

			&:hover {
				color: var(--red-basic);
				background-color: #ffffff;
			}
		}

		.ta-forgot-password-btn {
			display: block;
			margin: 0 0 10px 0;
			color: var(--blue-basic);
			font-size: 14px;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.ta-register-btn {
			display: block;
			margin: 0 0 10px 0;
			color: var(--red-basic);
			text-transform: uppercase;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.popup-ta-close-btn {
		display: block;
		margin: 0 auto;
		border: 0;
		color: var(--blue-basic);
		background: transparent;
		font-size: 14px;
		text-transform: uppercase;

		&:hover {
			color: #118c8f;
		}
	}
}

@media screen and (min-width: 992px) {
	.popup-ta {
		max-width: 540px;
		padding: 80px 40px 20px 40px;
		font-size: 18px;

		&.success {
			.title {
				font-size: 30px;
			}
		}

		&.log-in {
			.title {
				font-size: 24px;
			}

			.field {
				margin: 0 0 15px 0;
				input {
					padding: 14px 10px;
					font-size: 18px;
				}
			}

			.ta-login-btn {
				margin: 40px 0 5px 0;
				padding: 13px 0;
			}

			.ta-forgot-password-btn {
				margin: 0 0 20px 0;
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	.popup-ta {
		max-width: 600px;
		font-size: 20px;

		.icon-box {
			position: absolute;
			top: -75px;
			width: 150px;
			height: 150px;

			.lock-icon {
				margin: 34px 0 0 12px;
			}
		}

		&.success {
			.title {
				font-size: 40px;
			}
		}

		.popup-ta-close-btn {
			font-size: 18px;
		}
	}
}

@media screen and (min-width: 1600px) {
	.popup-ta {
		max-width: 700px;
		font-size: 24px;

		&.success {
			.title {
				font-size: 50px;
			}
		}
	}
}
