.mainPopup {
    display: none;
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.popup-content {
    background-color: var(--color-neutral-lightest);
    margin: 50px auto;
    padding: 20px;
    border: 1px solid var(--color-neutral-light);
    width: 90%;

    &.request-host-form-content {
        width: 80%;
        padding: 10px;
    }

    &.thank-you-request-host-content, &.request-host-form-message {
        width: 80%;
        padding: 20px;
    }

}

@media only screen and (min-width: 768px) {
    .popup-content {
        width: 60%;

        &.small {
            width: 40%;
        }

        &.login-desktop {
            top: 50%;
            left: 50%;
            animation: slide-in 1s forwards;
            -webkit-animation: slide-in 1s forwards;
        }

        &.slide-in {
            position: absolute;
            top: calc(50% - 60px);
            left: 100%;
            transform: translate(-50%, -50%);
            -webkit-animation: slide-in 1s forwards;
            animation: slideInLeft 1s forwards;
        }

        @keyframes slideInLeft {
            0% {
                left: 100%;
            }
            100% {
                left: 50%;
            }
        }

        &.with-scroll {
            max-height: 750px;
            overflow: auto;
        }

        &.request-host-form-content {
            width: 51%;
        }

        &.thank-you-request-host-content, &.request-host-form-message {
            width: 60%;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .popup-content {
        width: 60%;

        &.small {
            width: 52%;
        }

        &.request-host-form-content {
            width: 900px;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .popup-content {

        &.small {
            width: 41%;
        }

        &.login-desktop {
            width: 500px;
            padding: 10px;
        }

        &.request-host-form-content {
            width: 1025px;
            padding: 15px;
        }

        &.thank-you-request-host-content, &.request-host-form-message {
            width: 60%;
        }
    }
}

@media only screen and (min-width: 1366px) {
    .popup-content {

        &.request-host-form-content {
            width: 1115px;
        }
    }
}

@media only screen and (min-width: 1440px) {
    .popup-content {

        &.request-host-form-content {
            width: 1185px;
        }
    }
}

@media only screen and (min-width: 1600px) {
    .popup-content {

        &.request-host-form-content {
            width: 1275px;
        }
    }
}