// ===== suitcase page =====

.suitcase-page {

    .suitcase-main {
        padding-top: 150px;
        background-color: var(--grey-light-basic);

        .container {
            position: relative;
        }

        .btn-yellow {
            font-family: Poppins-Regular, sans-serif;
            display: inline-block;
            margin: 0 0 50px 0;
            border: 2px solid var(--yellow-basic);
            padding: 10px 20px;
            background-color: var(--yellow-basic);
            font-size: 21px;
            text-decoration: none;
            text-transform: uppercase;
            transition: 0.5s;

            &:hover {
                color: var(--yellow-basic);
                background: var(--blue-basic);
            }
        }

        .back-link {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 5px;
            text-decoration: none;
            margin-top: -40px;

            .arrow-back {
                display: flex;
                width: 10px;
                height: 10px;
                box-sizing: border-box;
                border-left: 2px solid var(--blue-basic);
                border-bottom: 2px solid var(--blue-basic);
                transform: rotate(45deg);
            }

            .text-back {
                display: flex;
                font-size: 20px;
                font-family: Poppins-Medium, sans-serif;
                color: var(--blue-basic);
            }
        }

        .return-back {
            display: block;
            width: 270px;
            margin: 0 auto;
            border: 2px solid var(--yellow-basic);
            padding: 10px 20px;
            font-family: Poppins, sans-serif;
            background-color: var(--yellow-basic);
            font-size: 21px;
            text-decoration: none;
            text-transform: uppercase;
            transition: 0.5s;

            &:hover {
                color: var(--yellow-basic);
                background: var(--blue-basic);
            }
        }

        .suitcase-title {
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                font-family: Poppins-Bold, sans-serif;
                margin: 45px 0 20px;
                font-size: 30px;
            }
        }

        .suitcase-table {
            width: 100%;
            margin: 0 0 100px 0;
            border-collapse: collapse;
            border-spacing: 0;

            tr.booking {
                padding: 10px;
                border-radius: 5px;

                th,
                td {
                    padding: 5px;
                }
            }

            .continue-shopping {
                display: block;
                font-family: Poppins-Medium, sans-serif;
                font-size: 17px;
                text-decoration: none;
                text-align: center;
                width: 180px;
                border-bottom: 1px solid var(--blue-basic);
                margin: 0 auto 25px auto;
                padding-bottom: 2px;
                transition: .3s;

                &:hover {
                    color: var(--yellow-basic);
                    border-color: var(--yellow-basic);
                }
            }

            tbody {
                tr.booking {
                    position: relative;
                    box-shadow: 0 3px 6px #00000029;
                    background-color: #ffffff;
                    opacity: 1;

                    .additional-block {
                        padding: 25px 45px 0 0;

                        .change-people-wrapper {

                            .sel-box {
                                margin-right: 15px;

                                input {
                                    width: 150px;
                                    font-size: 14px;
                                    height: 35px
                                }
                                .sel-icon {
                                    width: 15px;
                                    height: 21px;
                                    right: 10px;
                                }
                            }
                        }
                    }

                    td {

                        &:nth-child(2) {
                            text-align: left;
                        }

                        .booking-img {
                            width: 180px;
                            height: 180px;
                            overflow: hidden;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .delete-booking {
                            display: inline-block;
                            font-size: 24px;
                            color: var(--blue-basic);
                            cursor: pointer;
                            transition: 0.5s;
                            background: url($suitcase-page-sprite + '#icon-close-blue')
                            no-repeat;
                            width: 21px;
                            height: 21px;
                            position: absolute;
                            top: 10px;
                            right: 10px;

                            &:hover {
                                background: url($suitcase-page-sprite + '#icon-close-yellow')
                                no-repeat;
                            }
                        }

                        .list-info {
                            font-family: Poppins-Medium, sans-serif;
                            font-size: 18px;
                            color: var(--blue-basic);
                            opacity: 1;
                            padding: 0;
                            list-style: none;
                            margin-right: 25px;

                            li {
                                display: flex;
                                margin-bottom: 8px;

                                &.addOn-booking {
                                    display: flex;
                                    align-items: center;
                                    margin: 10px 0 0;

                                    .icon {
                                        width: 135px;
                                        height: 35px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        padding: 5px;
                                        margin-right: 10px;
                                        background-color: var(--green-basic);

                                        img {
                                            max-height: 25px;
                                        }
                                    }
                                }

                                h3 {
                                    display: inline-block;
                                    margin: 0;
                                    font-size: 20px;
                                    font-weight: bold;
                                    width: 90%;
                                    overflow-wrap: anywhere;
                                }

                                i {
                                    display: inline-block;
                                    width: 25px;
                                    height: 25px;
                                    margin: 0 10px 0 0;
                                    color: var(--green-basic);
                                    font-size: 16px;
                                    font-weight: normal;
                                    text-align: center;
                                    vertical-align: top;
                                }

                                .icon-container {
                                    width: 24px;
                                    margin-right: 10px;

                                    .star-icon {
                                        background: url($suitcase-page-sprite + '#star-icon')
                                            no-repeat;
                                    }

                                    .pin-icon {
                                        background: url($suitcase-page-sprite + '#pin-icon')
                                            no-repeat;
                                    }

                                    .time-icon {
                                        background: url($suitcase-page-sprite + '#time-icon')
                                            no-repeat;
                                    }
                                    .calendar-icon {
                                        background: url($suitcase-page-sprite + '#calendar-icon')
                                            no-repeat;
                                    }

                                    .clock_yellow-icon {
                                        background: url($suitcase-page-sprite + '#icon-clock_yellow')
                                            no-repeat;
                                    }

                                    .check_yellow-icon {
                                        background: url($suitcase-page-sprite + '#icon-down')
                                            no-repeat;
                                    }
                                }
                            }
                        }

                        .price-booking {
                            text-align: center;
                            vertical-align: top;

                            & > span {
                                font-family: Poppins-Bold, sans-serif;
                                font-size: 18px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 150px;
                                padding: 5px;
                                font-weight: bold;
                                border: 1px solid var(--blue-basic);
                                background-color: var(--blue-basic);
                                color: var(--white);
                            }
                        }
                        .desktop-only {
                            display: none;
                        }

                        &.price-booking {
                            text-align: center;
                            vertical-align: top;

                            & > span {
                                font-family: Poppins-Bold;
                                font-size: 20px;
                                display: none;
                                width: 176px;
                                height: 40px;
                                margin: 0 auto 25px;
                                padding: 10px;
                                font-weight: bold;
                                border: 1px solid var(--blue-basic);
                                background-color: var(--blue-basic);
                                color: var(--white);
                            }
                        }
                    }
                }

                .addOn-title {
                    display: block;
                    border-top: 1px solid #b4b4b4;

                    tr,
                    td {
                        display: block;
                        background-color: var(--grey-light-basic);
                    }

                    td {
                        padding-top: 15px;

                        h3 {
                            font-size: 18px;
                            font-weight: bold;
                            text-align: left;
                            margin-bottom: 20px;
                            font-family: Poppins-Medium, sans-serif;
                        }
                    }
                }

                .addOn-list {
                    &__item {
                        padding: 15px;
                        margin-bottom: 25px;
                        border: 1px solid #b4b4b4;
                        border-radius: 3px;
                        text-align: center;

                        .icon {
                            height: 120px;
                            width: 120px;
                            padding: 10px;
                            margin: 0 auto 15px;
                            background-color: var(--green-basic);

                            img {
                                height: 100%;
                            }
                        }

                        .title {
                            strong {
                                display: block;
                                margin-bottom: 5px;
                            }

                            p {
                                margin-bottom: 0;
                            }
                        }

                        .user-info {
                            padding-top: 20px;
                            margin-bottom: 20px;

                            .user-select {
                                position: relative;
                                max-width: 185px;
                                margin: 0 auto 15px;

                                & > p {
                                    position: relative;
                                    padding: 10px 0;
                                    margin-bottom: 0;
                                    border: 1px solid var(--blue-basic);
                                    border-radius: 3px;

                                    &::after {
                                        position: absolute;
                                        top: 50%;
                                        right: 10px;
                                        font-family: FontAwesome, sans-serif;
                                        font-weight: bold;
                                        font-size: 20px;
                                        line-height: 0;
                                        content: '\f107';
                                        transform: translateY(-50%);
                                        transition: 0.4s;
                                    }
                                }

                                &__list {
                                    display: none;
                                    position: absolute;
                                    top: 105%;
                                    left: 0;
                                    width: 100%;
                                    margin: 0;
                                    border: 1px solid var(--blue-basic);
                                    border-radius: 3px;
                                    background-color: var(--white);
                                    color: var(--blue-basic);
                                    box-shadow: 0 0 5px 0 #00000080;

                                    input[type='radio'] {
                                        display: none;
                                    }

                                    label {
                                        width: 100%;
                                        padding: 10px;
                                        margin-bottom: 0;
                                        transition: 0.4s;

                                        &:hover,
                                        &:focus,
                                        &:active {
                                            color: var(--white);
                                            background-color: var(--blue-basic);
                                        }
                                    }

                                    input[type='radio']:checked + label {
                                        color: var(--white);
                                        background-color: var(--blue-basic);
                                    }
                                }

                                &.open-select {
                                    .user-select__list {
                                        display: block;
                                    }

                                    & > p::after {
                                        transform: rotate(180deg);
                                    }
                                }
                            }

                            .addOn-total {
                                display: inline-block;
                                font-size: 20px;
                            }
                        }

                        .add-addOn__btn {
                            display: block;
                            width: 270px;
                            padding: 10px 0;
                            margin: 0 auto;
                            font-size: 18px;
                            font-weight: 400;
                            border: 1px solid var(--red-basic);
                            color: var(--red-basic);
                            background-color: var(--white);
                            transition: 0.5s;

                            &:hover,
                            &:focus,
                            &:active {
                                color: var(--white);
                                background-color: var(--red-basic);
                            }
                        }
                    }
                }

                .total-section__description-top {
                    background-color: var(--white);
                    padding: 10px;
                    box-shadow: 0 3px 6px #00000029;

                    td {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 50px 0 30px 0;
                        margin: 10px;

                        .price {
                            font-family: Poppins-Bold, sans-serif;
                            font-size: 1.8rem;
                        }

                        .btn-yellow {
                            margin: 0 0 20px 0;
                            font-size: 16px;
                            padding: 10px 45px
                        }
                    }

                    @media screen and (min-width: 1200px) {
                        display: none;
                    }
                }
            }

            .total-section {
                tr {
                    box-shadow: 0 3px 6px #00000029;

                    td {
                        text-align: center;

                        .secure-container {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .secure-description {
                                font-family: Poppins-Bold, sans-serif;
                                font-size: 23px;
                            }
                        }

                        .buyer-information {
                            border-top: 1px solid #b4b4b4;
                            padding-top: 30px;
                            padding-bottom: 30px;
                        }
                    }

                    &.total-section__title {
                        padding: 0;
                        margin-bottom: 0;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                        background-color: var(--blue-basic);
                        color: var(--white);

                        strong {
                            font-size: 1.8rem;
                        }
                    }

                    &.total-section__description {
                        border-top-right-radius: 0;
                        border-top-left-radius: 0;
                        background-color: #ffffff;

                        .total-price__description {
                            font-family: Poppins, sans-serif;
                            font-size: 21px;
                        }

                        .total-due {
                            font-size: 21px;
                        }

                        .suitcase-booking-total {
                            margin-bottom: 25px;
                            font-family: Poppins-Bold, sans-serif;
                            font-size: 30px;

                            & > span {
                                font-weight: bold;
                                font-size: 1.8rem;
                            }
                        }
                    }
                }

                &__secure {
                    margin-bottom: 25px;
                }

                &-desktop {
                    display: none;

                    .total-section__description {
                        td {
                            display: flex;
                            flex-direction: column;
                        }
                        
                    }

                    .total-section__cases {
                        position: absolute;
                        top: 0;
                        right: 0;
                        background: transparent;
                        box-shadow: none;
                    }
                }

                &-mobile {
                    tr {
                        box-shadow: none;

                        td {
                            .buyer-information {
                                border: none;
                                font-size: 14px;
                                margin-top: 15px;

                                .buyer-description {
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                    .total-section__description {
                        background-color: var(--grey-light-basic);
                        margin-bottom: 0;
                        margin-top: 10px;
                    }

                    .btn-yellow {
                        margin: 25px 0 0 0;
                        font-size: 16px;
                        padding: 10px 45px;
                    }

                    .total-section__cases {
                        margin-top: 20px;
                    }

                    .buyer-container {
                        margin-bottom: 5px;
                    }

                    .logo-bottom {
                        max-width: 300px;
                        margin: 0px auto;
                    }

                    @media screen and (min-width: 1200px) {
                        display: none;
                    }
                }
            }
        }

        .message-no-bookings {
            padding: 30px 5px;
            margin-bottom: 30px;
            border: 1px solid #b4b4b4;

            p {
                margin: 0;
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                color: var(--blue-basic);
            }
        }

        .total-section__cases {
            display: flex;
            justify-content: center;
            box-shadow: none;

            .suitcase-container-doodle {
                margin-bottom: 30px;
                display: flex;
                align-items: flex-end;

                .doodle-case-1,
                .doodle-case-2 {
                    display: inline-block;
                }

                .doodle-case-1 {
                    width: 81px;
                    height: 74px;
                    background: url('/theme/_build/assets/images/icon-doodles/doodle-case-1.png')
                        no-repeat;
                    background-size: contain;
                }

                .doodle-case-2 {
                    width: 82px;
                    height: 115px;
                    background: url('/theme/_build/assets/images/icon-doodles/doodle-case-2.png')
                        no-repeat;
                    background-size: contain;
                }
            }
        }

        .buyer-container {
            display: flex;
            justify-content: center;
            align-items: center;

            .icon-flash_blue {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                background: url($suitcase-page-sprite + '#flash-blue-icon')
                    no-repeat;
            }

            .buyer-description {
                font-family: Poppins, sans-serif;
                font-size: 19px;
                color: var(--color-primary);
                margin-top: 2px;
            }
        }
    }

    .reviews-carousel {
        padding: 0 35px;

        .title {
            font-family: Poppins-Bold, sans-serif;
            margin-bottom: 50px;
        }

        .slider-item {
            text-align: left;
            font-size: 14px;
            margin-right: 30px;

            .list-star {
                display: flex;
                justify-content: flex-start;

                .star-icon {
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    background: url($general-icons-sprite + '#star_yellow') no-repeat;
                }
            }

            .text {
                font-family: Poppins-Medium, sans-serif;
                margin-bottom: 20px;
                line-height: 20px;
            }

            .date {
                font-family: Poppins-Regular, sans-serif;
                margin-bottom: 0;
            }

            .author {
                font-family: Poppins-Bold, sans-serif;
            }
        }

        .slick-slider {
            .slick-arrow {
                display: block;
                width: 55px;
                height: 40px;
            }

            .slick-prev {
                left: -95px;
                top: 22%;

                &::after {
                    content: "";
                    background: var(--color-accent-gold);
                    mask: url($main-icons-sprite + '#carousel-arrow') 0 0/contain no-repeat;
                    transform: rotate(180deg);
                }
            }

            .slick-next {
                right: -55px;
                top: 30%;

                &::after {
                    content: "";
                    background: var(--color-accent-gold);
                    mask: url($main-icons-sprite + '#carousel-arrow') 0 0/contain no-repeat;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .suitcase-page {

        .suitcase-main {
            .container {
                padding: 0 15px 30px 15px;

                .back-link {
                    margin-top: -70px;
                    .text-back {
                        font-size: 17px;
                    }
                }

                .suitcase-title {
                    h1 {
                        font-size: 30px;
                        margin: 20px 0 20px;
                    }
                }
            }

            .btn-yellow {
                font-family: Poppins-Medium, sans-serif;
                padding: 10px 30px;
            }

            .suitcase-table {
                tbody {
                    tr.booking {
                        margin: 10px 0;
                        padding: 25px 10px 10px 10px;

                        td {
                            
                            .list-info {
                                font-size: 14px;
                                margin-left: 10px;
                                margin-right: 0;

                                li {
                                    max-width: 200px;
                                    width: 100%;

                                    h3 {
                                        font-size: 14px;
                                    }

                                    .icon-container {
                                        margin-right: 5px;

                                        i {
                                            width: 18px;
                                            height: 18px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                tfoot {
                    .total-section__cases {
                        td {
                            .suitcase-container-doodle {
                                .doodle-case-wrapper {
                                    .doodle-case-1 {
                                        width: 70px;
                                        height: 60px;
                                    }

                                    .doodle-case-2 {
                                        width: 63px;
                                        height: 105px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .box-section {
            background: var(--grey-light-basic);
        }

        .reviews-carousel {
            padding: 0 10px;

            .slider-item {
                text-align: center;
                margin: 0 20px 40px 20px;
    
                .list-star {
                    justify-content: center;
                }

                &:nth-child(n+6) {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .suitcase-page {
        .suitcase-main {
            .suitcase-table {
                max-width: 600px;
                margin: 0 auto;

                tr.booking {
                    display: block;
                    margin-bottom: 10px;
                    border-radius: 3px;

                    td {
                        padding: 0;
                        text-align: right;

                        div.booking-img {
                            width: 100%;
                            height: 100%;
                            max-width: 140px;
                            max-height: 140px;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .list-info {
                            margin-left: 5px;
                        }

                        &:last-child {
                            border-bottom: 0;
                        }
                    }
                }

                tfoot {
                    tr.booking {
                        td {
                            text-align: center;
                            border-bottom: 0;
                        }
                    }
                }
            }
        }

        .additional-block {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            padding: 0 !important;

            .change-people-wrapper {
                flex-direction: row;
                justify-content: center;

                .sel-box {
                    margin-right: 15px;

                    input {
                        width: 150px;
                        font-size: 14px;
                        height: 35px
                    }
                    .sel-icon {
                        width: 15px;
                        height: 21px;
                        right: 10px;
                    }
                }
            }

            .price-booking {
                span {
                    font-size: 18px !important;
                    margin: 0 auto !important;
                    width: 160px !important;
                    padding: 5px !important;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .suitcase-page {
        .suitcase-main {
            .back-link {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 5px;
                text-decoration: none;
                margin-top: 30px;
            }

            .suitcase-title {
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-around;
            }

            .suitcase-table {
                .total-section {
                    &__secure {
                        margin-bottom: 50px;
                    }
                }
            }

            .message-no-bookings {
                padding: 50px 0;
                margin-bottom: 40px;
            }
        }
    }
}

@media screen and (min-width: 1201px) {
    .suitcase-page {
        .suitcase-main {
            .container {
                padding: 0 15px 30px 15px;

                .suitcase-title {
                    justify-content: space-between;
                    margin-bottom: 30px;

                    .suitcase-container-doodle {
                        width: 30%;
                        max-width: 460px;
                        margin-bottom: 0;
                        text-align: center;
                    }
                }

                .suitcase-table {
                    display: flex;
                    justify-content: space-between;

                    tbody {
                        display: block;
                        width: 69%;
                        max-width: 1000px;

                        tr.booking {
                            display: block;
                            margin-bottom: 25px;

                            td {
                                &:nth-child(2) {
                                    width: 60%;
                                }

                                .list-info {
                                    li {
                                        display: flex;
                                    }
                                }

                                .desktop-only {
                                    display: block;
                                    margin: 10px 0 0;
                                    padding: 10px;
                                    font-weight: 700;
                                    text-align: center;
                                    background-color: var(--blue-basic);
                                    color: var(--white);
                                }

                                &.price-booking {
                                    span {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .addOn-list {
                            display: block;

                            tr,
                            td {
                                display: block;
                                background-color: var(--white);
                            }
                        }

                        .addOn-list {
                            &__item {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding: 15px 25px;
                                text-align: start;

                                .icon,
                                .title,
                                .user-info {
                                    margin: 0 15px 0 0;
                                }

                                .icon {
                                    flex-shrink: 0;
                                }

                                .title {
                                    width: 50%;
                                    max-width: 420px;
                                }

                                .user-info {
                                    width: 180px;
                                    flex-shrink: 0;
                                    align-self: stretch;
                                    padding-top: 0;
                                    text-align: center;

                                    .user-select__wrapper {
                                        height: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        padding: 0 20px;
                                        border-right: 1px solid var(--gray);
                                    }

                                    .user-select {
                                        margin: 0 0 5px;
                                    }
                                }

                                .add-addOn__btn {
                                    width: 115px;
                                    flex-shrink: 0;
                                    margin: 0;
                                }
                            }
                        }
                    }

                    .total-section-desktop {
                        display: block;
                        width: 30%;
                        max-width: 460px;

                        tr,
                        td {
                            text-align: center;

                            .suitcase-booking-total {
                                margin-top: 10px;
                            }

                            .btn-yellow {
                                margin: 0 35px 25px 35px;
                            }

                            .continue-shopping {
                                margin-bottom: 25px;
                            }

                            .logo-bottom {
                                margin: 15px auto 0 auto;
                                max-width: 300px;
                            }
                        }
                    }
                }

                .message-no-bookings {
                    margin-bottom: 60px;
                }
            }
        }
    }
}

@media screen and (min-width: 360px) {
    .suitcase-page {

        .suitcase-main {
            .container {
                padding: 0 5px 30px;
            }
        }
    }
}

@media screen and (min-width: 375px) {
    .suitcase-page {

        .suitcase-main {
            .container {
                padding: 0 12px 30px;
            }
        }
    }
}

@media screen and (min-width: 414px) {
    .suitcase-page {

        .suitcase-main {
            .container {
                padding: 0 15px 30px;
            }
        }
    }
}
