@import 'air-datepicker/dist/css/datepicker.min';
@import "./../font-awesome/font-awesome.min";
@import 'jquery-ui-dist/jquery-ui';
@import 'selectric/public/selectric';
@import "./../header/main-popup";
@import "../../_styles/sections/select-peolple.scss";

@import 'variables';
@import 'mixins';
@import 'reset';
@import 'base';
@import 'helpers';
@import 'wrapper';
@import 'shell';
@import 'container';
@import 'header';
@import 'main';
@import 'sidebar';
@import 'content';
@import 'footer';
@import 'btn';
@import 'form-elements';
@import 'form';
@import 'list';
@import 'logo';
@import 'nav';
@import 'section';
@import 'slider';
@import 'table';
@import 'widget';
@import 'ico';
@import 'feature';
@import 'intro';
@import 'article';
@import 'social';
@import 'breadcrumps';
@import 'filter';
@import 'testimonial';
@import 'accordion';
@import 'link';
@import 'search';
@import 'step';

@import 'errorNotFound';
@import 'hostingRestart';
@import 'animation-chat';
@import 'loader';
@import 'addReview';
@import 'requestHostForm';
// @import 'cookieBanner';

@import 'bodyExperiences';
@import 'blockEmail';

@import 'howItWork';
@import 'contentPageCarousel';
@import 'newFilters';

@import 'home-page';
@import 'emailPreferenceCenter';
@import 'btn-back-to-top';
@import 'currency';
@import 'languages';
@import 'canvas-animation';
@import 'spinner';
@import 'registration-travel-agent';
@import 'travel-agent';
@import 'suitcase';
@import "slick";

@media only screen and (max-width: 1442px) {
    html {
        font-size: 14px;
    }
}
@media only screen and (max-width: 1199px) {
    html {
        font-size: 13px;
    }
}
@media only screen and (max-width: 991px) {
    html {
        font-size: 12px;
    }
}
