/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
    display: block;

    a {
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        display: block;
        position: relative;
        color: #e84042;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        padding: 18px 10px 14px 32px;
        transition: background 0.4s, color 0.4s;

        i {
            position: absolute;
            right: 38px;
            top: 50%;
            transform: translateY(-50%);
            margin-top: 2px;
        }
    }

    > ul {
        li {
            &:first-child {
                height: 88px;
                display: flex;
                align-items: center;
                padding-left: 28px;

                a {
                    border: none;
                    padding: 0;
                    background: none !important;
                    color: #e84042 !important;

                    i {
                        position: static;
                        transform: translateY(0);
                        margin: -4px 19px 0 0;
                        vertical-align: middle;
                    }
                }
            }

            + li {
                margin-top: -1px;
            }
        }
    }

    ul {
        ul {
            position: absolute;
            top: 0;
            left: 100%;
            width: 100%;
            background: #fff;
            z-index: 100;
            opacity: 0;
            visibility: hidden;
            min-height: 100%;
            height: 100vh;
            transition: opacity 0.4s, visibility 0.4s, left 0.4s;
            overflow-y: auto;

            a {
                padding: 17.5px 10px 14px 32px;
            }

            > li {
                &:first-child {
                    height: 88px;
                    display: flex;
                    align-items: center;
                    padding-left: 0;

                    a {
                        border: none;
                        padding: 0;
                        padding-left: 31px;
                        margin-top: 2px;
                        background: none !important;
                        color: #e84042 !important;

                        i {
                            right: auto;
                            left: 0;
                        }
                    }
                }

                + li {
                    margin-top: -1px;
                }
            }
        }
    }

    .expanded {
        > ul {
            left: 0;
            opacity: 1;
            visibility: visible;
        }
    }
}
