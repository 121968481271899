/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

/*  Hidden  */
[hidden],
.hidden {
	display: none !important;
}

/*  Grid  */
.col {
	max-width: 100%;
	flex: 1;

	&s {
		display: flex;
		flex-flow: row wrap;
		margin: 0 -15px;
	}

	&--1of2 {
		max-width: 50%;
		flex: 0 0 50%;
	}

	&--1of4 {
		max-width: 25%;
		flex: 0 0 25%;
	}
}

.visible-mobile {
	display: none !important; 
}

@media #{$mobile} {
	.visible-mobile {
		display: block !important; 
	}

	.hidden-mobile {
		display: none !important; 
	}
}

/* ------------------------------------------------------------ *\
	Text Colors
\* ------------------------------------------------------------ */

.red-color { 
	color: #e84042; 
}
.green-color { 
	color: #118c8f; 
}
.yellow-color { 
	color: #fab64e; 
}

/* ------------------------------------------------------------ *\
	Datepicker
\* ------------------------------------------------------------ */

.datepicker {
	width: 100%;
	border: none;
	font-family: Poppins-Medium, sans-serif;

	.datepicker--nav {
		padding: 16px 18px 7px; 
		border: none;
	}

	.datepicker--cells {
		margin: 0 -16px; 
	}

	.datepicker--nav-title {
		font-weight: 700; 
		color: #28306c; 
		font-family: Poppins-Medium, sans-serif;

		i {
			color: #28306c; 
			margin: 0;
		}
	}

	.datepicker--nav-action {
		position: relative; 
		top: -3px;
		visibility: visible; 

		svg {
			width: 35px; 
			height: 35px;
		}

		path {
			stroke: red;
		}
	}

	.datepicker--content {
		padding: 0 46px 0 31px; 
	}

	.datepicker--days-names {
		padding-bottom: 6px;
		border-bottom: 1px solid #28306c;
		margin: 0 0 3px;
		justify-content: space-between;
	}

	.datepicker--day-name {
		font-size: 14px; 
		color: #28306c; 
		letter-spacing: 0.025em;
		font-weight: 500; 
		text-transform: none;
		flex: inherit;
		text-align: left;
	}

	.datepicker--cell-day {
		font-size: 14px;
		font-weight: 500;
		color: #28306c; 
		height: 48px;
		background: none !important; 
		transition: color .4s; 
		cursor: pointer;
		justify-content: center;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);   
			background: url($general-icons-sprite + '#icon-calendar-pointer') no-repeat 0 0 !important;
			width: 34px; 
			height: 35px;
			z-index: -1;
			margin-left: -1px;
			background-size: 100% 100% !important; 
			opacity: 0; 
			visibility: hidden; 
			transition: opacity .4s, visibility .4s; 
		}

		&.-current- {

			&:after {
				visibility: visible;
			}
		}

		&.-selected- {
			color: #fff; 

			&:after {
				opacity: 1; 
				visibility: visible; 
			}
		}

		&.-disabled- {
			color: #b6b6b6;
			cursor: default;
		}
	}
}

@media #{$small-desktop} {
	.datepicker {
		.datepicker--content {
			padding: 0 15px; 
		}
	}
}

@media #{$tablet-portrait} {
	.datepicker {
		max-width: 300px;
	}
}

@media screen and (max-width: 991px) {
	.datepicker {
		max-width: 100%;
	}
}

/* ------------------------------------------------------------ *\
	Range Slider
\* ------------------------------------------------------------ */

.range-slider {
	position: relative; 
	border: none !important;
	background: #28306c !important; 
	border-radius: 0;
	height: 2px;

	.dots {
		list-style: none; 
		display: flex; 
		justify-content: space-between;
		position: absolute; 
		top: 50%; 
		left: 0; 
		width: 100%;
		transform: translateY(-50%); 
		pointer-events: none;

		li {
			display: inline-block; 
			border-radius: 50%; 
			width: 7px; 
			height: 7px; 
			background: #28306c; 
		}
	}

	.ui-widget-header {
		background: #28306c !important; 
	}

	.ui-slider-handle {
		border-radius: 50%; 
		width: 20px; 
		height: 20px; 
		background: #e84042;
		border: none;
		top: -10px;
	}
}

/* ------------------------------------------------------------ *\
	Masonry
\* ------------------------------------------------------------ */

.masonry-grid {
	.col-lg-4 {
		margin-bottom: 50px;
	}

	&--size1 {
		.col-lg-4 {
			margin-bottom: 40px;
		}	
	}
}

.masonry-grid-alt {
	.col-lg-4 {
		margin-bottom: 50px;
	}

	&--size1 {
		.col-lg-4 {
			margin-bottom: 40px;
		}	
	}
}

@media #{$mobile} {
	.masonry-grid {
		> div {
			position: static !important; 
			transform: translateY(0) !important; 
		}

		.col-lg-4 {
			margin-bottom: 50px;
		}
	}

	.masonry-grid-alt {
		> div {
			position: static !important; 
		}

		.col-lg-4 {
			margin-bottom: 50px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Disable Scroll
\* ------------------------------------------------------------ */

.disable-scroll {
	position: fixed; 
	top: 0; 
	left: 0;
	width: 100%; 
	height: 100vh; 
	z-index: 50;
	opacity: 0; 
	visibility: hidden;  

	&.active {
		opacity: 1; 
		visibility: visible; 
	}
}


/* ------------------------------------------------------------ *\
	Selectric
\* ------------------------------------------------------------ */

.selectric {
	border: 1px solid #707070;
	border-radius: 2px; 

	.label {
		background-color: #F5F2F2; 
		height: 58px; 
		line-height: 60px; 
		margin: 0;
		position: relative; 
		font-size: 0;
		background-repeat: no-repeat; 
		background-position: 9px center; 
		background-size: 54px 36px; 

		&:after {
			content: '';
			position: absolute; 
			top: 50%; 
			right: 8px; 
			transform: translateY(-50%); 
			background: url(../../_build/assets/images/temp/ico-angle-down-gray.png) no-repeat 0 0;
			width: 12px;
			height: 8px;  
			background-size: 100% 100%; 
			pointer-events: none;
		}
	}

	.button {
		display: none;
	}
}

.selectric-items {
	width: 510px !important;
	background: #fff;
	border: 1px solid #707070;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);

	ul {
		padding: 12px 15px; 
	}


	li {
		min-height: 36px; 
		background-position: 0 0 !important;
		background-repeat: no-repeat !important;   
		background-size: 54px 36px !important; 
		padding-left: 69px;
		font-size: 20px; 
		font-weight: 500; 
		color: #585858;
		position: relative; 

		+ li {
			margin: 4px 0 0; 
		}

		&:nth-child(4) {
			margin-top: 25px;

			&:after {
				content: '';
				position: absolute; 
				top: -11px; 
				left: -6px; 
				right: -11px; 
				height: 1px; 
				background: #707070; 
			}
		}

		&.highlighted {
			background: none;
		}
	}
}


@media #{$mobile} {
	.selectric {
		.label {
			height: 35px; 
			line-height: 35px; 
			background-position: 15px center; 
			background-size: 37px 25px; 

			&:after {
				width: 10px;
				height: 6px;  
			}
		}
	}

	.selectric-items {
		width: 100% !important;

		ul {
			padding: 12px 15px; 
		}


		li {
			font-size: 11px; 
			min-height: 36px; 
			background-position: 0 center !important;
			background-size: 37px 25px !important; 
			padding: 5px 5px 5px 50px;

			&:nth-child(4) {
				&:after {
					top: -14px; 
					left: 0; 
					right: 0; 
				}
			}
		}
	}
}

/*  for the light background images (except mobile)  */
@media screen and (min-width: 768px) {
	.experience-slide {
		.article__body {
			text-shadow: 0 0 3px #000000;
		}
	}
}

.feature-secondary {
	.feature__body {
		& > h3 {
			text-shadow: 0 0 3px #000000;
		}

		& .favourite__title {
			text-shadow: none;
		}
	}
}

/*  active cursor for all selects  */
.select select:hover,
.select select:focus,
.select select:active {
	cursor: pointer;
}

// corrections in Popular Experiences-slider
.experiences-slider {
	text-align: center;

	button.slick-arrow {
		outline: none;
	}

	@media screen and (max-width: 767px) {
		button.slick-arrow {
			height: 60px;
			width: 60px;
		}
	}
}

/*  for the Adults/Children inputs  */
.incrementor__field:hover {
	cursor: default;
}

/*  Drop shadow class for the all Titles  */
.drop-shadow {
	text-shadow: 3px 3px 3px #000000
}

/*  auto height to remove extra spaces in host's description  */
.article-primary .article__body .host__description {
	font-size: 1.2rem;
	min-height: auto;
}

/*  correct with for the circles in 'Subscribe now and thank us later' section  */
@media screen and (max-width: 950px) and (min-width: 767px) {
	.list-circles {
		.icon-cirlce1 {
			width: 186px;
		}

		.icon-cirlce2 {
			width: 200px;
		}

		.icon-cirlce3 {
			width: 189px;
		}
	}
}

.article-secondary + div.content-page-form {
	margin-right: 0;
}

/*  style for the sign-up-form  */
.robly-wrap {
	margin: 0 auto;
}

/*  style for slogan title section  */
.slogan__title {
	position: relative;
	z-index: 10;
	margin-top: 20px;

	& > h3 {
		font-size: 1.2rem;
	}
}

.invisible {
	position: absolute;
	top: -9999px;
	left: -9999px;
}

/* TODO:: DM & IN refactor and make less huge   */

/*  host content  */
.host-content {
	.host-reviews {
		.aside-reviews {
			width: 330px;
			margin: 0 auto;

			.section__head {
				margin: 0 0 60px 0;
	
				h2 {
					font-size: 30px;
				}
			}
	
			.widget-messages {
				.widget-message {
					position: relative;
					display: inline-block;
					margin: 0 0 40px 0;
					text-align: left;
	
					.widget__content {
						text-align: center;
	
						h2 {
							font-size: 22px;
							margin: 0 0 15px 0;
						}
	
						p {
							font-size: 16px;
							margin: 0 0 0 0;
						}
					}
				}
			}

			.section__actions {
				text-align: center;
			}
		}
	}
}

/*  host request  */
.host-request {
	.form__col,
	.accordions {
		font-size: 16px !important;

		h6 {
			font-size: 16px !important;
		}
	}
}

.may-also-like {
	padding: 50px 0 !important;
}


// =======================

// ===== host page =====

.host-page-slider {
	margin: 0 auto 50px;
	padding: 0;

	.slick-arrow {
		top: 50%;
	}

	.host-page-slider-item {
		height: 250px;
		padding: 0 6px;

		img{
			width: 100%;
			display: block;
			height: 100%;
			object-fit: cover;
		}
	}

	.host-page-slider-text {
		display: flex !important;
		flex-direction: column;
		justify-content: center;
		background-color: #f6f6f6;

		.icon {
			display: block;
			width: 60px;
			height: 60px;
			margin: 0 auto 20px;
		}

		.icon-cocktail {
			background: url('../../_build/assets/images/svg/cocktail-icon.svg') no-repeat;
		}

		.icon-footbal {
			background: url('../../_build/assets/images/svg/futbol-icon.svg') no-repeat;
		}

		.text{
			font-size: 12px;
			text-align: center;
			text-transform: uppercase;
			line-height: 30px;

			span {
				font-size: 20px;
				font-weight: bold;
				text-transform: capitalize;
			}
		}
	}
}

@media screen and (min-width: 767px) {
	.host-page-slider.container {
		padding: 0;

		.host-page-slider-item {
			height: 350px;
		}

		.host-page-slider-text {
			.icon {
				width: 90px;
				height: 90px;
			}

			.text{
				font-size: 16px;
				line-height: 50px;
	
				span {
					font-size: 30px;
				}
			}
		}
	}
}
// =====================
