/* ------------------------------------------------------------ *\
	Icons
\* ------------------------------------------------------------ */
.ico-x {
	display: inline-block; 
	position: relative; 
	width: 40px; 
	height: 40px; 

	&:after,
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(45deg); 
		background: #e84042; 
		width: 100%; 
		height: 4px; 
		border-radius: 4px;   
	}

	&:after {
		transform: translate(-50%, -50%) rotate(-45deg); 
	}
}

.ico-plus {
	display: inline-block; 
	position: relative; 
	width: 15px; 
	height: 15px; 

	&:after,
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); 
		width: 100%; 
		height: 3px; 
		background: #fff;
		border-radius: 2px;   
	}

	&:after {
		transform: translate(-50%, -50%) rotate(90deg); 
	}
}

.ico-angle-down-white {
	width: 25px; 
	height: 15px;
}

.ico-search-red,
.ico-search {
	width: 29px; 
	height: 29px;
}

.ico-mouse {
	width: 52px; 
	height: 112px;
}

.ico-label {
	width: 122px; 
	height: 60px;
}

.ico-camera {
	width: 89px; 
	height: 71px;
}

.ico-circle-arrow-blue {
	width: 21px; 
	height: 21px;
}

.ico-circle-arrow {
	width: 29px; 
	height: 29px;
}

.ico-angle-right {
	width: 9px; 
	height: 18px;
}

.ico-grapes {
	width: 135px; 
	height: 152px;
}

.ico-flag {
	width: 157px; 
	height: 154px;
}

.ico-flipflops {
	width: 137px; 
	height: 122px;
}

.ico-dollar-blue {
	width: 21px; 
	height: 21px;
}

.ico-heart {
	width: 36px; 
	height: 38px;
}

.ico-currency {
	width: 36px; 
	height: 39px;
}

.ico-walk {
	width: 34px; 
	height: 44px;
}

.ico-car {
	width: 57px; 
	height: 35px;
}

.ico-message-alt {
	width: 38px; 
	height: 33px;
}

.ico-setting-red,
.ico-setting {
	width: 24px; 
	height: 20px;
}

.ico-angle-down-red,
.ico-angle-down-blue {
	width: 14px; 
	height: 8px;
}

.ico-ball {
	width: 72px; 
	height: 71px;
}

.ico-drink {
	width: 69px; 
	height: 89px;
}

.ico-cafe-green {
	width: 177px; 
	height: 206px;
}

.ico-grapes-green {
	width: 135px; 
	height: 152px;
}

.ico-arrow-down-big {
	width: 39px; 
	height: 34px;
}

//.ico-mail-alt,
//.ico-twitter-alt,
//.ico-instagram-alt,
//.ico-linkedin-alt,
//.ico-pinteres-alt,
//.ico-facebook-alt {
//	width: 32px;
//	height: 32px;
//}

.ico-mail-big {
	width: 122px; 
	height: 99px;
}

.ico-cirlces-yellow,
.ico-circles {
	width: 101px; 
	height: 33px;
}

.ico-pdf {
	width: 43px; 
	height: 61px;
}

.ico-angle-right-dark {
	width: 9px; 
	height: 15px;
}

.ico-share {
	width: 41px;
	height: 41px;  
}
.ico-pdf-alt {
	width: 41px;
	height: 41px;  
}
.ico-map {
	width: 43px;
	height: 42px;  
}
.ico-info {
	width: 35px;
	height: 41px;  
}


@media #{$mobile} {
	.ico-x {
		width: 32px; 
		height: 32px; 

		&:after,
		&:before {
			height: 3px;
		}
	}

	.ico-search-red, 
	.ico-search {
		width: 18px; 
		height: 18px; 
	}

	.ico-angle-down-white {
		width: 16px; 
		height: 10px; 
	}

	.ico-food-1 {
		width: 125px; 
		height: 62px;
	}

	.ico-burger {
		width: 94px; 
		height: 77px;
	}

	.ico-flowers {
		width: 70px; 
		height: 83px;
	}

	.ico-cat-green-alt,
	.ico-cat {
		width: 65px; 
		height: 76px;
	}

	.ico-gate-green-alt,
	.ico-gate {
		width: 66px; 
		height: 80px;
	}

	.ico-cafe-green-alt,
	.ico-cafe {
		width: 86px; 
		height: 100px;
	}

	.ico-mouse {
		width: 26px; 
		height: 56px;
	}

	.ico-label {
		width: 61px; 
		height: 30px;
	}

	.ico-camera {
		width: 45px; 
		height: 35px;
	}

	.ico-circle-arrow {
		width: 20px; 
		height: 20px;
	}

	.ico-message-def {
		width: 19px; 
		height: 18px;
	}

	.ico-heart {
		width: 33px;
		height: 35px;
	}

	.ico-currency {
		width: 32px; 
		height: 35px;
	}

	.ico-walk {
		width: 27px; 
		height: 35px;
	}

	.ico-car {
		width: 56px; 
		height: 35px;
	} 

	.ico-message-alt {
		width: 40px; 
		height: 35px;
	} 

	.ico-dollar-blue {
		width: 18px; 
		height: 18px;
	}

	.ico-ball {
		width: 36px; 
		height: 35px;
	} 

	.ico-drink {
		width: 34px; 
		height: 44px;
	} 

	.ico-grapes {
		width: 47px; 
		height: 53px;
	}

	.ico-flag {
		width: 55px; 
		height: 54px;
	}

	.ico-flipflops {
		width: 54px; 
		height: 48px;
	}

	.ico-arrow-down-big {
		width: 20px; 
		height: 17px;
	}

	.ico-mask {
		width: 105px; 
		height: 96px;
	}

	.ico-burns {
		width: 91px; 
		height: 89px;
	}

	.ico-heart-cloud {
		width: 106px; 
		height: 70px;
	}

	.ico-cafe-red {
		width: 105px; 
		height: 122px;
	}


	.ico-stamp {
		width: 66px; 
		height: 58px;
	}

	.ico-world {
		width: 80px; 
		height: 64px;
	}

	.ico-mobile {
		width: 44px; 
		height: 64px;
	}

	.ico-photo {
		width: 73px; 
		height: 56px;
	}

	.ico-support {
		width: 63px; 
		height: 56px;
	}

	.ico-cirlces-yellow,
	.ico-circles {
		width: 77px; 
		height: 25px;
	}
}

/* ------------------------------------------------------------ *\
	Circles icons
\* ------------------------------------------------------------ */

[class^="icon-"] {
	display: inline-block; 
	background-position: 0 0;
	background-repeat: no-repeat;  
	background-size: 100% 100%;
}

.icon-cirlce-small {
	background-image: url(/theme/_build/assets/images/temp/circle-small.svg);
	width: 40px; 
	height: 45px;
}

.icon-cirlce-small-1 {
	background-image: url(/theme/_build/assets/images/temp/icon-cirlce-small-1.svg);
	width: 38px; 
	height: 42px;
}

.icon-cirlce-small-2 {
	background-image: url(/theme/_build/assets/images/temp/icon-cirlce-small-2.svg);
	width: 38px; 
	height: 42px;
}

.icon-shape-1 {
	background-image: url(/theme/_build/assets/images/temp/icon-shape-1.svg);
	width: 90px; 
	height: 92px;
}

.icon-shape-2 {
	background-image: url(/theme/_build/assets/images/temp/icon-shape-2.svg);
	width: 96px; 
	height: 92px;
}

.icon-shape-3 {
	background-image: url(/theme/_build/assets/images/temp/icon-shape-3.svg);
	width: 95px; 
	height: 91px;
}

.icon-cirlce1 {
	background-image: url(/theme/_build/assets/images/temp/circle1.svg);
	width: 249px; 
	height: 280px;

	&--size1 {
		width: 153px; 
		height: 169px;
	}
}

.icon-cirlce2 {
	background-image: url(/theme/_build/assets/images/temp/circle2.svg);
	width: 267px; 
	height: 280px;

	&--size1 {
		width: 164px; 
		height: 170px;
	}
}

.icon-circle2-yellow {
	background-image: url(/theme/_build/assets/images/temp/circle2-yellow.svg);
	width: 267px; 
	height: 280px;
}

.icon-cirlce3 {
	background-image: url(/theme/_build/assets/images/temp/circle3.svg);
	width: 252px; 
	height: 280px;

	&--size1 {
		width: 154px; 
		height: 168px;
	}
}

.icon-cirlce-big-1 {
	background-image: url(/theme/_build/assets/images/temp/c1.svg);
	width: 393px; 
	height: 437px;
	z-index: -1;
}

.icon-cirlce-big-3 {
	background-image: url(/theme/_build/assets/images/temp/c3.svg);
	width: 415px; 
	height: 437px;
}

.icon-cirlce-big-4 {
	background-image: url(/theme/_build/assets/images/temp/c4.svg);
	width: 415px; 
	height: 437px;
}

.icon-cirlce-big-5 {
	background-image: url('/theme/_build/assets/images/temp/c5.svg');
	width: 415px; 
	height: 437px;
}


.icon-cirlce-big-d1 {
	background-image: url(/theme/_build/assets/images/temp/circle-d1.svg);
	width: 393px; 
	height: 437px;
}


.icon-circle-green {
	background-image: url(/theme/_build/assets/images/temp/ico-circle-green.svg);
	width: 38px; 
	height: 44px;
}

.icon-circle-yellow {
	background-image: url(/theme/_build/assets/images/temp/ico-circle-yellow.svg);
	width: 38px; 
	height: 44px;
}

.icon-circle-red {
	background-image: url(/theme/_build/assets/images/temp/ico-circle-red.svg);
	width: 38px; 
	height: 44px;
}

.icon-circle-blue {
	background-image: url(/theme/_build/assets/images/temp/ico-circle-blue.svg);
	width: 38px; 
	height: 44px;
}

.icon-circle-green-alt {
	background-image: url(/theme/_build/assets/images/temp/ico-circle-green-alt.svg);
	width: 92px; 
	height: 96px;
}

.icon-circle-yellow-alt {
	background-image: url(/theme/_build/assets/images/temp/ico-circle-yellow-alt.svg);
	width: 92px; 
	height: 96px;
}

.icon-circle-red-alt {
	background-image: url(/theme/_build/assets/images/temp/ico-circle-red-alt.svg);
	width: 92px; 
	height: 96px;
}

.icon-circle-blue-alt {
	background-image: url(/theme/_build/assets/images/temp/ico-circle-blue-alt.svg);
	width: 92px; 
	height: 96px;
}

.icon-cirlce-big-1-e {
	background-image: url(/theme/_build/assets/images/temp/c1-e.svg);
	width: 384px; 
	height: 432px;
}

.icon-cirlce-big-3-e {
	background-image: url(/theme/_build/assets/images/temp/c3-e.svg);
	width: 384px; 
	height: 432px;
}

.icon-cirlce-big-4-e {
	background-image: url(/theme/_build/assets/images/temp/c4-e.svg);
	width: 384px; 
	height: 432px;
}

.icon-cirlce-big-h1 {
	background-image: url(/theme/_build/assets/images/temp/circle-h1.svg);
	width: 393px; 
	height: 437px;
}

.icon-cirlce-f1 {
	background-image: url(/theme/_build/assets/images/temp/icon-cirlce-f1.svg);
	width: 259px; 
	height: 272px;
}

.icon-cirlce-f2 {
	background-image: url(/theme/_build/assets/images/temp/icon-cirlce-f2.svg);
	width: 259px; 
	height: 272px;
}

.icon-cirlce-h1 {
	background-image: url(/theme/_build/assets/images/temp/icon-circle-h1.svg);
	width: 384px; 
	height: 432px;
}

.icon-cirlce-h2 {
	background-image: url(/theme/_build/assets/images/temp/icon-circle-h2.svg);
	width: 393px; 
	height: 437px;
}

.icon-cirlce-h2-y {
	background-image: url(/theme/_build/assets/images/temp/icon-circle-h2-y.svg);
	width: 393px; 
	height: 437px;
}

.icon-cirlce-h3 {
	background-image: url(/theme/_build/assets/images/temp/c5-blue.svg);
	width: 415px; 
	height: 437px;
}

.icon-cirlce-g1 {
	background-image: url('/theme/_build/assets/images/temp/icon-circle-g1.svg');
	width: 415px; 
	height: 437px;
	opacity: .1;
}

.icon-cirlce-g2 {
	background-image: url(/theme/_build/assets/images/temp/icon-circle-g2.svg);
	width: 384px; 
	height: 432px;
	opacity: .1;
}

.icon-article-circle11 {
	background-image: url(/theme/_build/assets/images/temp/article-circle11.svg);
	width: 106px; 
	height: 116px;
}

.icon-article-circle12 {
	background-image: url(/theme/_build/assets/images/temp/article-circle12.svg);
	width: 97px; 
	height: 101px;
}

.icon-article-circle13 {
	background-image: url(/theme/_build/assets/images/temp/article-circle13.svg);
	width: 154px; 
	height: 171px;
}

.icon-article-circle21 {
	background-image: url(/theme/_build/assets/images/temp/article-circle21.svg);
	width: 182px; 
	height: 190px;
}

.icon-article-circle22 {
	background-image: url(/theme/_build/assets/images/temp/article-circle22.svg);
	width: 88px; 
	height: 97px;
}

.icon-article-circle23 {
	background-image: url(/theme/_build/assets/images/temp/article-circle23.svg);
	width: 147px; 
	height: 161px;
}

.icon-article-circle31 {
	background-image: url(/theme/_build/assets/images/temp/article-circle31.svg);
	width: 96px; 
	height: 100px;
}

.icon-article-circle32 {
	background-image: url(/theme/_build/assets/images/temp/article-circle32.svg);
	width: 132px; 
	height: 144px;
}

.icon-article-circle33 {
	background-image: url(/theme/_build/assets/images/temp/article-circle33.svg);
	width: 125px; 
	height: 140px;
}

.icon-cirlce-big-c1 {
	background-image: url(/theme/_build/assets/images/temp/icon-cirlce-big-c1.svg);
	width: 415px; 
	height: 437px;
}

.icon-cirlce-big-5-e {
	background-image: url(/theme/_build/assets/images/temp/icon-cirlce-big-5-e.svg);
	width: 393px; 
	height: 437px;
}

.icon-cirlce-big-r1 {
	background-image: url(/theme/_build/assets/images/temp/icon-cirlce-big-r1.svg);
	width: 393px; 
	height: 437px;
}

.icon-cirlce-h3-white {
	background-image: url(/theme/_build/assets/images/temp/c5-white.svg);
	width: 276.67px; 
	height: 291.33px;
}

.icon-cirlce-h2-white {
	background-image: url(/theme/_build/assets/images/temp/c6-white.svg);
	width: 262px; 
	height: 291.33px;
}


@media #{$tablet-portrait} {
	.icon-cirlce-f1 {
		width: 215px; 
		height: 226px;
	}

	.icon-cirlce-f2 {
		width: 215px; 
		height: 226px; 
	}

	.icon-cirlce1 {
		&--size1 {
			width: 106px; 
			height: 118px;
		}
	}

	.icon-cirlce2 {
		&--size1 {
			width: 114px; 
			height: 118px;
		}
	}

	.icon-cirlce3 {
		&--size1 {
			width: 107px; 
			height: 117px;
		}
	}
}


@media #{$mobile} {
	.icon-cirlce-small {
		width: 22px; 
		height: 25px; 
	}

	.icon-cirlce-small-1 {
		width: 24px; 
		height: 25px; 
	}

	.icon-cirlce-small-2 {
		width: 22px; 
		height: 25px; 
	}

	.icon-shape-1 {
		width: 45px; 
		height: 46px; 
	}

	.icon-shape-2 {
		width: 48px; 
		height: 46px; 
	}

	.icon-shape-3 {
		width: 48px; 
		height: 45px; 
	}

	.icon-cirlce1 {
		width: 79px; 
		height: 88px; 
	}

	.icon-cirlce2 {
		width: 85px; 
		height: 88px; 
	}

	.icon-cirlce3 {
		width: 80px; 
		height: 88px; 
	}

	.icon-cirlce-f1 {
		width: 144px; 
		height: 151px;
	}
}

/* ------------------------------------------------------------ *\
	doodle icon moved from _booking-widget.scss;
\* ------------------------------------------------------------ */
.doodle-icon {
	&.small {
		width: 22px;
		height: 20px;
	}

	&.normal {
		width: 65px;
		height: 40px;
	}

	&.duration-experience-card {
		background: url('/theme/_build/assets/images/icon-doodles/experience-cards/duration-experience-card.png') center no-repeat;
		background-size: contain;
	}

	&.mappin-experience-card {
		background: url('/theme/_build/assets/images/icon-doodles/experience-cards/mappin-experience-card.png') center no-repeat;
		background-size: contain;
	}

	&.price-from-experience-card {
		background: url('/theme/_build/assets/images/icon-doodles/experience-cards/price-from-experience-card.png') center no-repeat;
		background-size: contain;
	}
}

/* ------------------------------------------------------------ *\
	Multi language icons;
\* ------------------------------------------------------------ */
.language-flags {
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: contain;

	@media screen and (max-width: 767px) {
		width: 15px;
		height: 15px;
	}
}

.language-flag__en {
	background-image: url(/theme/_build/assets/images/language-flags/usa.svg);
}

.language-flag__de {
	background-image: url(/theme/_build/assets/images/language-flags/germany.svg);
}

.language-flag__it {
	background-image: url(/theme/_build/assets/images/language-flags/italy.svg);
}

.language-flag__fr {
	background-image: url(/theme/_build/assets/images/language-flags/france.svg);
}

.language-flag__es {
	background-image: url(/theme/_build/assets/images/language-flags/spain.svg);
}