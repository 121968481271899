.email-block {
    width: 100%;
    padding: 50px 0;
    color: var(--color-primary);
    background-color: #ffffff;

    &__title {
        display: inline-block;
        margin-bottom: 35px;
        font-size: 1.875rem;
    }

    .text-block {
        margin: 0 0 80px 0;
        font-size: 1.2rem;

        @media screen and (max-width: 1199px) {
            margin: 0 0 40px 0;
        }

        &__paragraph1 {
            margin-bottom: 20px;

            & > a.link-email {
                color: var(--red-basic);
            }
        }

        &__paragraph2 {
            margin: 0;
        }
    }

    .main-block {
        position: relative;
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px) {
            flex-wrap: wrap;
        }

        .photo-block {
            width: 271px;
            height: 271px;
            margin: 0 80px 0 0;

            @media screen and (max-width: 1199px) {
                width: 240px;
                height: 240px;
                margin: 0 40px 0 0;
            }

            @media screen and (max-width: 991px) {
                width: 200px;
                height: 200px;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .contact-block {
            &__name {
                display: inline-block;
                margin-bottom: 10px;
                font-size: 3.375rem;
            }

            &__position {
                margin-bottom: 25px;
                font-size: 1.3125rem;
                font-weight: bold;
            }

            &__list {
                margin: 0;
                list-style: none;
            }

            &__item {
                margin-bottom: 7px;
                font-size: 1.3125rem;

                &:nth-child(1) {
                    & > i {
                        font-size: 1.3125rem;
                    }
                }

                & > i {
                    margin-bottom: 7px;
                    color: var(--yellow-basic);
                    font-size: 1.5rem;
                }

                & a {
                    text-decoration: none;
                }
            }
        }

        .doodles-block {
            position: absolute;
            bottom: 10%;
            right: 10%;

            @media screen and (max-width: 1199px) {
                right: 0%;
            }

            @media screen and (max-width: 991px) {
                display: none;
            }

            .doodle-city {
                display: inline-block;
                width: 200px;
                height: 100px;

                &.first {
                    margin: 0 0 30px 0;
                    transform: rotate(-10deg);
                }
            }
        }
    }
}
