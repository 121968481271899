/* ------------------------------------------------------------ *\
	experiences + Cards;
\* ------------------------------------------------------------ */
.appearance-effect-experiences,
.no-masonry {
    .col-lg-4 {
        @media screen and (min-width: 991px) and (max-width: 1442px) {
            flex: 0 0 33%;
            max-width: 33%;
        }

        .card-tile:hover {
            color: var(--blue-basic);
        }
    }
}

.card-block {
    &-item {
        display: block;
        border-radius: 5px;
        text-decoration: none;
        overflow: hidden;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
        transition: transform .5s, opacity 1s;

        &:hover,
        &:focus,
        &:active {
            color: #fff;
        }
    }

    .card-tile {
        .card-block-header {
            & > img {
                max-height: 210px;

                @media screen and (min-width: 768px) {
                    max-height: initial;
                }
            }

            .category__title {
                display: none;

                @media screen and (min-width: 768px) {
                    display: initial;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    min-width: 135px;
                    padding: 0 10px;
                    font-size: 1rem;
                    text-align: center;
                    text-transform: lowercase;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
            }
        }

        .article__body {
            min-height: 210px;
            max-height: 210px;
            position: relative;

            @media screen and (min-width: 768px) {
                max-height: initial;
                min-height: 290px;
            }

            .country-name {
                margin-bottom: 10px;
                font-size: 1.25rem;

                @media screen and (min-width: 768px) {
                    font-size: 1.375rem;
                }
            }

            .card-tile__title {
                display: block;
                font-size: 1.25rem;

                @media screen and (min-width: 768px) {
                    height: 70px;
                    font-size: 1.375rem;
                }
            }

            .list-stars {
                position: absolute;
                bottom: 15px;

                @media screen and (min-width: 768px) {
                    position: initial;
                    bottom: initial;
                    display: flex;
                }

                li + li {
                    margin-left: 0;

                    @media screen and (min-width: 768px) {
                        margin-left: 5px;
                    }
                }

                li > i {
                    font-size: 14px;

                    @media screen and (min-width: 768px) {
                        font-size: 21px;
                    }
                }

                li:last-child {
                    font-size: 1rem;
                    vertical-align: bottom;

                    @media screen and (min-width: 768px) {
                        font-size: initial;
                    }
                }
            }
        }
    }
}

/* ------------------------------------------------------------ *\
    Our hosts Section;
\* ------------------------------------------------------------ */
.our-hosts {
    padding: 35px 0;

    @media screen and (min-width: 768px) {
        padding: 70px 15px;
    }

    .host-card {
        width: 100%;
        padding: 5px 6px 0 6px;

        @media screen and (min-width: 768px) {
            padding: 5px 10px 0 10px;
        }

        @media screen and (min-width: 992px) {
            padding: 5px 23px 0 23px;
        }

        .article__image-container {
            & > img {
                height: 230px;

                @media screen and (min-width: 768px) {
                    height: 400px;
                }
            }

            & >  i {
                display: none;

                @media screen and (min-width: 768px) {
                    display: inline-block;
                }
            }
        }

        .article__body {
            padding: 15px;

            @media screen and (min-width: 768px) {
                padding: 24px 25px;
            }

            p[data-name="tagline"] {
                font-size: 1.083rem;

                @media screen and (min-width: 768px) {
                    font-size: 1.2rem;
                }
            }

            .article__bottom-group {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                margin-top: 5px;

                @media screen and (min-width: 768px) {
                    display: block;
                    align-items: unset;
                    justify-content: unset;
                    margin-top: 0;
                }

                li {
                    & > i {
                        font-size: 14px;

                        @media screen and (min-width: 768px) {
                            font-size: 21px;
                        }
                    }
                }

                li + li {
                    margin-left: 0;

                    @media screen and (min-width: 768px) {
                        margin-left: 5px;
                    }
                }

                .bottom__controls {
                    & > span:first-child {
                        display: none;

                        @media screen and (min-width: 768px) {
                            display: inline-block;
                        }
                    }

                    .view-host__btn {
                        font-size: 1.083rem;

                        @media screen and (min-width: 768px) {
                            font-size: 1.125rem;
                        }
                    }
                }
            }
        }
    }
}

.container > header.our-hosts__head {
    margin-bottom: 50px;
    text-align: center;

    @media screen and (min-width: 768px) {
        text-align: left;
    }

    & > h2 {
        margin-bottom: 5px;

        & + p {
            margin-top: 0;
            font-size: 1.2rem;
        }
    }
}

.host-card {
    width: 100%;
    margin-bottom: 40px;
    padding: 0 15px;

    @media screen and (min-width: 768px) {
        width: 50%;
        padding: 0 23px;
    }

    @media screen and (min-width: 992px) {
        width: 33%;
    }

    .article__image-container {
        img {
            height: 400px;
            object-fit: cover;
        }
    }

    .card-tile {
        display: block;
        border-radius: 5px;
        overflow: hidden;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            color: var(--blue-basic);
        }
    }

    p[data-name="tagline"] {
        font-size: 1.2rem;
    }

    .list-stars {
        margin-bottom: 3px;
    }

    .bottom__controls {
        display: flex;
        justify-content: space-between;

        & > span {
            font-size: 1.2rem;
        }

        .view-host__btn {
            font-size: 1.125rem;
            text-transform: uppercase;
            text-decoration: underline;

            &:hover,
            &focus,
            &:active {
                color: #0056b3;
                text-decoration: none;
            }
        }
    }
}

.reviews-btn {
    display: none;

    @media screen and (min-width: 768px) {
        display: inline-block;
        margin-top: 3vh;
    }
}

/* ------------------------------------------------------------ *\
	SEE ALL button;
\* ------------------------------------------------------------ */
.see__more {
    text-align: center;

    .btn {
        font-size: 1rem;
        text-transform: uppercase;
        border-width: 2px;
        font-weight: normal;

        @media screen and (min-width: 768px) {
            border-width: 3px;
            font-weight: bold;
        }
    }
}

/* ------------------------------------------------------------ *\
	overlay-wrapper close search bar list when clicking outside;
\* ------------------------------------------------------------ */
.overlay-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    z-index: 180;
}