/* ------------------------------------------------------------ *\
	Social
\* ------------------------------------------------------------ */

.socials {
	ul {
		list-style: none;
		display: flex;
		align-items: center;
		font-size: 22px;

		li + li {
			margin-left: 23px;
		}
	}

	a {
		transition: opacity .4s; 

		&:hover {
			opacity: .7;
		}
	}
}


@media #{$tablet-portrait} {
	.socials {
		ul {
			li + li {
				margin-left: 17px;
			}
		}
	}	
}

@media #{$mobile} {
	.socials {
		ul {
			li + li {
				margin-left: 47px;
			}
		}
	}	
}


/* ------------------------------------------------------------ *\
	Socials Alt
\* ------------------------------------------------------------ */

.socials-alt {

	ul {
		display: flex;
		margin: 0;
		padding: 0;

		li {
			margin: 0 15px 0 0px;
			list-style: none;

			a {
				display: block;
				color: #28306c;
				text-decoration: none;

				i {
					display: block;
					font-size: 32px;

					&.facebook {
						color: #3c5998;
					}

					&.twitter {
						color: #26a6d1;
					}

					&.instagram {
						color: #40729b;
					}

					&.linkedin {
						color: #027bb5;
					}

					&.pinterest {
						color: #c8232c;
					}
				}
			}

			&:last-child {
				margin: 0;
			}
		}
	}

	a {
		transition: opacity .4s; 

		&:hover {
			opacity: .7;
		}
	}

	& > strong {
		display: inline-block;
		font-size: 14px; 
		color: #212020; 
		font-weight: 700; 
		font-family: 'Helvetica', sans-serif; 
		margin: 0 27px 0 0; 
		text-transform: uppercase;
	}

	&--primary {
		display: flex;
		align-items: center; 
		justify-content: center;
		padding: 66px 0 63px; 
	}
}

@media #{$small-desktop} {
	.socials-alt {
		& > strong {
			margin: 0 10px 0 0; 
		}
	}
}

@media #{$tablet-portrait} {
	.socials-alt {
		& > strong {
			margin: 0 0 10px; 
		}

		&--primary {
			display: block; 
		}
	}
}
