.box-spinner {
	position: fixed;
	z-index: 9999;
	width: 100vw;
	height: 100vh;
	background-color: #00000050;

	.box-spinner-icon {
		position: fixed;
		top: 50%;
		left: 50%;
		z-index: 9999;
		transform: translate(-50%, -50%);
		color: var(--red-basic);
		font-size: 80px;
	}
}
