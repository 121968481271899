/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */

input::placeholder {
	color: inherit;
	opacity: 1;
}

textarea::placeholder {
	color: inherit;
	opacity: 1;
}

input:-webkit-autofill {
	-webkit-text-fill-color: inherit;
	-webkit-box-shadow: 0 0 0 1000px #fff inset;
}

select {
	appearance: none;
}

select::-ms-expand {
	display: none; 
}

.textarea {
	width: 100%; 
	border: 2px solid #28306c;
	resize: none;
	padding: 3px 0px; 
	height: 100px;
	letter-spacing: 0.025em;
}

@media #{$mobile} {
	.textarea {
		border-width: 1px;
		height: 53px; 
		padding: 0;
	}
}

.select {
	position: relative; 

	&:after {
		content: '';
		position: absolute;  
		top: 50%; 
		right: 14px; 
		transform: translateY(-50%); 
		background: url(../../_build/assets/images/temp/ico-angle-down.png) no-repeat 0 0;
		width: 25px;
		height: 14px; 
		background-size: 100% 100%; 
		pointer-events: none;
	}

	select {
		width: 100%; 
		height: 50px; 
		padding: 0 18px; 
		background: #fff;
		border-radius: 0;
		color: #e84042; 
		font-weight: 700;
		border: none;
	}

	/*  Select Primary  */
	&--primary {
		&:after {
			background-image: url(../../_build/assets/images/temp/ico-angle-down-white.png);
			width: 22px;
			height: 12px; 
			right: 20px; 
		}

		select {
			border: 4px solid #fff;
			background: none; 
			height: 46px;
			color: #fff;
			font-size: 14px; 
			font-weight: 500; 
			letter-spacing: 0.07em;
			text-transform: uppercase;
		}
	}

	&--red {
		select {
			border: 2px solid #e84042;
		}
	}
}

/* ------------------------------------------------------------ *\
	Checkbox
\* ------------------------------------------------------------ */

.checkbox {
	input {
		display: none; 

		&:checked + label {
			&:before {
				opacity: 1; 
				visibility: visible; 
			}
		}
	}

	label {
		position: relative; 
		display: inline-block; 
		color: #28306c; 
		font-size: 11px; 
		letter-spacing: 0.025em;
		padding-left: 33px;
		margin: 0; 

		span {
			font-size: 14px; 
			display: block;
		}

		&:before,
		&:after {
			content: ''; 
			position: absolute; 
			top: -1px;
			left: 0; 
		}

		&:after {
			width: 18px; 
			height: 18px; 
			border: 1px solid #28306c;
		}

		&:before {
			width: 12px; 
			height: 12px;
			background: #28306c; 
			top: 2px; 
			left: 3px;
			opacity: 0; 
			visibility: hidden; 
			transition: opacity .4s, visibility .4s; 
		}
	}

	/*  Checkbox Big  */
	&--big {
		label {
			font-size: 16px; 
			letter-spacing: 0.025em;
			padding-left: 38px;

			
			&:after {
				width: 26px; 
				height: 26px; 
				border: 2px solid #28306c;
			}

			&:before {
				width: 20px; 
				height: 20px;
			}
		}
	}
}

@media #{$mobile} {
	.checkbox {
		&--big {
			label {
				font-size: 13px; 
				padding-left: 26px; 

				&:after {
					border-width: 1px;
					width: 20px; 
					height: 20px; 
					margin-top: -2px;
				}

				&:before {
					width: 16px; 
					height: 16px;
					top: -1px; 
					left: 2px;
				}
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Radio
\* ------------------------------------------------------------ */

.radio {
	input {
		display: none; 

		&:checked + label {
			&:before {
				opacity: 1; 
				visibility: visible; 
			}
		}
	}

	label {
		position: relative; 
		display: inline-block; 
		color: #28306c; 
		font-size: 11px; 
		letter-spacing: 0.025em;
		padding-left: 30px;
		margin: 0; 

		span {
			font-size: 14px; 
			display: block;
		}

		&:before,
		&:after {
			content: ''; 
			position: absolute; 
			top: 2px;
			left: 0; 
		}

		&:after {
			width: 18px; 
			height: 18px; 
			border: 1px solid #28306c;
		}

		&:before {
			width: 12px; 
			height: 12px;
			background: #28306c; 
			top: 5px; 
			left: 3px;
			opacity: 0; 
			visibility: hidden; 
			transition: opacity .4s, visibility .4s; 
		}
	}
}

/* ------------------------------------------------------------ *\
	Select Alt
\* ------------------------------------------------------------ */

.select-alt {
	position: relative; 

	&:after {
		content: '';
		position: absolute; 
		top: 50%; 
		right: 15px; 
		transform: translateY(-50%); 
		background: url(../../_build/assets/images/temp/ico-angle-down-gray.png) no-repeat 0 0;
		width: 12px;
		height: 8px;  
		background-size: 100% 100%; 
		pointer-events: none;
	}
}
