/* ------------------------------------------------------------ *\
	Multi language menu;
\* ------------------------------------------------------------ */
.language {
	display: none;
	position: absolute;
	top: 50%;
	right: 150px;
	transform: translateY(-50%);

	.language-btn {
		display: block;
		width: 50px;
		border: 0;
		color: #ffffff;
		background-color: transparent;
		text-align: center;
		outline: none;

		.language-icon-btn {
			display: block;
			width: 22px;
			height: 22px;
			margin: 0 auto;
			border-radius: 50%;
			background-color: #ffffff;

			&.en {
				background: url('../../_build/assets/images/language-flags/usa-icon.png') no-repeat;
				background-size: cover;
			}

			&.de {
				background: url('../../_build/assets/images/language-flags/germany-icon.png') no-repeat;
				background-size: cover;
			}

			&.it {
				background: url('../../_build/assets/images/language-flags/italy-icon.png') no-repeat;
				background-size: cover;
			}

			&.fr {
				background: url('../../_build/assets/images/language-flags/france-icon.png') no-repeat;
				background-size: cover;
			}

			&.es {
				background: url('../../_build/assets/images/language-flags/spain-icon.png') no-repeat;
				background-size: cover;
			}
		}

		span {
			font-size: 12px;
			letter-spacing: 0.3px;
		}
	}

	.language-current {
		position: relative;
		display: none;
		font-size: 16px;

		& > strong {
			display: inline-block;
			width: 25px;
			text-transform: capitalize;
			font-weight: normal;
		}

		& > i {
			font-size: 1.4rem;
			vertical-align: middle;
			transition: transform 0.4s;
		}
	}

	.language__list {
		position: absolute;
		right: -30px;
		top: 20px;
		width: 135px;
		padding: 10px;
		margin-bottom: 0;
		list-style-type: none;
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0 0 5px 0 #808080;
		transition: opacity 0.4s;

		li {
			margin: 0 0 10px 0;

			&:last-child {
				margin: 0;
			}
		}

		label {
			display: flex;
			justify-content: space-between;
			position: relative;
			width: 100%;
			margin: 0;
			color: var(--blue-basic);

			&:before {
				content: '';
				position: absolute;
				top: 3px;
				left: 35px;
				width: 15px;
				height: 15px;
				border: 1px solid var(--blue-basic);
			}
		}

		input[type='radio'] {
			display: none;
		}

		input[type='radio']:checked + label::after {
			position: absolute;
			top: 4px;
			left: 37px;
			font-family: FontAwesome, sans-serif;
			content: '\f00c';
			font-size: 12px;
			color: var(--blue-basic);
		}
	}

	&.language--opened {
		.language-current {
			display: block;
		}
	}
}

/* ------------------------------------------------------------ *\
	Animation for the arrow, when multi currency menu was open;
\* ------------------------------------------------------------ */
.language--opened {
	.language-current > i {
		transform: rotate(180deg);
	}
}

/* ------------------------------------------------------------ *\
	The same colors behaviour, when user is scrolling down/up;
\* ------------------------------------------------------------ */

header.fixed {
	.language {
		.language-btn {
			color: var(--blue-basic);
		}
	}
}

@media screen and (max-width: 767px) {
	.language {
		right: 80px;

		.language-btn {
			color: var(--blue-basic);

			span {
				display: none;
			}
		}
	}
}
