/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */

.shell {
	max-width: $shell-max-width + $shell-gutter * 2;
	padding-right: $shell-gutter;
	padding-left: $shell-gutter;
	margin: auto;

	&--fluid {
		max-width: none;
	}
}
