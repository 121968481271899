/* ------------------------------------------------------------ *\
	Accordion
\* ------------------------------------------------------------ */

.accordion {
	position: relative; 

	+ .accordion {
		margin-top: 34px;
	}

	.accordion__head {
		position: relative; 
		border-bottom: 2px solid #28306c;
		padding-bottom: 7px;
		transition: border-color .4s; 
		cursor: pointer;

		h6 {
			position: relative; 
			padding-left: 7px; 
			margin: 0;
			color: #28306c;
			transition: color .4s;

			i {
				position: absolute; 
				top: 50%; 
				left: 13px; 
				backface-visibility: hidden;

				+ i {
					opacity: 0; 
				}
			}

			strong {
				font-weight: 500;
			}

			.ico-setting {
				margin: -10px 0 0  -12px; 
				
				+ i {
					margin: -10px 0 0  -12px; 
				}
			}
		}

		.accordion__ico {
			position: absolute; 
			top: 50%; 
			right: 4px; 
			transform: translateY(-50%); 
			line-height: 0;

			i {
				transition: opacity .4s, visibility .4s; 

				+ i {
					position: absolute;
					top: 0;
					left: 0;
					opacity: 0; visibility: hidden;
				}
			}
		}
	}

	.accordion__body {
		position: absolute; 
		top: 105%;
		left: 0; 
		width: 100%; 
		background: #fff; 
		z-index: 20;
		margin-top: 7px;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);
		opacity: 0; 
		visibility: hidden; 
		transition: opacity .4s, visibility .4s; 
	}

	&.expanded {
		.accordion__head {
			border-color: #de3f41;

			h6 {
				color: #de3f41;

				i {
					opacity: 0; 

					+ i {
						opacity: 1; 
					}
				}
			}

			.accordion__ico {
				i {
					opacity: 0; 
					visibility: hidden; 

					+ i {
						opacity: 1; 
						visibility: visible; 
					}
				}
			}
		}
		
		.accordion__body {
			opacity: 1; 
			visibility: visible; 
		}
	}
}
