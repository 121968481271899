/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro {
	min-height: 100vh; 
	position: relative; 
	background: #118c8f; 
	z-index: 199;

	.intro__image {
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
        background-size: cover; 
        background-position: center center; 
	}

	.intro__title {
		font-size: 48px; 
		line-height: 1.2;
		margin: 0;
		letter-spacing: 0.05em; 
	}

	.intro__inner {
		position: absolute; 
		top: 50%; 
		transform: translateY(-50%);
		left: 0; 
		width: 100%; 
		z-index: 10;
		margin-top: 48px;
	}

	.intro__content {
		text-align: center; 
		color: #fff; 

		h2 {
			text-transform: uppercase;
			letter-spacing: 0.05em;
			margin-bottom: 43px;
			text-shadow: 0 0 30px rgba(0, 0, 0, 0.7);

			span {
				color: #ffb74b; 
			}
		}

		.form-location {
			margin: auto; 
			max-width: 450px; 
		}

		&--primary {
			h2 {
				margin: 0;
			}
		}

	}

	.intro__btn {
		position: absolute; 
		bottom: 15px; 
		left: 50%; 
		transform: translateX(-50%); 
		z-index: 20;

		.ico-angle-down-white {
			display: block;
			width: 18px;
			height: 20px;
			background: url('../../frontend/_assets/images/icons/icon-arrow-down-white.png') no-repeat;
			background-size: contain;
		}
	}

	.breadcrumps {
		position: absolute; 
		top: 100px; 
		left: 50%; 
		margin-left: -705px;
		z-index: 200;
	}

	/*  Intro Primary  */
	&--primary {
		padding: 250px 0 50px; 

		.container {
			position: relative; 
			z-index: 10;
		}

		.intro__ico {
			position: absolute; 
		}
		
		.icon-cirlce-big-d1 {
			position: absolute; 
			top: 158px; 
			right: -51px;
		}

		.ico-grapes {
			bottom: 204px; 
			left: 86px;
		}
		.ico-flag {
			bottom: 246px; 
			left: 239px;
		}
		.ico-flipflops {
			bottom: 100px; 
			left: 193px;
		}
	}

	/*  Intro Secondary  */
	&--secondary {
		background: #28306c;
		padding: 206px 0 20px; 

		.icon-cirlce-big-h1 {
			position: absolute; 
			top: 158px; 
			right: -51px;
		}
	}

	/*  Intro Teritary  */
	&--teritary {
		height: 100vh;
		min-height: 0;
		max-height: 745px;

		@media (max-width: 1442px) {
			height: 550px;
		}

		@media (max-width: 767px) {
			height: 353px;
		}

		.intro__inner {
			margin-top: 30px;
		}

		.intro__inner {
			margin-top: 66px;
		}
	}

	/*  Intro Quaternary  */
	&--quaternary {
		background: #28306c;
		padding: 250px 0 89px; 
		min-height: 0;

		.country-image > img {
			max-height: 350px;
			object-fit: cover;
		}

		.icon-cirlce-big-c1 {
			position: absolute; 
			top: 51px; 
			right: -114px;
		}
	}

	/*  Intro Size1  */
	&--size1 {
		max-height: 745px; 
		min-height: 0; 
		height: 100vh;

		@media (max-width: 1442px) {
			height: 550px;
		}

		@media (max-width: 767px) {
			height: 353px;
		}

		.intro__inner {
			margin-top: 30px;
		}
	}

	/*  Intro Alt  */
	&--alt {

		.intro__content {
			text-align: left;
		}

		.intro__inner {
			margin-top: 38px;
		}

		.intro__btn {
			margin-left: -687px;
			bottom: 25px;
		}
	}

	/*  Intro Alt  */
	&--alternative {
		.intro__inner {
			margin: 50px 0 0; 
		}

		.intro__content {
			h2 {
				margin-bottom: 32px;
			}
		}
	}

	&--size2 {
		height: 100vh; 
		max-height: 768px;
	}
}

@media(max-width: 1750px){
	.intro {
		&--primary {
			.ico-grapes {
				left: -50px;
			}
			.ico-flag {
				left: 82px;
			}
			.ico-flipflops {
				left: 67px;
			}
		}
	}
}

@media #{$large-desktop} {
	.intro {
		.breadcrumps {
			left: 15px; 
			margin-left: 0;
		}

		/*  Intro Primary  */
		&--primary {
			.ico-grapes {
				left: -82px;
			}
			.ico-flag {
				left: 50px;
			}
			.ico-flipflops {
				left: 35px;
			}
		}

		/*  Intro Secondary  */
		&--secondary {
			padding: 190px 0 50px; 
		}

		/*  Intro Secondary  */
		&--teritary {
			max-height: 550px; 
		}

		/*  Intro Alt  */
		&--alt {
			.intro__btn {
				left: 15px; 
				margin-left: 0;
				transform: translateX(0); 
			}
		}
	}
}

@media #{$small-desktop} {
	.intro {
		.intro__ico {
			display: none;
		}
		
		.breadcrumps {
			left: 50%; 
			margin-left: -555px;
		}

		/*  Intro Alt  */
		&--alt {
			.intro__btn {
				left: 50%; 
				margin-left: -555px;
			}
		}

		&--size2,
		&--size1 {
			max-height: 550px;
		}
	}
}

@media(max-width: 1199px){
	.intro {
		.breadcrumps {
			margin-left: -465px;
		}

		/*  Intro Alt  */
		&--alt {
			.intro__btn {
				margin-left: -465px;
			}
		}
	}
}

@media #{$tablet-portrait} {
	.intro {
		.intro__title {
			font-size: 40px;
		}

		.intro__inner {
			margin-top: 0;
		}
	}
}

@media(max-width: 992px){
	.intro {
		.breadcrumps {
			margin-left: -345px;
		}

		/*  Intro Alt  */
		&--alt {
			.intro__btn {
				margin-left: -345px;
			}
		}
	}
}

@media #{$mobile} {
	.intro {
		.breadcrumps {
			top: 58px;
			margin-left: -255px;
		}

		.intro__btn {
			bottom: 23px;
		}

		.intro__title {
			font-size: 24px; 
		}

		.intro__content {
			h2 {
				margin-bottom: 22px; 
			}

			.form-location {
				max-width: 225px; 

				.select {

					&:after {
						width: 17px; 
						height: 10px;

					}

					select {
						height: 35px;
						font-size: 13px;
						padding: 0 15px; 
					}
				}
			}

			&--primary {
				h2 {
					margin: 0;
				}
			}
		}

		/*  Intro Primary  */
		&--primary {
			padding: 160px 0 117px; 
			min-height: 0;

			.intro__btn {
				display: none;
			}

			.intro__ico {
				display: block; 
				left: 50%; 
				bottom: auto;
				top: 70px; 
				transform: translateX(-50%);

				&.icon-cirlce-big-d1 {
					display: none;
				}
			}

			.ico-flag {
				margin-left: 27px;
			}

			.ico-grapes {
				margin-left: -30px;
				top: 86px;
			}


			.ico-flipflops {
				top: auto;
				bottom: 35px;
			}

		}

		/*  Intro Secondary  */
		&--secondary {
			padding: 129px 0 50px; 
		}

		/*  Intro Teritary  */
		&--teritary {
			max-height: 400px;

			.intro__inner {
				margin-top: 0;
			}
		}

		/*  Intro Alt  */
		&--alt {
			.intro__btn {
				margin-left: 0;
				left: 50%; 
				transform: translateX(-50%);
				bottom: 25px;
			}

			.intro__inner {
				top: auto;
				bottom: 90px; 
				transform: translateY(0);  
			}
		}

		/*  Intro Quaternary  */
		&--quaternary {
			padding: 129px 0 50px; 
		}
		
		&--size2 {
			height: initial; 
			max-height: initial;
			padding-top: 59%;

			.breadcrumps {
				top: 8px;
			}
		}
	}
}

@media(max-width: 576px){
	.intro {
		.breadcrumps {
			margin-left: 0;
			left: 15px;
		}
	}
}