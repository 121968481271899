/* ------------------------------------------------------------ *\
	Features Alt
\* ------------------------------------------------------------ */

@media #{$tablet-portrait} {
	.features-alt {
		margin-bottom: -29px;

		.col-md-6 {
			margin-bottom: 31px;
		}
		
	}
}

/* ------------------------------------------------------------ *\
	Feature Alt
\* ------------------------------------------------------------ */

.feature-alt {
	color: #28306c; 

	h3 {
		position: relative; 
		padding-left: 60px;
		font-weight: 700;
		margin-bottom: 17px;

		i {
			position: absolute; 
			top: 50%; 
			left: 0; 
			transform: translateY(-50%);
		}
	}

	p {
		font-weight: 500;
		letter-spacing: 0.025em;
		margin-bottom: 0; 

		+ p {
			margin-top: 20px;
		}
	}
}

@media #{$large-desktop} {
	.feature-alt {
		br {
			display: none; 
		}
	}
}

@media #{$mobile} {
	.feature-alt {
		h3 {
			padding-left: 30px;
			margin-bottom: 15px; 
		}

		p {
			margin: 0;
		}
	}
}

/* ------------------------------------------------------------ *\
	Features
\* ------------------------------------------------------------ */

@media #{$tablet-portrait} {
	.features {
		margin-bottom: -30px;
		
		.col-md-6 {
			margin-bottom: 30px;
		}
	}
} 

@media #{$mobile} {
	.features {
		margin-bottom: -40px;
		
		.col-md-6 {
			margin-bottom: 40px;
		}
	}
} 

/* ------------------------------------------------------------ *\
	Feature
\* ------------------------------------------------------------ */

.feature {
	color: #28306c;
	letter-spacing: 0.025em;

	p {
		margin: 0; 

		span {
			font-style: italic; 
		}

		+ p {
			margin-top: 18px;
		}
	}

	> i {
		display: block; 
		margin-left: auto; 
	}

	.ico-cat-green-alt,
	.ico-cat {
		margin-right: 32px;
	}

	.ico-cafe-green-alt,
	.ico-cafe {
		position: relative; 
		top: -26px;
		margin-bottom: -26px;
	}

	/*  Feature Primary  */
	&--primary {
		position: relative; 
		padding-top: 69px;

		> i {
			position: absolute; 
			top: -39px; 
			right: 0; 
		}
	}
}

@media #{$large-desktop} {
	.feature {
		br {
			display: none; 
		}

		.ico-cafe-green-alt,
		.ico-cafe {
			top: 0px;
			margin-bottom: 0px;
		}

		/*  Feature Primary  */
		&--primary {
			padding-top: 90px;

			> i {
				top: -49px; 
			}
		}
	}
}

@media #{$mobile} {
	.feature {
		position: relative; 
		padding: 0 95px 0 0; 

		p {
			+ p {
				margin-top: 15px;
			}
		}

		i {
			position: absolute !important; 
			top: 50% !important;  
			right: 0; 
			transform: translateY(-50%);
			margin: 0;
		}

		/*  Feature Primary  */
		&--primary {
			padding: 0 0 0 100px; 

			i {
				right: auto;
				left: 0; 
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Features Primary
\* ------------------------------------------------------------ */

.features-primary {
	position: relative; 
	z-index: 10;
}

@media #{$tablet-portrait} {
	.features-primary {
		margin-bottom: -30px;
		
		.col-md-4,
		.col-md-6 {
			margin-bottom: 30px;
		}
	}
}

@media #{$mobile} {
	.features-primary {
		margin-bottom: -26px;
		
		.col-md-4,
		.col-md-6 {
			margin-bottom: 26px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Feature Primary
\* ------------------------------------------------------------ */

.feature-primary {
	text-align: center; 

	h3 {
		margin: 15px 0 0; 
		font-weight: 700;
		color: #363e76; 
	}

	p {
		margin: 19px 0 0; 
		color: #28306c; 
	}

	.feature__ico {
		position: relative; 
		display: inline-block;

		i {
			+ i {
				position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);  
			}
		}

		.ico-mouse {
			margin: -27px 0 0 -2px;
		}

		.ico-label {
			margin: -3px 0 0 32px;
		}

		.ico-camera {
			margin: 1px 0 0 -12px;
		}
	}

	&--alt {
		h3 {
			font-size: 24px; 
		}

		p {
			font-size: 18px; 
			line-height: 1.2;
			margin: 24px 0 0; 
		}
	}
}

@media #{$small-desktop} {
	.feature-primary {
		br {
			display: none;
		}
	}
}

@media #{$tablet-portrait} {
	.feature-primary {
		h3 {
			font-size: 17px;
		}

		p {
			font-size: 13px;
		}
	}
}

@media #{$mobile} {
	.feature-primary {
		h3 {
			margin: 7px 0 0; 
			font-size: 13px;
		}

		p {
			margin: 16px 0 0; 
		}

		.feature__ico {
			.ico-mouse {
				margin: -18px 0 0 -4px; 
			}

			.ico-label {
				margin: -4px 0 0 16px; 
			}

			.ico-camera {
				margin: -5px 0 0 -5px; 
			}
		}

		&--alt {
			p {
				font-size: 13px; 
				margin: 14px 0 0; 
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Features Secondary
\* ------------------------------------------------------------ */

.features-secondary {
	.row {
		margin: 0 -23px;
	}

	.col-lg-4 {
		padding: 0 23px; 
	}

	&--alt {
		.row {
			margin-bottom: -50px; 
		}

		.col-lg-4 {
			margin-bottom: 50px;
		}
	}
}

@media #{$large-desktop} {
	.features-secondary {
		.row {
			margin: 0 -15px;
		}

		.col-lg-4 {
			padding: 0 15px; 
		}

		&--alt {
			.row {
				margin-bottom: -30px; 
			}

			.col-lg-4 {
				margin-bottom: 30px;
			}
		}
	}	
}

@media #{$mobile} {
	.features-secondary {
		&--alt {
			.row {
				margin-bottom: 0; 
			}

			.col-lg-4 {
				margin: 0; 

				+ .col-lg-4 {
					margin-top: 25px;
				}
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Feature Secondary
\* ------------------------------------------------------------ */

.feature-secondary {
	position: relative; 
	padding-top: 89.8%; 
	background: #f6f6f6; 
	text-align: center; 
	overflow: hidden;

	> a {
		display: block; 
		overflow: hidden;

		&:hover {
			.feature__image {
				transform: scale(1.1); 
			}
		}
	}

	.feature__image {
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
		background-size: cover; 
		transition: transform .4s; 
	}

	.feature__body {
		position: absolute; 
		top: 50%; 
		left: 0; 
		width: 100%;
		padding-top: 100px; 
		transform: translateY(-50%); 
		margin-top: 20px;
		z-index: 10;

		i {
			position: absolute;
			top: 23px;
			left: 50%;
			transform: translate(-50%, -50%);  
		}

		h6 {
			text-transform: uppercase;
			letter-spacing: 0.025em;
			margin-bottom: 25px;
		}

		h3 {
			font-weight: 700;

			a {
				text-decoration: none;
			}
		}

		&--primary {
			color: #28306c !important;
		}
	}

	.feature__ico {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); 
		opacity: .1;  
		margin-top: 10px;

		&.icon-cirlce-f2 {
			transform: translate(-50%, -50%) rotate(-105deg); 
		}
	}

	/*  Feature Secondary Alt  */
	&--alt {
		padding-top: 68%;

		.feature__body {
			padding: 0 10px;
			color: #fff; 
			margin: -3px 0 0;
		}
	}
}

@media #{$tablet-portrait} {
	.feature-secondary {
		padding-top: 100%;

		.feature__body {
			padding-top: 80px; 

			h6 {
				margin-bottom: 10px;
			}
		}

		/*  Feature Secondary Alt  */
		&--alt {
			padding-top: 68%;

			.feature__body {
				padding-top: 0;
				margin: 0;
			}
		}
	}
}

@media #{$mobile} {
	.feature-secondary {
		padding-top: 63%;

		.feature__body {
			padding-top: 62px;
			margin-top: -6px;

			h6 {
				margin-bottom: 15px;
			}
		}

		.feature__ico {
			margin-top: 0;
		}

		/*  Feature Secondary Alt  */
		&--alt {
			padding-top: 69%;

			h3 {
				font-size: 13px;
				margin: 0;
			}

			.feature__body {
				padding-top: 0;
				margin: 0;
			}
		}
	}
}
