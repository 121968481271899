.experiences-section {
	padding: 35px 0;
	background-color: #fafafa;

	@media screen and (min-width: 768px) {
		padding: 70px 0;
	}

	&-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 35px;

		&__title {
			width: 65%;
			margin-bottom: 0;
			font-size: 2rem;

			@media screen and (min-width: 768px) {
				font-size: 1.25rem;
			}
		}

		&__doodles {
			width: 35%;
			text-align: right;

			img {
				height: 45px;

				@media screen and (min-width: 768px) {
					display: none;
				}
			}

			img + img {
				margin-top: 4vh;
			}
		}
	}


}

.card-block-item {
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	cursor: pointer;
	transition: transform 0.5s, opacity 1s;

	& .card-block-header {
		position: relative;
		width: 100%;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		overflow: hidden;

		& > img {
            display: block;
			object-fit: cover;
			max-height: 200px;

			@media screen and (min-width: 768px) {
				max-height: initial;
			}
		}

		&__bg {
			display: flex;
			align-items: center;
			justify-content: space-around;
			position: absolute;
			z-index: 20;
			width: 100%;
			height: 100%;
			border-top-right-radius: 5px;
			border-top-left-radius: 5px;
			overflow: hidden;
			background-color: rgba(0, 0, 0, 0.5);
			opacity: 0;
			transition: .4s;
		}

		&__btn {
			padding: 19px 45px;
			border: none;
			color: #fff;
			background-color: var(--red-basic);
			text-transform: uppercase;
			text-decoration: none;
			transition: transform .4s;

			&:hover,
			&:focus,
			&:active {
				color: var(--red-basic);
				background-color: #fff;
				box-shadow: 0 0 0 2px var(--red-basic);
			}

			&:hover {
				transform: scale(1.03);
			}
		}
	}

	.category {
		position: absolute;
		top: -13px;
		min-width: 135px;
		padding: 3px 10px;
		font-size: 1.2rem;
		text-align: center;
		text-transform: lowercase;
		border-radius: 5px;
		color: #fff;
		z-index: 21;
	}

	.card-block-main {
		&__title {
			margin-bottom: 25px;
			font-size: 1.5rem;

			@media screen and (max-width: 991px){
				font-size: 17px !important;
			}

			.card-tile__description {
				display: block;
				margin-top: 7px;
				font-size: 16px;
				text-transform: initial;
			}
		}

		.list-gadgets {
			margin-left: 10px;

			li.name-country-city {
				display: block;
			}

			@media screen and (min-width: 768px) {
				margin-left: 0;
			}
		}
	}
}

.card-block-item:hover .card-block-header__bg,
.card-block-item:focus .card-block-header__bg,
.card-block-item:active .card-block-header__bg {
	opacity: 1;
}

.section__body {
	margin-bottom: 50px;
}