/* ------------------------------------------------------------ *\
	Slick
\* ------------------------------------------------------------ */
.slick-arrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border-color: transparent;
	font-size: 0;
	background-color: transparent;
	z-index: 20;
	outline: none !important;

	&:after {
		font-family: FontAwesome, sans-serif;
		content: '\f054';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		font-size: 32px;
		text-shadow: 0 0 1px #000;

		@media screen and (min-width: 768px) {
			font-size: 50px;
			background: none;
		}
	}
}

.slick-prev {
	left: 15px;

	&:after {
		content: '\f053';
	}
}

.slick-next {
	right: 15px;
}

.become-a-host-host-carousel {
	.slick-arrow {
		position: absolute;
		top: 50%;
		z-index: 1;
		-webkit-transform: translate(0,-50%);
		transform: translate(0,-50%);
		width: 60px;
		height: 50px;
		border-radius: 50%;
		border: 0;
		background-color: transparent;
		font-size: 0;

		&:after {
			content: '';
		}
	}


	.slick-prev {
		left: -110px;
		top: -20px;

		&::after {
			-webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
			-moz-transform: matrix(-1, 0, 0, 1, 0, 0);
			-o-transform: matrix(-1, 0, 0, 1, 0, 0);
			transform: matrix(-1, 0, 0, 1, 0, 0);
		}
	}

	.slick-next {
		right: -22px;
	}

	.host-carousel {
		position: relative;

		.slider-action-buttons {
			position: absolute;
			right: 60px;
			top: -30px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider {
	position: relative; 
	z-index: 20;

	.slick-prev {
		left: 50px;
	}

	.slick-next {
		right: 50px;
	}

	.slider__ico {
		position: absolute; 

		&--size1 {
			top: 90px; 
			right: -113px;
		}
	}

	.breadcrumps {
		position: absolute; 
		top: 7px; 
		left: 50%; 
		margin-left: -705px;
		z-index: 20;
		color: #28306c; 

		li {
			+ li {
				&:after {
					color: #28306c; 
				}
			}
		}
	}

	.slider__head {
		text-align: center; 
		font-size: 1.2rem;
		line-height: 1.2; 
		margin: 0 0 60px; 

		h1 {
			font-size: 1.875rem;
			text-transform: uppercase;
			letter-spacing: 0.05em;
			margin:  0 0 29px; 
		}

		h2 {
			font-size: 1.2rem;
			letter-spacing: 0;
			font-weight: normal;
			margin: 0;
		}
	}

	/*  Slider Secondary  */
	&--secondary {
		padding-top: 78px;

		.slick-arrow {
			left: 50%;
			margin-top: 3px;
		}

		.slick-prev {
			margin-left: -674px;
		}
		.slick-next {
			margin-left: 616px;
		}
	}
}

@media #{$large-desktop} {
	.slider {
		.breadcrumps {
			left: 15px; 
			margin-left: 0;
		}

		.slick-prev {
			left: 15px; 
		}

		.slick-next {
			right: 15px; 
		}

		/*  Slider Secondary  */
		&--secondary {
			.slick-arrow {
				margin: 0;
			}

			.slick-prev {
				left: 30px;
			}
			.slick-next {
				right: 30px; 
				left: auto; 
			}
		}
	}
}

@media #{$small-desktop} {
	.slider {
		.breadcrumps {
			left: 50%; 
			margin-left: -555px;
		}

		.slider__ico {
			display: none;
		}

		/*  Slider Primary  */
		&--secondary {
			.slick-arrow {
				left: 50%; 
				right: auto; 
			}

			.slick-prev {
				margin-left: -450px;
			}

			.slick-next {
				margin-left: 390px;
			}
		}	
	}
}

@media(max-width: 1199px){
	.slider {
		.breadcrumps {
			margin-left: -465px;
		}
	}
}

@media #{$tablet-portrait} {
	.slider {
		.slider__head {
			br {
				display: none;
			}
		}
	}
}

@media(max-width: 992px){
	.slider {
		.breadcrumps {
			margin-left: -345px;
		}

		.slick-arrow {
			top: 40%;
		}

		.slick-next {
			margin-left: 260px;
		}

		.slick-prev {
			margin-left: -320px;
		}
	}
}

@media #{$mobile} {
	.slider {
		.breadcrumps {
			margin-left: -255px;

			li:before {
				color: #007bff;
			}
		}

		.slick-arrow {
			top: 25%;
		}

		.slick-prev {
			margin-left: -240px;
		}

		.slick-next {
			margin-left: 180px;
		}

		/*  Slider Secondary  */
		&--secondary {
			padding-top: 40px; 

			.slider__clip {
				max-height: 510px;
			}
		}
	}
}

@media(max-width: 576px){
	.slider {
		.breadcrumps {
			margin-left: 0;
			left: 15px;
		}

		.slick-arrow {
			margin: 0;
		}

		.slick-prev {
			left: 40px;
		}

		.slick-next {
			left: auto;
			right: 40px;
		}
	}
}

@media(max-width: 400px){
	.slider {
		.slick-arrow {
			top: 20%;
		}

		.slick-prev {
			left: 20px;
		}

		.slick-next {
			left: auto;
			right: 20px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Slider Main
\* ------------------------------------------------------------ */

.slider-main {
	position: relative; 

	.breadcrumps {
		position: absolute; 
		top: 100px; 
		left: 50%; 
		margin-left: -705px;
		z-index: 200;
	}

	.slick-arrow {
		margin-top: 44px;
	}

	.slick-prev {
		left: 50px;
	}

	.slick-next {
		right: 50px;
	}
}

@media #{$large-desktop} {
	.slider-main {
		.breadcrumps {
			left: 15px; 
			margin-left: 0;
		}

		.slick-arrow {
			margin-top: 0;
		}

		.slick-prev {
			left: 15px;
		}

		.slick-next {
			right: 15px;
		}
	}
}

@media #{$mobile} {
	.slider-main {
		.breadcrumps {
			top: 58px;
			z-index: 100; 
		}

		.slick-arrow {
			display: none !important;
		}
	}
}


/* ------------------------------------------------------------ *\
	Slider Primary
\* ------------------------------------------------------------ */

.slider-primary {

	.slick-prev {
		left: -190px;
	}

	.slick-next {
		right: -190px;
	}
}

@media(max-width: 1850px){
	.slider-primary {
		.slick-prev {
			left: -100px;
		}

		.slick-next {
			right: -100px;
		}
	}	
}

@media(max-width: 1650px){
	.slider-primary {
		.slick-prev {
			left: 15px;
		}

		.slick-next {
			right: 15px;
		}
	}	
}

@media #{$mobile} {
	.slider-primary {
		margin: 0 -15px;

		.slider__slides {
			padding-bottom: 17px;
		}
	}
}


/* ------------------------------------------------------------ *\
	Slider Mobile
\* ------------------------------------------------------------ */

@media #{$mobile} {
	.slider-mobile {
		margin: 0 -15px; 

		.slick-prev {
			left: 15px;
		}

		.slick-next {
			right: 15px;
		}

		.row {
			display: block; 
			margin: 0 !important;

			.col-md-6 {
				padding: 0;
			}
		}

		.article {
			box-shadow: none;

			.article__body {
				padding: 52px 16px 26px; 
			}
		}

		&.slider-mobile--primary {
			padding: 0 15px; 

			.row {
				display: block; 
				margin: 0 -15px !important;

				.col-md-6 {
					padding: 0 15px;
				}
			}

			.slick-slide {
				padding: 10px 0; 
			}

			.slick-arrow {
				display: none !important; 
			}

			.article {
				box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);

				.article__body {
					padding: 25px 26px 53px; 
				}
			}
		}
	}
}

.slide-ex .slick-slide {
	margin: 0 10px;
}

// fix for slider on page host

.host__slider {
	height: 434px;
	overflow: hidden;
}

@media (max-width: 1333px) {
	.host__slider {
		height: 402px;
	}
}

@media (max-width: 1200px) {
	.host__slider {
		height: 344px;
	}
}

@media (max-width: 1024px) {
	.host__slider {
		height: 290px;
	}
}

@media (max-width: 768px) {
	.host__slider {
		height: 322px;
	}
}

// fix for slider on page experiences

.experiences__slider {
	height: 745px;
	overflow: hidden;

	@media (max-width: 1442px) {
		height: 550px;
	}

	@media (max-width: 767px) {
		height: 353px;
	}
}


