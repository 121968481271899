/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body {
	min-width: 320px;
	width: 100%;
	background: #fff;
	font-family: $font-family-sans-serif;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: var(--blue-basic);
	font-weight: 500;
}

button {
	outline: none;
}

a {
	color: inherit;
	text-decoration: underline;

	&:hover,
	&[href^="tel"] {
		text-decoration: none;
	}
}

h1 {
	font-size: 2rem;
	font-weight: 700;
}

h2 {
	font-size: 1.875rem;
	font-weight: 700;
}

h3 {
	font-size: 1.375rem;
	line-height: 1.2;
}

h6 {
	font-size: 1rem;
}


p {
	letter-spacing: 0.025em;
	font-weight: 500;
}

/* ------------------------------------------------------------ *\
	Classes for the colors;
\* ------------------------------------------------------------ */
.basic-red {
	color: var(--red-basic);
	fill: var(--red-basic);
}

.basic-blue {
	color: var(--blue-basic);
	fill: var(--blue-basic);
}

.basic-green {
	color: var(--green-basic);
	fill: var(--green-basic);
}

.basic-yellow {
	color: var(--yellow-basic);
	fill: var(--yellow-basic);
}

.white {
	color: var(--white);
	fill: var(--white);
}

/* ------------------------------------------------------------ *\
	Classes for the background colors;
\* ------------------------------------------------------------ */
.bc-red {
	background-color: var(--red-basic);
}

.bc-blue {
	background-color: var(--blue-basic);
}

.bc-green {
	background-color: var(--green-basic);
}

.bc-yellow {
	background-color: var(--yellow-basic);
}

.hide {
	visibility: hidden;
	opacity: 0;
}

.no-display,
#gtx-trans {
  display: none;
}

.main-caption {
	font-size: 2.2rem;
	font-weight: normal;
}

.selected {
	background-color: var(--blue-basic);
	color: var(--white);
}

.slick-dots {
	display: none !important;
}

.fa.fa-star {
	font-size: 20px;
	transition: .3s;

	@media screen and (max-width: 767px) {
		font-size: 18px;
	}
}

@media only screen and (min-width: 768px) {
	.no-scroll {
		overflow: hidden;
		padding: 0 7px 0 0;
	}
}

@media only screen and (max-width: 767px) {
	.no-scroll {
		overflow: hidden;
		position: fixed;
	}
}

.desktop-title {
    display: none;

    @media screen and (min-width: 768px) {
        display: initial;
    }
}

// ===== custom scroll for Mozilla Firefox =====
html,body {
	scrollbar-width: thin;
	scrollbar-color: var(--blue-basic) #f1f1f1;
}

// ===== custom scroll =====
::-webkit-scrollbar {
	width: 5px;

	@media screen and (min-width: 992px){
		width: 8px;
	}
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: var(--blue-basic);
}

::-webkit-scrollbar-thumb:hover {
	background: var(--blue-basic);
}

// =====

.a-btn {
	display: block;
	width: 100%;
	margin: 0 0 0 0;
	padding: 10px 30px;
	border: 2px solid var(--white);
	border-radius: 3px;
	color: var(--white);
	background-color: var(--blue-basic);
	font-size: 14px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	line-height: 1.5;
	cursor: pointer;
	transition: .5s;
	font-family: Poppins-Medium, sans-serif;

	&:focus,
	&:hover,
	&:active {
		color: var(--blue-basic);
		background-color: var(--white);
		border-color: var(--blue-basic);
	}

	&.white {
		border: 2px solid var(--blue-basic);
		color: var(--blue-basic);
		background-color: var(--white);

		&:focus,
		&:hover,
		&:active {
			color: var(--white);
			background-color: var(--blue-basic);
			border-color: var(--white);
		}
	}
}

.substrate {
	position: fixed;
	top: 0;
	z-index: 200;
	display: none;
	width: 100vw;
	height: 100vh;
	background-color: #00000050;

	&.show {
		display: block;
	}
}