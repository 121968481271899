/* ------------------------------------------------------------ *\
	Breadcrumps
\* ------------------------------------------------------------ */
.breadcrumps {
	font-size: 14px; 
	letter-spacing: 0.025em;
	color: #fff; 

	a {
		text-decoration: underline; 
	}

	ul {
		list-style: none; 

		li {
			display: inline-block; 
			position: relative; 

			+ li {
				margin-left: 0;
				padding-left: 12px;

				&:after {
					content: '/';
					position: absolute; 
					top: 50%; 
					left: 0; 
					transform: translateY(-50%); 
					color: #fff;  
				}
			}
		}
	}

	.active {
		a {
			text-decoration: none; 
		}
	}

	&--alt {
		color: #28306c;

		ul {
			li {
				&:before {
					color: #28306c !important;
				}

				+ li {
					&:after {
						color: #28306c;
					}
				}
			}
		}
	}
}

@media #{$mobile} {
	.breadcrumps {
		font-size: 10px; 

		ul {

			li {
				padding-right: 8px !important; 
				margin-right: 0 !important;

				&:after {
					display: none; 
				}

				&:before {
					content: '/';
					position: absolute; 
					top: 50%; 
					right: 0; 
					transform: translateY(-50%); 
					color: #fff;  
				}

				+ li {
					margin-left: 0; 
					padding-left: 0;
				}

				&:last-child {
					margin: 0 !important; 
					padding: 0 !important; 
					&:before {
						display: none !important; 
					}
				}
	
			}
		}
	}
}

/* ------------------------------------------------------------ *\
    Moved from heroPage.scss;
\* ------------------------------------------------------------ */
.white-bg {
	position: absolute;
	top: 94px;
	z-index: 100;
	width: 100%;
	text-align: left;

	.breadcrumps {
		position: initial;
		top: auto;
		left: auto;
		width: 100%;
		padding: 13px 0;
		margin-left: 0;

		ul {
			margin: 0;
		}

		li {
			font-size: 14px;
		}

		li:before,
		li:after {
			color: #ffffff;
		}

		a {
			color: #ffffff;
			text-decoration: underline;
		}

		.active a {
			color: #ffffff;
			text-decoration: none;
		}
	}
}

.white-bg-active {
	background-color: rgba(255, 255, 255, .9);

	.breadcrumps {
		li:before,
		li:after {
			color: #28306C;
		}

		a {
			color: #28306C;
			text-decoration: none;
		}

		.active a {
			color: #808080;
		}
	}
}

@media only screen and (max-width: 767px) {
	.white-bg {
		top: 49px;
		background-color: rgba(255, 255, 255, .9);

		.breadcrumps {
			li:before,
			li:after {
				color: #28306C;
			}

			a {
				color: #28306C;
				text-decoration: none;
			}

			.active a {
				color: #808080;
			}
		}
	}
}