@import '../common-variables.scss';
/* ------------------------------------------------------------ *\
	Articles
\* ------------------------------------------------------------ */

.articles {
	.row {
		margin: 0 -20px;
	}

	.col-lg-4 {
		padding: 0 20px;
	}

	&--size1 {
		.row {
			margin: 0 -12px;
		}

		.col-lg-4 {
			padding: 0 12px; 
		}	
	}
}

@media #{$large-desktop} {
	.articles {
		.row {
			margin: 0 -15px;
		}

		.col-lg-4 {
			padding: 0 15px; 
		}
	}	
}

@media #{$tablet-portrait} {
	.articles {
		margin-bottom: -30px;
		
		.col-md-6 {
			margin-bottom: 30px;
		}
	}
}

@media #{$mobile} {
	.articles {
		margin-bottom: -25px;
		
		.col-md-6 {
			margin-bottom: 25px;
		}

		&--size1 {
			margin-bottom: -40px;
			
			.col-md-6 {
				margin-bottom: 40px;
			}	
		}
	}
} 


/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.article {
	color: #fff;  
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);
	border-radius: 0;
	cursor: pointer;

	h3 {
		letter-spacing: 0.01em;
		font-weight: 700; 
		margin-bottom: 20px;

		span {
			font-size: 16px; 
			font-weight: 500;
			letter-spacing: 0.025em;
			display: block;
			text-transform: uppercase;
			margin-bottom: 3px;
		}
	}

	h6 {
		letter-spacing: 0.025em; 
		margin: 0;

		i {
			vertical-align: middle;
			margin-right: 10px;
			position: relative; 
			top: -2px;
			left: 1px; 
		}
	}

	p {
		margin-bottom: 0; 
		letter-spacing: 0.025em;

		+ p {
			margin-top: 20px;
		}

		+ h6 {
			margin-top: 19px;
		}
	}

	.link-to-review {
		text-decoration: none;

		&:hover,
		&:focus,
		&:active {
			color: var(--blue-basic);
			& > ul > li > i {
				color: var(--red-basic);
			}
		}
	}

	.list-stars {
		display: inline-flex;
		position: relative;
		margin: 3px 0 0 0;
		z-index: 22;
	}


	.article__ico {
		position: absolute; 
		bottom: -10px; 
		right: -32px;
	}
	
	.ico-burger {
		bottom: -12px; 
		right: -15px;
	}

	.ico-flowers {
		bottom: 0; 
		right: 9px;
	}

	.btn {
		margin-top: 9px;
	}

	.list-gadgets {
		margin-bottom: 12px;

		@media screen and (min-width:768px) {
			margin-bottom: 9px;
		}
	}

	.article__image-container {
		position: relative; 
		overflow: hidden;

		img {
			width: 100%;
			transition: transform .4s; 
		}
		
		a {
			display: block;
		}

		i {
			position: absolute; 
		}

		.article__image-alternative {
			padding-top: 68%; 
			display: block; 
			background-size: cover;
			backface-visibility: hidden;
			transition: transform .4s; 
		}

		.icon-article-circle11 {
			top: -56px; 
			left: 13%;
		}

		.icon-article-circle12 {
			bottom: 12%; 
			left: 4%;
		}

		.icon-article-circle13 {
			bottom: -45px; 
			right: -10%;
		}

		.icon-article-circle21 {
			top: -74px; 
			left: -12%;
		}

		.icon-article-circle22 {
			top: 4%; 
			right: 3%;
		}

		.icon-article-circle23 {
			bottom: -96px; 
			left: 20%;
		}

		.icon-article-circle31 {
			top: -57px; 
			left: 10%;
		}

		.icon-article-circle32 {
			top: 32%; 
			right: 0;
		}

		.icon-article-circle33 {
			bottom: -36px; 
			left: -12%;
		}
	}

	.article__image {
		padding-top: 68.5%; 
		background-size: cover; 
		position: relative; 
		overflow: hidden;
		transition: transform .4s;  
	}

	.article__label {
		position: absolute; 
		top: -11px; 
		right: -38px;
		font-size: 14px; 
		font-weight: 700; 
		background: #e84042; 
		width: 120px; 
		text-align: center; 
		padding: 34px 0 10px;
		transform: rotate(45deg);
		color: #fff; 
	}

	.article__body {
		position: relative; 
		padding: 36px 40px 80px;
		text-align: left;
		overflow: hidden;
		background: #28306c;
	}

	/*  Article Green  */
	&--green {
		.article__body {
			background: #118c8f;
		}
	}

	/*  Article Red  */
	&--red {
		.article__body {
			background: #e84042;
		}
	}

	/*  Article Primary  */
	&--primary {
		color: #28306c; 
		h3 {
			margin-bottom: 18px;

			+ p {
				margin-bottom: 16px;
			}
		}

		.article__image {
			padding-top: 91%;
		}

		.article__body {
			background: #fff;
		}
	}

	/*  Article Pirmary Alt  */
	&--primary-alt {
		box-shadow: none;
		border: 1px solid #c0c0c0;

		h4 {
			font-size: 18px; 
			line-height: 1.11; 
			font-weight: 700;
		}

		.article__body {
			border-top: 1px solid #c0c0c0;
			padding: 23px 19px 21px; 
			min-height: 91px;
		}
	}

	/*  Article Primary Def  */
	&--primary-def {
		p {
			min-height: 164px; 
		}

		.btn {
			min-width: 144px;
		}
	}

	/*  Article Yellow  */
	&--yellow {
		border: none;

		.article__body {
			border: none;
			background: #FFB74B; 
			color: #29316A; 
		}
	}
}

@media #{$large-desktop} {
	.article {
		.ico-flowers {
			right: -20px;
		}
	}
}

@media #{$small-desktop} {
	.article {
		.article__body {
			padding: 36px 25px 40px; 

			br {
				display: none; 
			}
		}

		.article__image-container {
			i {
				transform: scale(.6); 
			}
		}
	}
}

@media #{$tablet-portrait} {
	.article {
		.article__body {
			padding: 20px 20px 40px; 
		}
	}
}

@media #{$mobile} {
	.article {
		h3 {
			margin-bottom: 16px; 

			span {
				font-size: 11px; 
				margin-bottom: 0;
			}
		}

		p {
			+ p {
				margin-top: 15px;
			}

			+ h6 {
				margin-top: 13px;
			}
		}

		h6 {
			i {
				margin-right: 2px;
			}
		}

		.list-stars {
			display: inline-flex;
			align-items: baseline;
			margin: 4px 0 0 10px;

			li > .fa.fa-star {
				font-size: 1.2rem;
			}

			li:last-child {
				display: inline-flex;
				margin: 0 0 0 8px;

			}
		}

		.article__body {
			height: initial !important;
		}

		.article__ico {
			bottom: 12px; 
			right: -30px;
		}

		.ico-burger {
			bottom: -8px; 
			right: -13px;
		}

		.ico-flowers {
			bottom: 0; 
			right: -5px; 
		}

		.article__image-container {
			.icon-article-circle11 {
				top: -52px; 
				left: 7%;
			}

			.icon-article-circle12 {
				bottom: 6%; 
				left: -1%;
			}

			.icon-article-circle13 {
				bottom: -52px; 
				right: -18%;
			}

			.icon-article-circle21 {
				top: -75px; 
				left: -22%;
			}

			.icon-article-circle22 {
				top: -1%; 
				right: -2%;
			}

			.icon-article-circle23 {
				bottom: -85px; 
				left: 11%;
			}

			.icon-article-circle31 {
				top: -51px; 
				left: 4%;
			}

			.icon-article-circle32 {
				top: 23%; 
				right: -20px;
			}

			.icon-article-circle33 {
				bottom: -43px; 
				left: -19%;
			}
		}

		/*  Article Primary  */
		&--primary {
			h3 {
				+ p {
					margin-bottom: 1px;
				}
			}
			.article__body {
				padding: 24px 25px;
			}
		}

		/*  Article Pirmary Alt  */
		&--primary-alt {
			box-shadow: none;
			border: none;
			background: none;

			h4 {
				font-size: 18px; 
				line-height: 1.11; 
				font-weight: 700;
			}

			.article__body {
				border-top: none;
				padding: 9px 0 0; 
				min-height: 0;
				background: none;
			}
		}

		/*  Article Primary Def  */
		&--primary-def {
			p {
				min-height: 0; 
			}

			.btn {
				min-width: 97px;
				margin: 20px 0 0; 
			}
		}
	}
}


/* ------------------------------------------------------------ *\
	Article ALt 
\* ------------------------------------------------------------ */

.article-alt {
	display: flex;
	color: #fff; 

	h3 {
		letter-spacing: 0.01em;
		font-weight: 700; 
		margin-bottom: 36px;

		span {
			font-size: 16px; 
			font-weight: 500;
			letter-spacing: 0.025em;
			display: block;
			text-transform: uppercase;
			margin-bottom: 3px;
		}
	}

	p {
		margin: 0; 

		+ p {
			margin-top: 33px;
		}

		+ .btn {
			margin-top: 38px;
		}
	}

	.article__image {
		flex: 0 0 59.1%;
		max-width: 59.1%;
		position: relative; 
		height: 580px;


		.article__image-inner {
			position: absolute; 
			top: 0; 
			left: 0; 
			width: 100%; 
			height: 100%;
			background-size: cover;
			background-position: center center; 
		}
	}

	.article__body {
		flex: 0 0 40.9%;
		max-width: 40.9%;
		background: #28306c; 
		position: relative; 
		padding: 149px 20px 30px 80px; 

		.article__ico {
			position: absolute; 
			bottom: 21px; 
			left: 358px;
		}

		.ico-burger {
			width: 192px; 
			height: 157px;
		}

		.ico-food-1 {
			width: 231px; 
			height: 113px;
			left: auto;
			right: 20px; 
			bottom: 20px; 
		}

		.ico-flowers {
			width: 154px; 
			height: 183px;
			bottom: 0; 
			margin-left: 35px;
		}

		&--alt {
			padding: 122px 20px 30px 80px; 
		}
	}

	/*  Article Alt Green  */
	&--green {
		.article__body {
			background: #118c8f; 
		}
	}

	/*  Article Alt Primary  */
	&--primary {
		.article__image {
			order: 1;
			flex: 0 0 59.5%;
			max-width: 59.5%;
		}

		.article__body {
			flex: 0 0 40.5%;	
			max-width: 40.5%;
			padding: 118px 75px 30px 254px; 

			p {
				+ p {
					margin-top: 27px;
				}

				+ .btn {
					margin-top: 58px;
				}
			}
		}
	}
}

@media(max-width: 1500px){
	.article-alt {
		.article__body {
			padding: 90px 120px 30px 40px; 

			br {
				display: none; 
			}
		}

		.article__image {
			height: 495px; 
		}

		/*  Article Alt Primary  */
		&--primary {
			.article__body {
				padding: 90px 40px 30px 120px; 
			}
		}
	}
}

@media #{$large-desktop} {
	.article-alt {
		.article__body {
			flex: 0 0 50%;
			max-width: 50%; 
		}

		.article__image {
			flex: 0 0 50%;
			max-width: 50%; 
		}
	}
}

@media #{$small-desktop} {
	.article-alt {
		.article__body {
			padding: 50px 120px 90px 40px; 

			.ico-food-1 {
				width: 151px; 
				height: 75px;
			}

			.ico-burger {
				width: 113px; 
				height: 93px;
			}

			.ico-flowers {
				width: 84px; 
				height: 100px;
			}
		}

		.article__image {
			height: 525px;
		}

		/*  Article Alt Primary  */
		&--primary {
			.article__body {
				padding: 50px 40px 90px 92px; 
			}
		}
	}
}

@media #{$tablet-portrait} {
	.article-alt {
		flex-direction: column;

		.article__body {
			order: 1;
			max-width: 100%; 
			flex: 0 0 100%;
			padding: 80px 40px 80px; 

			.article__ico {
				left: auto;
				right: 5px; 
			}
		}

		.article__image {
			max-width: 100%; 
			flex: 0 0 100%;
			height: 400px; 
		}
	}
}

@media #{$mobile} {
	.article-alt {
		h3 {
			font-size: 13px; 
			margin-bottom: 16px; 

			span {
				font-size: 11px; 
				margin-bottom: 0;
			}
		}

		p {
			+ p {
				margin-top: 15px !important;
			}

			+ .btn {
				margin-top: 13px !important;
			}
		}

		h6 {
			i {
				margin-right: 2px;
			}
		}

		.article__image {
			height: initial;
			padding-top: 68%; 
		}

		.article__body {
			padding: 52px 16px 52px; 

			.ico-food-1 {
				width: 125px; 
				height: 62px;
			}

			.ico-burger {
				width: 94px; 
				height: 77px;
			}

			.ico-flowers {
				width: 70px; 
				height: 83px;
			}
		}

		.article__ico {
			bottom: 12px; 
			right: -30px;
		}

		.ico-burger {
			bottom: -8px; 
			right: -13px; 
		}

		.ico-flowers {
			bottom: 0; 
			right: -5px; 
		}
	}
}


/* ------------------------------------------------------------ *\
	Article Big
\* ------------------------------------------------------------ */

.article-big {
	position: relative; 
	color: #fff; 

	h2 {
		margin-bottom: 30px;
		text-transform: uppercase;
		letter-spacing: 0.07em;

		span {
			display: block; 
			font-size: 16px; 
			font-weight: 500; 
			margin-bottom: 5px;
			letter-spacing: 0.025em;
		}
	}

	p {
		margin-bottom: 15px;
		font-size: 22px; 
		font-weight: 500; 
		letter-spacing: 0;
	}

	.article__image {
		height: 746px; 
		background-size: cover; 
		background-position: center center; 
	}

	.article__inner {
		position: absolute; 
		bottom: 0; 
		left: 0; 
		width: 100%; 
		z-index: 10;
	}
}

@media #{$large-desktop} {
	.article-big {
		.article__image {
			height: 550px; 
		}	
	}
}

@media #{$mobile} {
	.article-big {
		h2 {
			letter-spacing: 0;
			margin-bottom: 14px; 

			span {
				font-size: 11px; 
				margin-bottom: 2px; 
			}
		}

		p {
			font-size: 12px; 
			letter-spacing: 0.025em;
			margin-bottom: 8px; 
		}

		.article__image {
			height: 353px;
		}	

		.article__inner {
			position: static; 
			color: #28306c; 
			padding: 44px 0 0; 
		}
	}
}

/* ------------------------------------------------------------ *\
	Article Primary
\* ------------------------------------------------------------ */

.article-primary {
	color: #fff; 
	position: relative; 
	z-index: 10; 

	.article__media {
		padding-right: 90px; 
		padding-top: 42px;
		text-align: center; 

		h2 {
			margin: 86px 0 0 43px; 
			text-transform: uppercase;
			letter-spacing: 0.05em;
		}
	}

	.article__image {
		position: relative;
		max-height: 340px;

		&:after {
			content: '';
			position: absolute; 
			top: 0; 
			left: 0; 
			width: calc(100% + 7px); 
			height: 100%; 
			background: #fab64e;  
		}

		&.host-single__image {
			width: 390px;
			height: 390px;
			margin: 0 auto;

			& img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			h1 {
				color: #b9b5bb;
				font-size: 16px;
				font-weight: initial;
				margin: -20px 0 0 -20px;
			}

			@media screen and (max-width: 1199px) {
				width: 300px;
				height: 300px;

				h1 {
					display: none;
				}
			}

			@media screen and (max-width: 991px) {
				width: 250px;
				height: 250px;

				h1 {
					display: none;
				}
			}
		}

		img {
			width: 100%;
			position: relative; 
			z-index: 10; 
			top: -35px; 
			left: 43px;
		}
	}

	.article__body {
		@media screen and (max-width: 768px) {
			&.host-single-intro {
				text-align: center;
			}
		}

		h1 {
			font-size: 1.875rem;
			margin-bottom: 0;
			text-transform: uppercase;
			text-align: center;

			@media screen and (min-width: 768px) {
				text-align: initial;
			}
		}

		h2,
		.country-hero__description {
			display: inline-block;
			margin-bottom: 57px;
			font-size: 1.875rem;
			
			span {
				color: #fab64e;
			}

			strong {
				text-transform: uppercase;
				letter-spacing: 0.05em;
			}
		}

		.country-hero__description {
			margin: 29px 0 0 0;
		}

		h6 {
			text-transform: uppercase; 
			font-weight: 700;
			letter-spacing: 0.05em; 
			margin-bottom: 21px;
		}

		p {
			min-height: 243px; 
			margin-bottom: 20px;
		}
	}

	/*  Article Primary Alt  */
	&--alt {
		.article__body {
			padding-left: 87px;

			h1 {
				+ h2 {
					margin: 29px 0 0;
				}
			}
		}

		.article__media {
			padding-right: 47px; 
		}

		.article__image {
			img {
				top: -37px; 
				left: 47px;
			}
		}
	}
}

@media #{$small-desktop} {
	.article-primary {
		.article__body {
			h2 {
				margin-bottom: 40px; 
			}

			.country-hero__description {
				margin-top: 20px;

				@media screen and (min-width: 768px) {
					margin-top: 29px;
				}
			}

			p {
				min-height: 0;
			}
		}

		/*  Article Primary Alt  */
		&--alt {
			br  {
				display: none; 
			}

			.article__body {
				padding-left: 0; 

				h2,
				.country-hero__description {
					margin-bottom: 0;
				}
			}
		}
	}
}

@media #{$tablet-portrait} {
	.article-primary {
		.article__media {
			padding-right: 20px; 

			h2 {
				margin-left: 20px;
			}
		}

		.article__image {
			img {
				left: 20px; 
				top: -20px;
			}
		}
	}
}

@media #{$mobile} {
	.article-primary {
		.article__media {
			padding: 0;
			margin-bottom: 24px;

			h2,
			.country-hero__description {
				margin: 22px 0 0; 
			}
		}

		.article__image {
			margin: 0 34px 0 17px; 

			img {
				top: -13px; 
				left: 13px;
			}
		}

		.article__body {
			h2,
			.country-hero__description {
				text-align: center;
				margin-bottom: 33px;
			}
		}

		/*  Article Primary Alt  */
		&--alt {
			.article__body {
				padding-left: 0; 

				h1 {
					+ h2 {
						margin: 20px 0 0; 
					}
				}
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Article Primary Frame
\* ------------------------------------------------------------ */

.article-primary-frame {
	.article__image {
		&:after {
			margin: -35px 0 0 43px; 
			width: calc(100%);
			transition: margin .4s, width .4s; 
		}
	}

	&.animated {
		.article__image {
			&:after {
				margin: 0; 
				width: calc(100% + 7px);
			}
		}	
	}
}

/* ------------------------------------------------------------ *\
	Article Secondary
\* ------------------------------------------------------------ */

.article-secondary {
	position: relative; 

	.socials-alt {
		+ h1 {
			margin-top: 23px;
		}
	}

	.testimonial-alt {
		+ .article__content {
			margin-top: 31px;
		}

		+ .widget-map {
			margin-top: 27px;
		}
	}

	.widget-map {
		+ .article__content {
			margin-top: 31px;
		}
	}

	.article__tooltip {
		position: absolute; 
		top: 87px; 
		right: 100%;
		width: 166px;
		background: #fff; 
		box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, .16);
		text-align: center; 
		padding: 23px 15px 23px; 
		margin-right: 5px;
		border-radius: 5px;

		.article__link-def {
			display: block; 
			position: relative; 
			text-decoration: none;
			text-align: left;
			color: #212020; 
			font-size: 14px;
			padding-right: 10px;  
			font-weight: 700; 
			font-family: 'Helvetica', sans-serif; 
			margin-bottom: 10px;
			transition: color .4s; 

			&:hover {
				color: #FFB74B; 
			}

			i {
				position: absolute; 
				top: 0; 
				right: -6px; 
			}
		}
	}

	h1.article__title {
		font-size: 40px; 
		line-height: 1.13;
		margin-bottom: 13px;
	}

	h2.article__title {
		margin-bottom: 13px;
	}

	.article__content {
		font-size: 1.2rem;
		line-height: 1.67;

		p {
			letter-spacing: 0;

			a {
				text-decoration: none;
				color: #F20448; 
			}
		}
	}

	.article__image {
		margin-right: 13px;
		margin-left: 3px;
		text-align: center; 

		img {
			width: 100%;
		}

		p {
			margin: 10px 0 0;
			font-size: 12px; 
			font-weight: 400; 
			color: #BFBFBF; 
			font-family: 'Helvetica', sans-serif;  
			letter-spacing: 0;
		}

		+ .article__content {
			margin: 33px 0 0; 
		}
	}

	+ .article-secondary {
		margin-top: 75px;
	}

	+ .form-subscribe {
		margin: 50px 12px 0 0;

		+ .article-secondary {
			margin-top: 45px;
		}
	}

	+ .widget-teritary {
		margin: 48px 12px 0 0;
	}
}

@media #{$large-desktop} {
	.article-secondary {
		.article__tooltip {
			width: 60px; 
			padding: 30px 10px 10px; 

			.article__link-def {
				font-size: 0; 
				position: absolute; 
				top: 0; 
				left: 0; 
				width: 100%; 
				height: 100%;
				padding: 0;

				i {
					top: 10px; 
					right: 10px; 
				}
			}
		}

		.article__title {
			br {
				display: none; 
			}
		}
	}
}

@media #{$mobile} {
	.article-secondary {
		h1.article__title {
			font-size: 25px;
			line-height: 1.2; 
			margin-top: 0 !important;
		}

		h2.article__title {
			font-size: 25px;
			line-height: 1.2; 
			margin-top: 0 !important;
			margin-bottom: 7px;
		}

		.article__tooltip {
			display: none;
		}

		.article__content {
			line-height: 1.35; 
		}

		.widget-map {
			+ .article__content {
				margin-top: 27px;
			}
		}

		.article__image {
			margin: 0;
			text-align: left;

			+ .article__content {
				margin: 20px 0 0; 
			}
		}

		+ .article-secondary {
			margin-top: 55px; 
		}

		+ .widget-teritary {
			margin: 52px -15px 0; 
		}
	}
}

/* ------------------------------------------------------------ *\
	Article Small
\* ------------------------------------------------------------ */

.article-small {
	.separator {
		display: inline-block; 
		width: 10px; 
		text-align: center;
	}

	.article__image {
		padding: 0 10px;

		img {
			height: 195px;
			object-fit: cover;
			transition: transform .4s;

			@media screen and (max-width: 1200px){
				height: 142px;
			}

			@media screen and (max-width: 992px) {
				height: 111px;
			}

			@media screen and (max-width: 767px) {
				height: auto;
				width: 100%;
			}
		}

		a {
			display: block; 
			overflow: hidden; 

			&:hover img {
				transform: scale(1.1); 
			}
		}
	}

	.article__body {
		padding: 10px 0 0 10px;

		h4,
		.experience-card__title,
		.host-card__name {
			font-size: 18px; 
			font-weight: 700; 
			margin: 0;

			span {
				font-size: 20px;
			}
		}

		p {
			margin: 7px 0 0; 
			font-size: 12px;
		}
	}

	+ .article-small {
		margin: 47px 0 0; 
	}

	/*  Article Small Primary  */
	&--primary {
		text-align: center;

		.article__body {
			padding: 10px 0 0; 
		}
	}

	/*  Article Small Secondary  */
	&--secondary {
		text-align: center;
		color: #29316A; 

		.article__body {
			padding: 10px 0 0; 

			p {
				font-size: 15px; 
				line-height: 0.93; 
				margin: -1px 0 0; 
			}
		}
	}
}

@media #{$large-desktop} {
	.article-small {
		br {
			display: none; 
		}
	}
}

@media #{$mobile} {
	.article-small {
		.article__image {
			padding: 0;
		}

		.article__body {
			padding: 4px 0 0; 

			h4 {
				font-size: 18px; 

				span {
					font-size: 18px;
				}
			}

			p {
				margin: 5px 0 0; 
			}
		}

		+ .article-small {
			margin: 30px 0 0; 
		}

		/*  Article Small Primary  */
		&--primary {
			text-align: left;
		}

		/*  Article Small Secondary  */
		&--secondary {
			text-align: left;
		}
	}	
}

/* ------------------------------------------------------------ *\
	Article Teritary
\* ------------------------------------------------------------ */

.article-teritary {
	background: #fab64e; 
	color: #28306c; 
	position: relative; 
	font-size: 22px; 
	line-height: 1.2; 

	p {
		letter-spacing: 0.035em;
		font-style: italic; 
		margin: 0; 

		+ h6 {
			margin: 18px 0 0; 
		}
	}

	h6 {
		letter-spacing: 0.025em;

		+ img {
			margin-top: 39px;
		}
	}

	.article__body {
		width: 50%; 
		height: 550px;
		display: flex; 
		align-items: center; 
		padding: 0 90px 0 164px; 
	}	

	.article__content {
		position: relative; 
		top: 5px;
	}

	.article__image {
		position: absolute; 
		top: 0; 
		right: 0;
		width: 50%; 
		height: 100%; 
		background-size: cover;  
	}
}

@media #{$large-desktop} {
	.article-teritary {
		.article__body {
			padding: 0 30px 0 105px; 
		}
	}
}

@media #{$small-desktop} {
	.article-teritary {
		.article__body {
			height: 420px;
			padding: 0 30px 0 105px; 
		}
	}
}

@media #{$tablet-portrait} {
	.article-teritary {
		.article__body {
			height: initial;
			padding: 80px 30px 30px; 
			width: 100%;
		}

		.article__image {
			position: static; 
			width: 100%; 
			height: 300px;
		}
	}
}

@media #{$mobile} {
	.article-teritary {
		font-size: 14px; 

		.article__body {
			padding: 40px 15px 30px; 
		}

		.article__image {
			height: 230px;
		}
	}
}


// -----------------------

.article__inner {
	width: 100%;
	padding: 10px 0;
	background-color: var(--blue-basic);
	color: #ffffff;
	text-align: left;

	& h2 {
		margin-bottom: 0.5rem;
		text-transform: uppercase;
		letter-spacing: 0.07em;
	}

	& .description__wrapper {
		display: flex;
		align-items: flex-end;

		@media screen and (max-width: 1023px) {
			flex-direction: column;
			align-items: flex-start;
		}

		p {
			margin-right: 10%;

			@media screen and (max-width: 1023px) {
				margin-right: 0;
				margin-bottom: 5px;
			}
		}
	}

	& ul {
		flex-shrink: 0;
		margin-bottom: 0;
	}
}

.article__shadow {
	text-shadow: 0 0 3px #000000;
}

.article__body p {
	line-height: 1.2;
	margin-bottom: 0;
	font-size: 1.375rem;
	font-weight: 500;
	letter-spacing: 0;
}

@media (max-width: 767px) {
	.article__inner {
		position: static; 
		padding: 10px 0;

		& p {
			font-size: 14px;
		}
	}

	.article__shadow {
		text-shadow: none;
	}
}

@media (max-width: 1200px) {
	p.paragpaph__size {
		font-size: 16px;
	}
}

.article__body-category {
	text-transform: uppercase;
}

// ------------------- style for page reviews (slider) -------------------
.reviews-intro-slider {
	padding-top: 133px;

	@media screen and (min-width: 768px) {
		padding-top: 171px;
	}
}

.review {
	height: 540px;
	max-height: none !important;
	overflow: hidden;
	.slider__slides {
		height: 100%;

		.slick-arrow {
			border: none;

			&::after {
				content: '';
				bottom: 50%;
				top: initial;
				left: 0;
				display: block;
				width: 55px;
				height: 40px;
				background: var(--color-accent-gold);
				mask: url($main-icons-sprite + '#carousel-arrow') no-repeat;
				mask-size: contain;
			}

			&.slick-prev {

				&::after {
					transform: rotate(180deg);
					-webkit-transform: rotate(180deg);
					-moz-transform: rotate(180deg);
					-ms-transform:rotate(180deg);
				}
			}

			&.slick-next {

				&::after {
					transform: rotate(0deg);
					-webkit-transform: rotate(0deg);
					-moz-transform: rotate(0deg);
					-ms-transform:rotate(0deg);
				}
			}
		}
	}
}
.review-slider {
	margin-bottom: 60px;
	padding: 0 15px;
	&__container {
		position: relative;
		height: 520px;
	}
	&__content {
		position: absolute;
		bottom: 0;
		padding: 30px 15px 30px;
		background-color: #fab64e;
		& > p {
			margin-bottom: 15px;
			font-size: 1.2rem;
			font-style: italic;
			letter-spacing: 0;
			text-align: justify;
	  	}
	  	& > span {
			font-size: 1.125rem;
	  	}
	}
}
  
@media (min-width: 576px) {
	.review {
	  	height: 500px;
	}
	.review-slider {
		display: block !important;
		max-width: 540px;
		margin: 0 auto 60px;
		&__container {
			height: 500px;
		}
		&__content {
			padding: 30px 50px 30px;
		}
	}
}
  
@media (min-width: 768px) {
	.review {
		height: 580px;
		margin-bottom: 20px;
	}
	.review-slider {
		max-width: 720px;
		&__container {
			height: 580px;
		}
	}
}
  
@media (min-width: 992px) {
	.review {
	  	height: 420px;
		.slider__slides {
			.slick-arrow {
				top: 40%;
				bottom: initial;

				&::after {
					background: var(--color-neutral-lightest);
					mask: url($main-icons-sprite + '#icon-arrow-down') no-repeat;
				}

				&.slick-prev {

					&::after {
						margin: 0 -2px 0 0;
						transform: rotate(90deg);
						-webkit-transform: rotate(90deg);
						-moz-transform: rotate(90deg);
						-ms-transform:rotate(90deg);
					}
				}

				&.slick-next {

					&::after {
						margin: 0 0 0 -2px;
						transform: rotate(-90deg);
						-webkit-transform: rotate(-90deg);
						-moz-transform: rotate(-90deg);
						-ms-transform:rotate(-90deg);
					}
				}
			}
		}
	}
	.review-slider {
		max-width: 960px;
		&__container {
			display: flex;
			flex-direction: row-reverse;
			height: 420px;
		}
		&__img {
			width: 50%;
			& > img {
				width: 100%;
				height: 420px;
				object-fit: cover;
			}
		}
		&__content {
			position: static;
			width: 50%;
			padding: 45px 30px 0 85px;
		}
	}
}
  
@media (min-width: 1201px) {
	.review {
	  	height: 550px;
	}
	.review-slider {
		max-width: 1442px;
		&__container {
			height: 550px;
		}
		&__img {
			& > img {
				height: 550px;
			}
		}
		&__content {
			padding: 70px 30px 0 85px;
		}
	}
}
  
@media (min-width: 1443px) {
	.review-slider {
	  	&__content {
			padding: 90px 35px 0 110px;
	  	}
	}
}

@media (max-width: 767px) {
	.country-image img {
		height: 200px !important;
	}
}

