/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 202;
    text-align: center;
    padding: 12px 0;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        box-shadow: 0 0 90px 69px rgba(0, 0, 0, 0.6);
        margin-top: -1px;
        transition: opacity 0.4s, visibility 0.4s;
    }

    .container {
        position: relative;
        z-index: 101;
    }

    .header__select {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);

        select {
            height: initial;
            width: inherit;
            padding: 12px 10px;
            border-width: 2px;
            letter-spacing: initial;
            line-height: 1;
            transition: color 0.4s, border-color 0.4s;

            @media screen and (max-width: 1023px) {
                padding: 5px;
                font-size: 10px;
            }
        }

        &:after {
            background-image: none !important;
        }
    }

    &.fixed {
        background: #fff;
        border-bottom: 1px solid #e5e5e5;

        &:after {
            opacity: 0;
            visibility: hidden;
        }

        .logo {
            background-image: url(../../_build/assets/images/logo-secondary.png);
        }

        .header__select {
            &:after {
                background-image: url(../../_build/assets/images/temp/ico-angle-down.png);
            }

            select {
                color: #e84042;
                border-color: #e84042;
            }
        }
    }

    /*  Header Select  */
    &--select {
        .header__select {
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.4s, visibility 0.4s;
        }
    }

    /*  Header Visible select  */
    &.visible-select {
        .header__select {
            opacity: 1;
            visibility: visible;
        }
    }
}

@media #{$mobile} {
    .header {
        padding: 9px 0 7px;
        transition: background 0.4s, transform 0.4s;
        z-index: 250;
        background: #fff;
        border-bottom: 1px solid #e5e5e5;

        &:after {
            opacity: 0;
            visibility: hidden;
        }

        .header__select {
            display: none;
        }

        .logo {
            background-image: url(../../_build/assets/images/logo-secondary.png);
        }

        .header__select {
            &:after {
                background-image: url(../../_build/assets/images/temp/ico-angle-down.png);
            }

            select {
                color: #e84042;
                border-color: #e84042;
            }
        }

        &.up {
            transform: translateY(-100%);
        }

        &.active {
            transform: translateY(0) !important;
        }
    }
}

// ------------------------------------------

.blue__bar {
    margin: 0 0 20px 0;
    padding: 25px 0;
    color: #ffffff;
    background-color: var(--color-primary);
    h2 {
        text-shadow: 0 0 5px #000;
        text-transform: uppercase;
    }
}