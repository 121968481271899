/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */


@media(min-width: 1201px){
	.container {
		max-width: 1442px;

		&--size1 {
			max-width: 1205px; 
		}

		&--size2 {
			max-width: 1190px; 
		}
	}
}

@media #{$mobile} {
	.container {
		&--small {
			padding: 0 35px; 
		}
	}
}

/* ------------------------------------------------------------ *\
	Container Inner
\* ------------------------------------------------------------ */

.container-inner {
	margin: 0 -15px; 
}

@media #{$mobile} {
	.container-inner {
		margin: 0; 
	}	
}
