.general-filter {
	position: relative;
	margin-bottom: 18px;
	font-size: 16px;
	z-index: 200;

	@media screen and (max-width: 991px) {
		font-size: 14px;
	}

	@media screen and (max-width: 767px) {
		margin-bottom: 0;
	}

	& > strong {
		display: inline-block;
		font-size: 14px;
		margin-bottom: 15px;
	}

	&__buttons {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media screen and (max-width: 767px) {
			font-size: 12px;
		}

		label {
			svg {
				margin: 0 10px -7px 0;
				fill: var(--color-primary);

				@media screen and (min-width: 992px) {
					width: 30px;
					height: 30px;
					margin-bottom: -10px;
				}
			}

			&:hover,
			&:focus,
			&:active {
				svg {
					fill: #fff;
				}
			}
		}

		// LEFT FILTERS;
		&__left {
			position: relative;
			z-index: 151;

			@media screen and (max-width: 767px) {
				display: none;
			}

			.filters__btn:not(:last-child) {
				margin-right: -7px;
			}
		}

		label.active-filter__radio {
			padding-bottom: 16px;
			border-bottom: none;

			@media screen and (max-width: 991px) {
				padding-bottom: 12px;
			}
		}

		.filters__btn {
			position: relative;
			padding: 14px 50px 14px 20px;
			border: 2px solid var(--blue-basic);
			text-transform: uppercase;
			margin: 0;
			background-color: #fff;

			@media screen and (max-width: 991px) {
				padding: 10px 40px 10px 15px;
			}

			&:hover {
				color: #fff;
				background-color: var(--blue-basic);
			}

			input[type='radio'] {
				display: none;
			}

			i {
				font-size: 32px;
				position: absolute;
				top: 8px;
				right: 15px;

				@media screen and (max-width: 991px) {
					font-size: 28px;
					top: 5px;
					right: 10px;
				}

				@media screen and (max-width: 767px) {
					font-size: 24px;
					right: 5px;
				}
			}

			input[type='radio']:checked + i + ul {
				display: inline-block;
			}
		}

		// RIGHT FILTERS
		&__right {
			display: flex;

			@media screen and (max-width: 767px) {
				width: 100%;
			}

			.filters__btn-long {
				padding-right: 130px;

				@media screen and (max-width: 991px) {
					padding-right: 60px;
				}

				@media screen and (max-width: 767px) {
					padding: 10px 10px 10px 5px;
				}
			}

			.sort-by,
			.filters-mob {
				@media screen and (max-width: 767px) {
					width: 50%;

					& > .filters__btn-long {
						width: 100%;
					}

					& > .filters-mob__btn {
						border-right: none;
					}
				}
			}

			.sort-by {
				position: relative;
				z-index: 152;

				&__list {
					display: none;
					position: absolute;
					left: -110px;
					top: 10px;
					padding: 10px 0;
					background-color: #f2f2f2;
					box-shadow: 0 0 5px 0 #808080;

					@media screen and (max-width: 767px) {
						width: 195%;
						left: -100%;
						top: 12px;
					}

					label {
						display: block;
						position: relative;
						margin: 0;
						padding: 4px 15px 4px 30px;
						transition: 0.4s;

						@media screen and (max-width: 991px) {
							padding: 2px 10px 2px 25px;
						}

						&:hover,
						&:focus,
						&:active {
							background-color: #299092;
							color: #fff;
						}

						&:hover:before,
						&:focus:before,
						&:active:before {
							font-family: FontAwesome, sans-serif;
							content: '\f00c';
							position: absolute;
							top: 2px;
							left: 7px;
							color: #fff;

							@media screen and (max-width: 991px) {
								left: 5px;
							}
						}

						input[type='radio'] {
							display: none;
						}
					}
				}
			}

			.filters-mob {
				position: relative;

				&__btn {
					display: none;

					@media screen and (max-width: 767px) {
						display: inline-block;
					}

					.fa-sliders {
						font-size: 16px;
						margin-top: 5px;
						transform: rotate(90deg);

						@media screen and (max-width: 767px) {
							font-size: 14px;
						}
					}
				}

				&__wrapper {
					display: none;
					position: fixed;
					top: 110px;
					right: 0;
					width: 87%;
					background-color: #fff;
					z-index: 152;
				}

				&__menu {
					margin: 0;
					list-style-type: none;
					box-shadow: 0 0 5px 0 #808080;

					& > li:not(:first-child) {
						display: flex;
						justify-content: space-between;
						align-items: center;
						text-transform: capitalize;
						padding: 10px 30px;
						margin-top: -1px;
						border: 1px solid var(--blue-basic);

						&:not(:nth-child(2)):hover,
						&:not(:nth-child(2)):focus,
						&:not(:nth-child(2)):active {
							background-color: var(--blue-basic);
							color: #fff;
						}

						& > i {
							float: right;
						}

						b {
							font-weight: normal;
							color: #2f9093;
						}
					}

					&__close {
						position: relative;

						&__btn {
							position: absolute;
							top: -1px;
							left: -35px;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 36px;
							height: 36px;
							border: 1px solid var(--blue-basic);
							background-color: var(--blue-basic);
							color: #fff;

							&:hover,
							&:focus,
							&:active {
								background-color: #fff;
								color: var(--blue-basic);
							}

							& > i {
								font-size: 18px;
							}
						}
					}
				}

				&__submenu {
					display: none;
					position: absolute;
					top: 35px;
					width: 100%;
					background-color: #fff;
					box-shadow: 0 3px 5px 0 #808080;
					overflow-y: scroll;

					ul {
						flex-wrap: nowrap;
						width: 100%;
						max-height: initial;
						padding-top: 20px;

						& > li > label::before {
							top: -1px;
						}

						& > li > input[type='checkbox']:checked + label::after {
							font-size: 20px;
							top: -6px;
							left: 0;
						}
					}

					.filter-view-exp {
						margin: 20px 10px;
					}
				}

				&.filters-mob--opened {
					.filters-mob__wrapper {
						display: block;
						z-index: 160;
					}
				}
			}
		}
	}

	// hide submenus and mob-elements;
	.country-submenu-list,
	.city-submenu-list,
	.experience-submenu-list {
		display: none;
	}

	// SUBMENU;
	.filter__submenu {
		position: absolute;
		top: 80px;
		left: 0;
		width: 100%;
		padding-top: 30px;
		background-color: #fff;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		border-top: 2px solid var(--blue-basic);
		box-shadow: 0 4px 10px -2px #808080;

		@media screen and (max-width: 991px) {
			top: 69px;
		}

		@media screen and (max-height: 768px) and (orientation: landscape) {
			max-height: 500px;
			overflow: auto;
		}

		@media screen and (max-height: 415px) and (orientation: landscape) {
			max-height: 150px;
			overflow: auto;
		}

		// apply-btn
		.apply-filter {
			margin: 30px 0 30px 14px;
		}

		// country-list
		&__list {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			align-items: flex-start;
			height: 350px;
			overflow-y: auto;
			margin: 0;
			padding-left: 15px;
			list-style-type: none;

			@media screen and (max-width: 1441px) {
				max-height: 351px;
			}

			@media screen and (max-width: 1199px) {
				max-height: 430px;
			}

			@media screen and (max-width: 991px) {
				max-height: 505px;
			}

			li {
				position: relative;
				flex-shrink: 0;
				padding-left: 30px;
				white-space: nowrap;
				margin-bottom: 20px;

				@media screen and (max-width: 1199px) {
					margin-right: 10px;
					font-size: 14px;
				}

				@media screen and (max-width: 991px) {
					font-size: 12px;
				}

				label {
					margin: 0;

					&::before {
						content: '';
						position: absolute;
						top: 3px;
						left: 0;
						width: 15px;
						height: 15px;
						border: 1px solid var(--blue-basic);

						@media screen and (max-width: 1199px) {
							top: 0;
						}

						@media screen and (max-width: 991px) {
							top: 0;
							width: 13px;
							height: 13px;
						}
					}
				}

				input[type='checkbox'] {
					display: none;
				}

				input[type='checkbox']:checked + label {
					text-decoration: underline;
				}

				input[type='checkbox']:checked + label::after {
					position: absolute;
					top: 4px;
					left: 2px;
					font-family: FontAwesome, sans-serif;
					content: '\f00c';
					font-size: 12px;
				}
			}
		}

		&__button {
			box-shadow: 0 0 5px 0 grey;
			max-height: 75px;
		}
	}

	// result-list
	.filter__submenu__result {
		height: 0;
		display: flex;
		align-items: flex-start;
		border-top: 2px solid var(--blue-basic);

		&-list {
			margin: 0;
			list-style-type: none;

			& > li:not(:first-child) {
				margin-left: 30px;
			}

			& > li {
				display: inline-block;
				margin-bottom: 10px;

				i {
					margin-left: 5px;
					font-size: 20px;
					vertical-align: baseline;
					cursor: pointer;
				}
			}
		}
	}

	// apply & view exp btn's
	.filter-red-btn {
		min-width: 128px;
		padding: 10px 18px;
		font: inherit;
		font-weight: normal;
		text-transform: uppercase;
		border: 2px solid var(--red-basic);
		background-color: #fff;
		color: var(--red-basic);
		transition: 0.4s;

		&:disabled {
			opacity: 0.4;
			cursor: not-allowed;
		}

		&:hover:not([disabled]),
		&:focus:not([disabled]),
		&:active:not([disabled]) {
			background-color: var(--red-basic);
			color: #fff;
		}
	}

	// clear All-btn
	.clean-result__btn {
		display: none;
		flex-shrink: 0;
		margin-left: 30px;
		border: none;
		font: inherit;
		background-color: transparent;
		text-decoration: underline;

		@media screen and (max-width: 767px) {
			margin-left: 0;
		}
	}

	.main-result {
		display: flex;
		align-items: flex-start;
		margin-top: 20px;
	}
	.main-result {
		width: 100%;

		@media screen and (max-width: 767px) {
			display: inline-block;
			text-align: center;

			&-list {
				width: 100%;
			}
		}
	}
}
