.btn-loader {
    border: 10px solid #FFF8ED;
    border-top: 10px solid #E84042;
    display: inline-block;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}