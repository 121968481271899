/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	appearance: none;
	font-weight: 700; 
	font-size: 16px; 
	letter-spacing: 0.025em;
	color: var(--red-basic); 
	border: 3px solid var(--red-basic);
	border-radius: 0;
	min-width: 128px; 
	padding: 10px 18px; 
	transition: background .4s, color .4s, border-color .4s; 
	background: #ffffff; 

	&:focus,
	&:hover {
		background: var(--red-basic);
		color: #fff; 
	}


	&--red {
		background: #e84042; 
		color: #fff; 

		&:hover {
			background: #fff; 
			color: #e84042; 
		}
	}

	&--yellow {
		background: #FFB74B; 
		color: #fff; 

		&:hover {
			background: #fff; 
			color: #FFB74B; 
		}
	}

	&--green {
		background: #6BC14B; 
		border-color: #6BC14B;
		color: #fff; 

		&:hover {
			background: #fff; 
			color: #6BC14B; 
		}
	}

	&--blue {
		background: #2a3070;
		border-color: #2a3070; 
		color: #fff; 
		width: 100%;
		letter-spacing: 0;
		font-size: 13px !important;
		padding: 7px 10px !important; 
	}

	&--border-white {
		border-color: #fff;
		color: #fff; 
		text-transform: uppercase;
		font-size: 14px; 
		font-weight: 500;
		letter-spacing: 0.1em;
		padding: 11px 17px; 
		min-width: 189px;

		&:hover {
			background: #fff; 
			color: #e84042; 
		}
	}

	&--size1 {
		padding: 15px 30px; 
	}

	&--size2 {
		min-width: 144px;
	}

	&--size3 {
		min-width: 155px;
	}

	&--size4 {
		padding: 10px 34px; 
	}
}

@media #{$mobile} {
	.btn {
		min-width: 81px; 
		border-width: 1px;
		font-size: 11px; 
		padding: 9px 10px; 

	}
}

/* ------------------------------------------------------------ *\
	Button Chat
\* ------------------------------------------------------------ */

.btn-chat {
	background: #d60140; 
	color: #fff !important; 
	text-decoration: none;
	min-width: 256px; 
	text-align: center; 
	border-radius: 10px 10px 0 0; 
	padding: 12px 10px 12px 10px; 
	border-top: 1px solid #927076;
	transition: opacity .4s;

	i {
		position: absolute; 
		top: 50%; 
		left: 10px; 
		transform: translateY(-50%);

		+ i {
			display: none;
		}
	}

	span {
		+ span {
			display: none; 
		}
	}

	&:hover {
		opacity: .8;
	}
}

@media #{$mobile} {
	.btn-chat {
		min-width: 110px; 
		border-radius: 5px;
		border: none;
		background: #e90045;
		text-align: right; 
		padding: 4px 15px; 
		overflow: hidden;

		&:after {
			content: '';
			position: absolute; 
			top: 0; 
			left: 0; 
			width: 43px; 
			height: 100%; 
			background: #d8003c;  
		}

		i {
			display: none; 
			left: 12px; 
			z-index: 5;

			+ i {
				display: inline-block;
			}
		}

		span {
			display: none; 
			font-size: 19px;

			+ span {
				display: inline-block;
			}
		}
	}
}