/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
	padding: 87px 0 57px; 
	position: relative; 

	.section__ico {
		position: absolute; 

		&--size1 {
			top: 49px; 
			right: 22px;
			opacity: .1;
		}

		&--size2 {
			top: -185px; 
			left: -52px;
			opacity: .1;
		}

		&--size3 {
			top: -245px; 
			right: -52px;
			opacity: .1; 
			width: 415px; 
			height: 437px;
		}

		&--size4 {
			top: -137px; 
			left: -42px;
			opacity: .1; 
			width: 415px; 
			height: 437px;
		}

		&--size5 {
			top: -162px; 
			right: -54px;
			opacity: .1; 
			width: 415px; 
			height: 437px;
		}

		&--size6 {
			top: -125px; 
			right: -33px;
			opacity: .1;
		}

		&--size7 {
			top: -41px; 
			right: -50px;
		}

		&--size8 {
			top: 21%; 
			left: -72px;
		}

		&--size9 {
			top: 53.5%; 
			right: -49px;
		}

		&--size10 {
			top: -92px; 
			left: -78px;
		}

		&--size11 {
			top: 536px; 
			right: -78px;
		}

		&--size12 {
			top: 233px; 
			left: -56px;
		}

		&--size13 {
			top: 46.7%; 
			right: 31px;
		}

		&--size14 {
			top: 68.4%; 
			left: -201px;
		}

		&--size15 {
			top: -100px; 
			right: -25px;
		}

		&--size16 {
			top: 24px; 
			left: -37px;
		}

		&--size18 {
			top: 68%; 
			left: -37px;
		}

		&--size19 {
			top: 31px; 
			right: 12px;
		}

		&--size21 {
			top: 36%; 
			left: -83px;
			opacity: .1; 
		}

		&--size22 {
			bottom: 0; 
			right: -50px;
			opacity: .1; 
		}

		&--size23 {
			top: -120px; 
			left: -210px;
		}

		&--size24 {
			top: 303px; 
			right: -210px;
		}

		&--size25 {
			bottom: -70px; 
			left: -42px;
			z-index: 10;
		}

		&--size26 {
			top: 140px; 
			right: -50px;
		}

		&--size27 {
			top: 44%; 
			left: -35px;
		}

		&--size28 {
			top: -96px; 
			right: -50px;
		}

		&--size35 {
			bottom: 2px; 
			left: 50%;
			margin-left: 227px; 
			transform: scale(.78); 
		}

		&--size36 {
			top: -30px; 
			left: -39px;
		}

		&--size37 {
			bottom: -432px; 
			right: -53px;
		}
	}

	.articles {
		position: relative; 
		z-index: 20;
	}

	.section__head {
		margin-bottom: 50px;
		position: relative; 

		h1,
		h2 {
			color: #28306c; 
			margin: 0;

			i {
				vertical-align: middle;
				position: relative; 
				top: -8px;
				margin-left: 40px;
			}

			+ p {
				margin: 38px 0 0; 
			}
		}

		h2 {
			font-size: 1.75rem;
		}

		&--center {
			text-align: center; 
		}
	}

	.section__actions {
		text-align: center; 
		margin-top: 39px;

		p {
			margin: 17px 0 0; 
			font-size: 14px; 
		}
	}

	.section__content {
		padding-left: 2px;
		color: #28306c;
		padding-right: 41px;

		p {
			margin: 0; 

			+ p {
				margin-top: 21px;
			}
		}

		&--alt {
			padding: 5px 0 0 47px;

			p {
				+ p {
					margin-top: 20px;
				}
			}
		}
	}

	.section__content-alt {
		color: #28306c;

		h1 {
			letter-spacing: 0;
			margin-bottom: 40px;
		}

		h2 {
			letter-spacing: 0;
			margin-bottom: 36px;	
		}

		strong.content-title {
			display: inline-block;
			font-size: 2rem;
			margin-bottom: 40px;
		}

		p {
			font-size: 24px; 
			line-height: 1.2;
			margin: 0; 
			letter-spacing: 0;

			+ p {
				margin-top: 20px;
			}
		}

		&--primary {
			p {
				font-size: 1.2rem;
			}
		}
	}

	.section__actions-alt {
		position: fixed; 
		bottom: 0; 
		left: 0; 
		width: 100%; 
		z-index: 100; 
		background: #363e76; 
		padding: 20px 0; 

		a {
			text-decoration: none;
			text-transform: uppercase; 
			font-size: 13px;
			position: relative; 
			padding-left: 26px;
			letter-spacing: 0.025em;
			color: #fff !important; 

			i {
				position: absolute; 
				top: 50%; 
				left: 0; 
				transform: translateY(-50%); 
			}
		}
	}

	/*  Section Size1  */ 
	&--size1 {
		padding: 33px 0; 

		.section__head {
			margin-bottom: 40px;
		}
	}

	/*  Section Size2  */ 
	&--size2 {
		padding: 24px 0; 

		.section__head {
			margin-bottom: 40px;
		}
	}

	/*  Section Size3  */ 
	&--size3 {
		padding: 32px 0; 

		.section__head {
			margin-bottom: 40px;
		}
	}

	/*  Section Size4  */ 
	&--size4 {
		padding: 20px 0; 

		.section__head {
			margin-bottom: 37px;
			letter-spacing: 0.015em;
		}
	}

	/*  Section Size5  */ 
	&--size5 {
		padding: 36px 0; 

		.section__head {
			margin-bottom: 33px;
		}
	}

	/*  Section Size6  */ 
	&--size6 {
		padding: 18px 0; 

		.section__head {
			margin-bottom: 33px;
		}
	}

	/*  Section Size7  */ 
	&--size7 {
		padding: 69px 0 86px; 

		.section__head {
			margin-bottom: 15px;
		}
	}

	/*  Section Size8  */ 
	&--size8 {
		padding: 66px 0 40px; 

		.section__head {
			margin-bottom: 44px;
		}
	}

	/*  Section Size9  */ 
	&--size9 {
		padding: 27px 0 40px; 

		.section__head {
			margin-bottom: 42px;
		}

		.section__actions {
			margin-top: -2px;
		}
	}

	/*  Section Size10  */ 
	&--size10 {
		padding: 76px 0 120px; 

		.section__actions {
			margin-top: 53px;
		}
	}

	/*  Section Size11  */ 
	&--size11 {
		.section__head {
			margin-bottom: 34px;
		}
	}

	/*  Section Size12  */ 
	&--size12 {
		padding: 43px 0;

		.section__head {
			margin-bottom: 42px;
		}
	}

	/*  Section Size13  */ 
	&--size13 {
		padding: 17px 0 25px;

		.section__head {
			margin-bottom: 42px;
		}
	}

	/*  Section Size14  */ 
	&--size14 {
		padding: 61px 0 90px;

		.section__head {
			margin-bottom: 42px;
		}
	}

	/*  Section Size15  */ 
	&--size15 {
		padding: 17px 0 60px;

		.section__head {
			margin-bottom: 40px;
		}
	}

	/*  Section Size16  */ 
	&--size16 {
		padding: 55px 0 30px;
	}

	/*  Section Size18  */ 
	&--size18 {
		padding: 17px 0 30px;

		.section__head {
			margin-bottom: 32px;
		}

		.section__content-alt {
			h2 {
				margin-bottom: 9px;
			}
		}
	}

	/*  Section Size19  */ 
	&--size19 {
		padding: 29px 0 60px;

		.section__head {
			margin-bottom: 40px;
		}

		.section__actions {
			margin-top: 40px;
		}
	}

	/*  Section Size20  */ 
	&--size21 {
		padding: 56px 0 30px;

		.section__head {
			margin-bottom: 38px;
		}

		.section__actions {
			margin-top: 41px;
		}
	}

	/*  Section Size20  */ 
	&--size22 {
		padding: 30px 0 60px;

		.section__head {
			margin-bottom: 38px;
		}

		.section__actions {
			margin-top: 39px;
		}
	}

	/*  Section Size20  */ 
	&--size23 {
		padding: 56px 0 3px;

		.section__head {
			margin-bottom: 32px;
		}

		.section__actions {
			margin-top: 39px;
		}
	}

	/*  Section Size24  */ 
	&--size24 {
		padding: 59px 0 30px;

		.section__head {
			margin-bottom: 67px;
		}
	}

	/*  Section Size25  */ 
	&--size25 {
		padding: 59px 0 30px;

		.section__head {
			margin-bottom: 40px;
		}
	}

	/*  Section Size26  */ 
	&--size26 {
		padding: 24px 0 40px;

		.section__head {
			margin-bottom: 72px;
		}
	}

	/*  Section Size27  */ 
	&--size27 {
		padding: 57px 0 40px;

		.filters {
			margin-bottom: 41px; 
		}

		.section__head {
			margin-bottom: 40px;
		}

		.section__actions {
			margin-top: 19px;
		}
	}

	/*  Section Size28  */ 
	&--size28 {
		padding: 15px 0 40px;

		.section__head {
			margin-bottom: 38px;
		}
	}

	/*  Section Size29  */ 
	&--size29 {
		padding: 47px 0 74px;

		.container {
			position: relative; 
			z-index: 10;
		}

		.section__head {
			margin-bottom: 32px;
		}
	}

	/*  Section Form  */ 
	&--form {
		padding: 53px 0 40px;

		.section__head {
			margin-bottom: 39px;
		}

		.section__body {
			padding-right: 62px;
		}

		.section__aside {
			text-align: left;
			padding-left: 15px;

			.section__head {
				margin-bottom: 60px;
			}

			.section__actions {
				text-align: center;
				padding-right: 203px;
				margin-top: 41px;
			}
		}
	}

	/*  Section Small  */
	&--small {
		padding: 24px 0 90px; 
	}

	/*  Section Steps  */
	&--steps {
		padding: 155px 0 65px; 
	}

	/*  Section Steps Alt  */
	&--steps-alt {
		padding: 24px 0 77px; 

		.section__head {
			margin-bottom: 73px;
		}
	}

	/*  Section Yellow  */
	&--yellow {
		background: #fab64c; 
		padding: 56px 0 60px;
		z-index: 10;

		.section__head {
			margin-bottom: 30px;
		}

		.section__actions {
			margin: 22px 0 0; 
		}
	}
	
	/*  Section Hosts  */
	&--hosts {
		padding: 87px 0 31px; 

		.section__head {
			margin-bottom: 54px;
		}
	}	

	/*  Section Blue  */
	&--blue {
		background: #29316A; 
		padding: 79px 0 53px; 

		.section__head {
			margin-bottom: 54px; 

			h1,
			h2 {
				color: #FFB74B; 
			}
		}
	}
}


@media #{$large-desktop} {
	.section {
		.section__content {
			padding: 0; 
		}

		.section__content-alt {
			br {
				display: none; 
			}
		}

		&--form {
			.section__body {
				padding-right: 0;
			}
		}
	}
}

@media #{$small-desktop} {
	.section {
		.section__ico {
			display: none;
		}

		.section__head {
			.section__head-image {
				top: -69px;
				right: -30px;
			}
		}

		.section__actions {
			margin-top: 65px;
		}

		&--size9 {
			.section__actions {
				margin: 25px 0 0; 
			}
		}
	}
}

@media #{$tablet-portrait} {
	.section {
		.section__head {
			.section__head-image {
				display: none; 
			}
		}
		
		&--size27 {
			padding-top: 0;
		}
	}
}

@media #{$mobile} {
	.section {
		padding: 43px 0 20px; 

		.section__head {
			margin-bottom: 31px; 

			h2 {
				+ p {
					margin: 14px 0 0; 

					br {
						display: none;
					}
				}
			}
		}

		.section__actions {
			margin-top: 25px;

			p {
				font-size: 11px;
				margin: 15px 0 0; 
			}
		}

		.section__content {
			font-size: 12px; 
			line-height: 1.2;

			p {
				+ p {
					margin: 12px 0 0; 
				}
			}
		}

		.section__content-alt {
			br {
				display: none; 
			}

			h2 {
				margin-bottom: 24px; 
			}

			h1 {
				margin-bottom: 27px; 
			}

			p {
				font-size: 16px;
			}

			&--primary {
				p {
					font-size: 16px;
				}

				.find-host-text {
					font-size: 16px;
				}
			}
		}

		/*  Section Iions  */ 
		&--icons {
			.section__ico {
				display: block; 
				transform: scale(.28); 

				&--size23 {
					top: 10px; 
					left: -175px;
				}

				&--size24 {
					top: 38%; 
					right: -190px;
				}

				&--size25 {
					top: 66.5%; 
					left: -180px;
				}

				&--size331 {
					top: 3.5%; 
					right: -132px;
					transform: scale(0.6); 
				}

				&--size35 {
					top: 35.8%; 
					left: -100px;
					transform: scale(0.65); 
					margin: 0;
				}

				&--size333 {
					top: auto;
					bottom: -9%;  
					left: -12px;
					transform: scale(0.63); 
				}
			}
		}

		/*  Section Size1  */ 
		&--size1 {
			padding: 20px 0; 

			.section__head {
				margin-bottom: 26px;
			}
		}

		/*  Section Size1  */ 
		&--size01 {
			padding: 20px 0; 

			.section__head {
				margin-bottom: 26px;
			}
		}

		/*  Section Size2  */ 
		&--size2 {
			padding: 20px 0; 

			.section__head {
				margin-bottom: 20px;
			}
		}

		/*  Section Size3  */ 
		&--size3 {
			padding: 20px 0; 

			.section__head {
				margin-bottom: 26px;
			}
		}

		/*  Section Size4  */ 
		&--size4 {
			padding: 23px 0; 

			.section__head {
				margin-bottom: 23px;
			}

			.section__actions {
				margin-top: 42px;
			}
		}

		/*  Section Size5  */ 
		&--size5 {
			padding: 20px 0; 

			.section__head {
				margin-bottom: 20px;
			}
		}

		/*  Section Size6  */ 
		&--size6 {
			padding: 28px 0; 

			.section__head {
				margin-bottom: 42px;
			}
		}

		/*  Section Size7  */ 
		&--size7 {
			padding: 15px 0 45px; 

			.section__head {
				margin-bottom: 12px;
			}
		}

		/*  Section Size11  */ 
		&--size11 {
			padding: 43px 0 20px; 

			.section__head {
				margin-bottom: 42px;
			}
		}

		/*  Section Size12  */ 
		&--size12 {
			padding: 19px 0 20px; 

			.section__head {
				margin-bottom: 15px;
			}

			.section__actions {
				margin: 15px 0 0; 
			}
		}

		/*  Section Size13  */ 
		&--size13 {
			padding: 23px 0 20px; 

			.section__head {
				margin-bottom: 15px;
			}

			.section__actions {
				margin: 16px 0 0; 
			}
		}

		/*  Section Size11  */ 
		&--size14 {
			padding: 23px 0 44px; 

			.section__head {
				margin-bottom: 42px;
			}

			.section__actions {
				margin: 29px 0 0; 
			}
		}

		/*  Section Size15  */ 
		&--size15 {
			padding: 20px 0 44px; 

			.section__head {
				margin-bottom: 21px;
			}

			.section__body {
				margin: 0 8px; 
			}
		}

		/*  Section Size8  */ 
		&--size8 {
			padding: 41px 0 20px; 

			.section__head {
				margin-bottom: 42px;
			}
		}

		/*  Section Size9  */ 
		&--size9 {
			padding: 21px 0 20px; 

			.section__head {
				margin-bottom: 25px;
			}

			.section__actions {
				margin: 14px 0 0; 
			}
		}

		/*  Section Size10  */ 
		&--size10 {
			padding: 21px 0 44px; 

			.section__head {
				margin-bottom: 25px;
			}

			.section__actions {
				margin: 28px 0 0; 
			}
		}

		/*  Section Form  */ 
		&--form {
			padding: 40px 0 23px; 

			.section__head {
				margin-bottom: 30px;
			}

			.section__aside {
				padding: 42px 0 0; 
				text-align: left;

				.section__head {
					margin-bottom: 25px;
				}

				.section__actions {
					text-align: center; 
					padding: 0;
					margin: 25px 0 0; 
				}
			}
		}

		/*  Section Steps  */ 
		&--steps {
			padding: 57px 0 20px; 
		}

		/*  Section Steps Alt  */ 
		&--steps-alt {
			padding: 40px 0; 
		}

		/*  Section Steps Alt  */ 
		&--yellow {
			padding: 50px 0; 
		}

		/*  Section Size26  */ 
		&--size26 {
			padding: 40px 0;

			.section__head {
				margin-bottom: 50px;
			}
		}

		/*  Section Size29  */ 
		&--size29 {
			padding: 40px 0;
		}

		/*  Section Hosts  */ 
		&--hosts {
			padding: 22px 0; 

			.section__head {
				margin-bottom: 45px; 

				h2 {
					font-size: 18px; 
					position: relative; 
					padding-bottom: 27px;

					i {
						position: absolute; 
						right: 0; 
						bottom: 0; 
						margin: 0;
						top: auto; 
					}
				}
			}
		}

		/*  Section Blue  */ 
		&--blue {
			padding: 22px 0; 

			.section__head {
				margin-bottom: 45px; 

				h2 {
					font-size: 18px; 
					position: relative; 
					padding-bottom: 27px;

					i {
						position: absolute; 
						right: 0; 
						bottom: 0; 
						margin: 0;
						top: auto; 
					}
				}
			}

			.article {
				.article__body {
					color: #FFB74B; 
				}
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Dark
\* ------------------------------------------------------------ */

.section-dark {
	background: #28306c; 
	color: #fff; 
	padding: 56px 0 69px; 
	position: relative; 
	overflow: hidden;

	.list-features {
		padding: 17px 0 0 3px; 
		margin-bottom: 0;
	}

	.section__content {
		strong {
			display: inline-block;
			margin-bottom: 37px;
			font-size: 1.875rem;
			letter-spacing: 0.015em;
		}

		p {
			font-size: 22px; 
			line-height: 1.2; 
			letter-spacing: 0;
		}
	}

	.section__ico {
		position: absolute; 

		&--size1 {
			top: -171px; 
			right: -2%;
		}

		&--size2 {
			top: 42%; 
			left: -5%;
		}

		&--size3 {
			bottom: -105px; 
			right: 2.5%;
		}
	}


	/*  Section Dark Primary  */
	&--primary {
		padding: 133px 0 150px; 
	}
}

@media #{$large-desktop} {
	.section-dark {
		.section__content {
			br {
				display: none;
			}
		}
	}
}

@media #{$mobile} {
	.section-dark {
		padding: 23px 0 24px; 

		.list-features {
			padding: 0;
		}

		.section__content {
			margin-bottom: 23px;

			strong {
				margin-bottom: 24px; 
			}

			p {
				font-size: 11px;
			}
		}

		.section__ico {
			transform: scale(.26); 

			&--size1 {
				top: -180px; 
				right: -59%;
			}

			&--size2 {
				top: 16.5%; 
				left: -60%;
				transform: scale(.29); 
			}

			&--size3 {
				bottom: 0;
				right: -60%;
				transform: scale(.29); 
			}
		}

		/*  Section Dark Primary  */
		&--primary {
			padding: 53px 0 27px; 
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Main
\* ------------------------------------------------------------ */

.section-main {
	position: relative;

	.section__ico {
		position: absolute; 

		&--size1 {
			opacity: .1;
			right: -60px; 
			top: 10.9%;
		}

		&--size2 {
			opacity: .1;
			left: -40px; 
			top: 31.2%;
		}

		&--size3 {
			opacity: .1;
			right: -5px; 
			top: 58%;
		}

		&--size4 {
			opacity: .1;
			left: -60px; 
			top: 84.6%;
		}
	}

	.section__aside {
		background: #f6f6f6; 
		margin: 0 -15px 0 -19px; 
		padding: 55px 60px 80px;  
		position: relative;
		z-index: 1;

		.btn-close {
			position: absolute; 
			top: 20px; 
			right: 20px; 
			display: none;
		}

		.section__aside-head {
			color: #28306c; 
			margin-bottom: 40px;
			
			h6 {
				margin-bottom: 0;
				letter-spacing: 0.025em;
			}

			.list-stars {
				margin: 0; 

				li + li {
					margin-left: 1px;
				}
			}
		}

		.section__aside-body {
			> h6 {
				color: #28306c; 
				font-weight: 700;
				letter-spacing: 0.025em;
				margin-bottom: 20px;
			}
		}

		.section__aside-actions {
			margin-top: 56px; 

			h2 {
				color: #28306c; 
				margin-bottom: 33px;

				span {
					display: block;
					font-size: 16px;
					font-weight: 500; 
					margin-bottom: 4px;
				}
			}

			.btn {
				display: block; 
				text-transform: uppercase; 
				font-size: 22px; 
				padding: 5px 10px; 
			}
		}

		.section__aside-foot {
			margin-top: 97px;
			color: #28306c; 

			h6 {
				font-weight: 700; 
				margin-bottom: 19px; 
			}

			ul {
				margin-bottom: 0; 
			}
		}
	}

	.section__body {
		padding-top: 58px;
	}

	.section__content {
		color: #28306c; 

		h2 {
			margin: 0; 

			+ p {
				margin: 36px 0 0; 
			}

			+ ul {
				margin: 30px 0 0; 
			}

			+ h6 {
				margin: 31px 0 0;
			}
		}

		p {
			letter-spacing: 0.025em;

			+ h2 {
				margin-top: 58px;
			}
		}

		ul {
			+ h2 {
				margin-top: 62px;
			}

			+ h6 {
				margin: 39px 0 0;
			}
		}

		h6 {
			font-weight: 700; 
			letter-spacing: 0.05em;

			+ ul {
				margin: 20px 0 0; 
			}

			+ p {
				margin: 20px 0 0; 
			}
		}
	}

	.section__actions-alt {
		position: fixed; 
		bottom: 0; 
		left: 0; 
		width: 100%; 
		z-index: 100; 
		background: #363e76; 
		color: #fff; 
		padding: 16px 0; 

		a {
			text-decoration: none;
			text-transform: uppercase; 
			font-weight: 700; 
			font-size: 19px;
		}
	}
}

@media #{$large-desktop} {
	.section-main {
		.section__aside {
			margin: 0 0 0 -65px;
			padding: 70px 20px 80px;
		}
	}
}

@media #{$small-desktop} {
	.section-main {
		.section__ico {
			display: none;
		}
	}
}

@media #{$mobile} {
	.section-main {
		.section__body {
			padding-top: 27px;
		}

		.section__content {
			h2 {
				+ p {
					margin: 24px 0 0; 
				}

				+ ul {
					margin: 19px 0 0; 
				}

				+ h6 {
					margin: 18px 0 0; 
				}
			}

			p {
				+ h2 {
					margin: 44px 0 0; 
				}
			}

			ul {
				+ h2 {
					margin: 43px 0 0; 
				}

				+ h6 {
					margin: 32px 0 0; 
				}
			}

			h6 {
				+ ul {
					margin: 16px 0 0; 
				}

				+ p {
					margin: 16px 0 0; 
				}
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Small
\* ------------------------------------------------------------ */

.section-small {
	background: #28306c; 
	color: #fff;
	padding: 29px 0 27px; 

	.section__content {
		h6 {
			text-transform: uppercase; 
			margin-bottom: 17px;
			font-weight: 700; 
			letter-spacing: 0.025em;
		}

		.list-locations {
			margin-bottom: 23px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Image
\* ------------------------------------------------------------ */

.section-image {
	position: relative; 

	.section__image {
		position: relative; 
		background-size: cover; 
		padding-top: 57.3%;
		overflow: hidden; 

		&:after {
			content: '';
			position: absolute; 
			bottom: 100%; 
			left: 0; 
			width: 100%; 
			height: 10px; 
			box-shadow: 0 0 100px 280px rgba(255,255,255,1) 
		}
	}

	.section__inner {
		position: absolute; 
		top: 177px; 
		left: 0; 
		width: 100%; 
		z-index: 10;
	}

	.section__content {
		h2 {
			margin: 0;
		}
	}
}

@media #{$tablet-portrait} {
	.section-image {
		.section__image {
			&:after {
				box-shadow: 0 0 100px 130px rgba(255,255,255,1) 
			}
		}

		.section__inner {
			top: 70px;
		}
	}
}

@media #{$mobile} {
	.section-image {
		.section__image {
			padding-top: 139%; 

			&:after {
				box-shadow: 0 0 48px 75px rgba(255,255,255,1) 
			}
		}

		.section__inner {
			top: 70px;
			top: 34px;
		}

		.section__content {
			padding: 0 20px; 

			br {
				display: none;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Def
\* ------------------------------------------------------------ */

.section-def {
	padding: 200px 0 46px; 
	position: relative;

	.breadcrumps {
		padding-left: 50px;
	}

	.section__ico {
		opacity: .15; 
		position: absolute; 
		transform: scale(0.78); 

		&--size29 {
			top: 5.8%; 
			left: -107px;
		}

		&--size30 {
			top: 24.5%; 
			left: 50%;
			margin-left: -121px;
		}

		&--size31 {
			opacity: 1; 
			top: 43.1%; 
			left: -122px;
			transform: scale(0.84); 
		}

		&--size32 {
			opacity: 1; 
			top: 54.5%; 
			left: 50%;
			margin-left: 29px;
			transform: scale(0.76); 
		}

		&--size33 {
			opacity: 1; 
			bottom: 2.3%; 
			left: 50%;
			margin-left: 249px;
			transform: scale(0.79); 
		}

		&--size34 {
			opacity: 1; 
			bottom: -3.7%; 
			left: 46px;
			transform: scale(0.81); 
		}
	}

	.articles-secondary {
		padding-left: 25px;
	}

	.section__aside {
		margin: 0 -6px 0 -5px; 
	}

	.sectiom__image {
		margin-bottom: 33px;

		img {
			width: 100%;
		}
	}

	.section__body {
		padding: 0 75px 0 50px; 
	}
}

@media #{$small-desktop} {
	.section-def {
		.section__body {
			padding: 0;
		}

		.breadcrumps {
			padding-left: 0;
		}
	}
}

@media #{$mobile} {
	.section-def {
		padding: 29px 0 46px; 

		.list-links {
			margin: 0 0 30px; 
		}

		.articles-secondary {
			padding: 0;
		}

		.section__aside {
			margin: 0 -15px; 
		}

		.section__ico {
			transform: scale(.44); 

			&--size29 {
				top: 4.2%;
				left: -142px; 
			}

			&--size291 {
				top: 10.4%;
				right: -63px; 
				transform: scale(.67); 
			}

			&--size30 {
				left: auto;
				top: 11.4%;
				right: -123px; 
				opacity: 0;
			}

			&--size333 {
				top: 17.7%;
				left: -36px; 
			}

			&--size31 {
				top: 25.1%;
				left: -122px; 
			}

			&--size32 {
				top: 32.7%;
				left: 50px; 
				margin: 0;
			}

			&--size322 {
				top: 41.7%;
				left: -111px; 
				margin: 0;
				opacity: 1; 
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Secondary
\* ------------------------------------------------------------ */

.section-secondary {
	padding: 45px 0; 

	.widget-total {
		padding: 60px 0 0 18px; 
	}
}

@media #{$mobile} {
	.section-secondary {
		.widget-total {
			padding: 40px 0 0; 
		}
	}	
}

.section__ico {
	z-index: -1;
}
