@import '../common-variables.scss';

.change-people-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	.sel-box {
		position: relative;
		display: inline-block;

		.sel-icon {
			width: 23px;
			height: 28px;
			position: absolute;
			display: inline-block;
			top: 6px;
			right: 6px;
			background:url($suitcase-page-sprite + '#icon-arrow-down-blue') right no-repeat;
			pointer-events: none;

			&.up {
				-webkit-transform: rotate(180deg);
				transform: rotate(180deg);
			}
		}
	}

	.sel-children , .sel-adults, .options-list {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		display: inline-block;
		font-family: Poppins, sans-serif;
		font-size: 17px;
		text-align: center;
		align-items: center;
		width: 176px;
		height: 40px;
		margin-bottom: 10px;
		border: 1px solid var(--blue-basic);
		padding: 8px;
		color: var(--blue-basic);
		cursor: pointer;
		background-color: var(--white);

		.options {
			background-color: var(--white);
			color: var(--blue-basic);
			padding: 5px;
			width: 100%;
			display: inline-block;
			border-left: 1px solid var(--blue-basic);
			border-right: 1px solid var(--blue-basic);

			&:nth-last-child(1) {
				border-bottom: 1px solid var(--blue-basic);
			}

			&.selected,
			&:focus,
			&:hover,
			&:active {
				background-color: var(--blue-basic);
				color: var(--white);
			}
		}
	}

	.options-list {
		position: absolute;
		top: 40px;
		left: 0;
		display: flex;
		flex-direction: column;
		z-index: 1;
		padding: 0;
		border-bottom: none;
		border-left: none;
		border-right: none;
	}
}