textarea {
	border-radius: 0;
}

/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form {
	.form__field {
		width: 100%; 
		background: none; 
		border-radius: 0;
		border: none;
		border-bottom: 2px solid #28306c;
		padding: 0 7px 7px;
		color: #28306c; 
		font-weight: 500; 
		letter-spacing: 0.025em;
	}

	.form__row {
		display: flex;
		margin: 0 -45px; 

		+ .form__row {
			margin-top: 49px;
		}

		+ .form__row--size1 {
			margin-top: 24px;
		}

		+ .checkbox {
			margin-top: 17px;
		}

		&--alt {
			margin: 0; 
			display: block;
		}
	}
	
	.form__col {
		flex: 0 0 50%;
		max-width: 50%; 
		padding: 0 45px; 
	}

	.form__actions {
		margin-top: 45px;

		p {
			margin: 23px 0 0; 
		}
	}
}

@media #{$small-desktop} {
	.form {
		.form__row {
			margin-left: -15px; 
			margin-right: -15px; 

			.form__col {
				padding: 0 15px; 
			}

			&--alt {
				margin-left: 0; 
				margin-right: 0;
			}
		}

		.form__actions {
			br {
				display: none;
			}
		}
	}
}

@media #{$tablet-portrait} {
	.form {
		.form__row {
			display: block; 
			margin-left: 0; 
			margin-right: 0;

			.form__col {
				padding: 0;
			}

			+ .form__row {
				margin: 30px 0 0;
			}
		}

		.form__col {
			max-width: 100%;

			+ .form__col {
				margin-top: 30px;
			}
		}
	}
}

@media #{$mobile} {
	.form {
		.form__field {
			padding: 0 4px 4px; 
			border-width: 1px;
		}

		.form__row {
			+ .form__row {
				margin-top: 23px;
			}

			+ .checkbox {
				margin: 23px 0 0;
			}
		}

		.form__col {
			+ .form__col {
				margin-top: 23px;
			}
		}

		.form__actions {
			margin: 20px 0 0;

			p {
				margin: 11px 0 0; 
			}

			.btn {
				display: block; 
				width: 100%;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Form Subscribe
\* ------------------------------------------------------------ */

.form-subscribe {
	background: #e84042; 
	position: relative;
	text-align: center; 
	padding: 57px 0 60px; 

	.list-circles {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);  
		z-index: 5;
		margin-left: 6px;
	}

	.row {
		position: relative; 
		z-index: 15;
	}

	.form__content {
		color: #fff;
		margin-bottom: 42px; 

		strong {
			display: inline-block;
			font-size: 1.875rem;
			margin-bottom: 30px;
		}

		p {
			font-size: 1.375rem;
			line-height: 1.2;
			letter-spacing: 0;
			margin: 0;
		}
	}

	.form__body {
		max-width: 270px; 
		margin: auto; 
	}

	.form__field {
		width: 100%; 
		background: none; 
		border-radius: 0;
		border: none;
		border-bottom: 2px solid #fff;
		padding: 0 37px 10px 7px;
		color: #fff; 
		font-weight: 500; 
		letter-spacing: 0.025em;
	}

	.form__row {
		position: relative; 

		+ .form__row {
			margin-top: 47px;
		}
	}

	.form__btn {
		background: none; 
		border: none;
		position: absolute; 
		bottom: 8px; 
		right: 0;
	}

	/*  Form Subscribe Primary  */
	&--primary {
		padding: 33px 0 11px; 

		.list-circles {
			li {
				+ li {
					margin-left: 43px;
				}
			}
		}

		.form__content {
			color: #29316A; 
			margin-bottom: 34px;

			b {
				display: block;
				margin-bottom: 10px;
				font-size: 20px;
			}

			p {
				font-size: 15px; 
			}
		}

		.form__field {
			color: #29316A; 
			border-color: #29316A;
			font-size: 14px;
			border-width: 1px;
			padding: 0 0 5px; 
		}

		.form__actions,
		.form__foot,
		.form__content,
		.form__body {
			position: relative; 
			z-index: 10; 
		}

		.form__link {
			text-decoration: none; 
			font-size: 12px;
			font-family: 'Helvetica', sans-serif;
			font-weight: 400;  
		}

		.form__foot {
			margin: 34px 0 0; 

		}

		.form__body {
			display: flex;
			max-width: 100%;
			padding: 0 63px; 

			.form__row {
				flex: 0 0 50%;
				max-width: 50%; 
				padding: 0 16px; 

				+ .form__row {
					margin: 0;
				}
			}
		}

		.form__btn {
			right: 15px; 
			bottom: 5px; 
		}
	}

	/*  Form Subscribe Primary Alt  */
	&--primary-alt {
		position: relative; 
		text-align: left; 
		padding: 58px 43px 40px; 
		overflow: hidden; 

		.form__ico-alt {
			position: absolute; 
			bottom: -53px; 
			right: -17px;
		}

		.form__ico {
			position: absolute; 
			top: 5px; 
			right: 0;
		}

		.form__body {
			display: block; 
			padding: 0;
			margin: 0;
			
			.form__row {
				max-width: 100%; 
				padding: 0;

				+ .form__row {
					margin-top: 20px;
				}
			}
		}

		.form__actions {
			margin: 37px 0 0;
			text-align: center; 

			.btn {
				width: 100%;
			}
		}


		.form__content {
			margin: 0 0 29px;

			strong {
				display: block;
				font-size: 20px;
				margin-bottom: 18px;
			}

			p {
				font-size: 14px;
				line-height: 1.3;
			}
		}
	}
}

@media #{$large-desktop} {
	.form-subscribe {
		&--primary {
			.list-circles {
				margin: 0; 

				li + li {
					margin-left: 30px;
				}
			}
		}

		&--primary-alt {
			.form__ico {
				width: 91px;
				height: 74px; 
			}
		}
	}
}

@media #{$small-desktop} {
	.form-subscribe {
		&--primary-alt {
			br {
				display: none; 
			}
		}
	}
}

@media #{$tablet-portrait} {
	.form-subscribe {
		&--primary {
			padding: 30px 20px 10px; 

			br {
				display: none; 
			}

			.list-circles {
				margin: 0; 

				li + li {
					margin-left: 30px;
				}
			}
		}

		&--primary-alt {
			padding: 70px 20px 30px; 

			.form__ico-alt {
				right: -60px; 
				bottom: -100px; 
			}
		}
	}
}

@media #{$mobile} {
	.form-subscribe {
		text-align: left;
		padding: 24px 0 26px; 

		.form__content {
			margin-bottom: 23px; 

			br {
				display: none;
			}

			h2 {
				padding-right: 50px;
				margin-bottom: 21px;
			}

			p {
				font-size: 13px;
			}
		}

		.form__body {
			max-width: 100%;
		}

		.form__field {
			border-width: 1px;
			padding: 0 0px 5px;
			font-size: 13px; 
		}

		.form__btn {
			bottom: 5px;
		}

		.form__row + .form__row {
			margin-top: 22px; 
		}

		&--primary-alt {
			padding: 32px 20px 23px; 
			text-align: center; 
			margin: 0 -15px; 
			
			.form__content {
				color: #fff;
				padding: 0 10px; 
				margin-bottom: 39px;

				h2 {
					font-size: 18px; 
					line-height: 1.11; 
					padding: 0;
				} 

				p {
					font-size: 12px; 
					line-height: 1.25; 
				}
			}

			.form__body {
				padding: 0 30px; 
			}

			.form__field {
				border-color: #fff;
				color: #fff;
				text-align: center; 
			}

			.form__actions {
				margin: 24px 0 0; 

				.btn {
					display: block;
					width: 209px; 
					margin: 0 auto 10px; 
					font-size: 12px;
				}

				.form__link {
					color: #fff; 
				}
			}

			.form__row + .form__row {
				margin: 23px 0 0; 
			}
		}
	}
}


/* ------------------------------------------------------------ *\
	Form Payment
\* ------------------------------------------------------------ */

.form-payment {
	color: #343333; 
	position: relative; 
	padding-right: 140px;

	&:after {
		content: '';
		position: absolute; 
		top: -4px; 
		bottom: -4px; 
		right: 38px; 
		width: 1px; 
		background: #707070;
	}
	
	h2 {
		font-weight: 500;
	}

	label {
		display: block; 
		font-size: 20px; 
		margin: 0 0 6px; 
		color: #585858; 
	}

	.form__field {
		width: 100%; 
		border: 1px solid #707070;
		border-radius: 2px; 
		height: 60px;
		font-size: 20px; 
		color: #C7C7C7; 
		padding: 0 15px; 
	}

	.form__head {
		margin-bottom: 62px;
	}

	.form__row {
		+ .form__row {
			margin-top: 40px;
		}

		&--primary {
			display: flex;
			margin-left: -13px; 
			margin-right: -13px; 
			
			.form__col {
				padding: 0 13px; 
			}
		}
	}

	.form__row-inner {
		display: flex;
		margin-left: -5px; 
		margin-right: -5px; 

		.form__col {
			padding: 0 5px; 
		}

	}

	.form__col {
		&--1of2 {
			flex: 0 0 50%;
			max-width: 50%; 
		}

		&--size1 {
			flex: 0 0 19.5%;
			max-width: 19.5%;
		}

		&--size2 {
			flex: 0 0 80.5%;
			max-width: 80.5%;
		}

		&--size3 {
			flex: 0 0 57%;
			max-width: 57%;
		}

		&--size4 {
			flex: 0 0 43%;
			max-width: 43%;
		}

		&--size5 {
			flex: 0 0 55%;
			max-width: 55%;
		}

		&--size6 {
			flex: 0 0 45%;
			max-width: 45%;
		}
	}

	.form__actions {
		text-align: center; 
		margin: 50px 0 0; 

		p {
			color: #585858; 
			font-size: 15px; 
			letter-spacing: 0;
			margin: 0 0 10px; 

			a {
				text-decoration: underline;
			}
		}

		.btn {
			width: 100%;
			font-size: 20px;
			padding: 15px 18px; 
			box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
		}
	}
}

@media #{$small-desktop} {
	.form-payment {
		padding-right: 80px;

		label {
			font-size: 16px;
		}

		.form__field {
			font-size: 17px;
		}

		.form__col {
			&--size1 {
				max-width: 30%; 
				flex: 0 0 30%;
			}

			&--size2 {
				max-width: 70%; 
				flex: 0 0 70%;
			}
		}
	}
}

@media #{$tablet-portrait} {
	.form-payment {
		padding-right: 0; 

		&:after {
			right: -15px;
		}

		.form__row {

			+ .form__row {
				margin-top: 30px;
			}

			&--primary {
				display: block;
			}
		}

		.form__col {
			max-width: 100%;

			+ .form__col {
				margin-top: 30px;
			}
		}

		.form__row-inner {
			display: block;
		}
	}
}

@media #{$mobile} {
	.form-payment {
		border-bottom: 1px solid #707070;
		padding-bottom: 40px;

		&:after {
			display: none;
		}

		label {
			font-size: 13px; 
		}

		.form__field {
			height: 35px;
			font-size: 13px; 
		}

		.form__row {

			+ .form__row {
				margin-top: 20px;
			}
		}

		.form__col {
			+ .form__col {
				margin-top: 20px;
			}
		}

		.form__head {
			margin-bottom: 30px;
		}

		.form__actions {
			margin: 35px 0 0;

			p {
				font-size: 13px; 
			}

			.btn {
				font-size: 13px; 
				padding: 9px 10px; 
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Form Code
\* ------------------------------------------------------------ */

.form-code {
	position: relative;
	padding-right: 0; 

	&:after {
		display: none; 
	}

	.form__body {
		position: relative; 
		padding-right: 167px; 

		.form__btn {
			position: absolute; 
			bottom: 0; 
			right: 0;
			background: #F0D330; 
			border: 2px solid #F0D330;
			min-width: 155px;
			font-size: 20px;
			height: 60px; 
			line-height: 55px;
			padding-top: 0; 
			padding-bottom: 0;
			transition: background .4s, color .4s; 
			font-weight: 700;
			box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

			&:hover {
				background: #fff; 
				color: #F0D330;
			}
		}
	}

	.form__foot {
		margin: 12px 0 0; 

		p {
			font-size: 15px; 
			line-height: 1.2;
			color: #585858; 
			margin: 0;
			letter-spacing: 0;
		}
	}

	.form__inner {
		opacity: 0; 
		visibility: hidden; 
		transition: opacity .4s, visibility .4s; 
	}

	.form__overlay {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);  
		margin-top: -39px;
		transition: opacity .4s, visibility .4s; 

		.btn {
			font-size: 20px; 
			font-weight: 500; 
			background: #128C8F; 
			border-color: #128C8F;
			min-width: 280px;
			padding: 12px 18px; 
			box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

			&:hover {
				background: #fff; 
				color: #128C8F;
			}
		}
	}

	&.active {
		.form__overlay {
			opacity: 0; 
			visibility: hidden; 
		}

		.form__inner {
			opacity: 1; 
			visibility: visible; 
		}
	}
}

@media #{$tablet-portrait} {
	.form-code {
		.form__body {
			padding-right: 125px; 

			.form__btn {
				min-width: 115px;
			}
		}
	}
}

@media #{$mobile} {
	.form-code {
		border: none;
		padding-bottom: 0; 
		
		.form__body {
			padding-right: 100px;

			.form__btn {
				min-width: 80px;
				height: 35px; 
				font-size: 11px; 
				line-height: 32px; 
			}
		}

		.form__foot {
			p {
				font-size: 13px; 
			}
		}
	}
}
