.preference-center {
	.preference-center-header {
		.hero-img-block {
			position: relative;
			width: 100%;
			height: 60vh;
			text-align: center;

			&__title {
				position: absolute;
				bottom: -50px;
				left: 0;
				right: 0;
				z-index: 1;
				margin: 0 0 0 0;
				color: #ffffff;
				font-size: 4.375rem;

				span {
					display: block;
					color: #fab64e;
					font-family: Poppins-Medium, sans-serif;
					font-weight: normal;
					font-size: 7.875rem;
				}
			}

			img {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.icon-letter {
				display: block;
				width: 200px;
				height: 180px;
				margin: 10px auto 0;
				background-image: url('/theme/_build/assets/images/temp/icon-letter.png');
				background-size: cover;
			}

			div {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.3);
			}
		}

		.blue-bar {
			width: 100%;
			height: 90px;
			background-color: var(--color-primary);
		}
	}

	.preference-center-main {
		position: relative;
		max-width: 890px;
		margin: 0 auto;
		padding: 60px 0;

		._form._form_13._inline-form._dark {
			width: auto !important;
			margin: 0 0 0 0 !important;
			padding: 0 0 0 0 !important;
			font-family: Poppins-Medium, sans-serif !important;

			._button-wrapper {
				button {
					display: block;
					margin: 0 auto;
				}
			}
		}
	}
}

.ico-circle-yellow {
	position: absolute;
	z-index: -1;
	width: 700px;
	height: 777px;
	background-image: url('/theme/_build/assets/images/temp/ico-circle-yellow.svg');
	opacity: 0.1;
}

.ico-circle-yellow.top {
	top: -10%;
	left: -35%;
}

.ico-circle-yellow.center {
	top: 40%;
	left: 45%;
}

.ico-circle-yellow.bottom {
	bottom: -8%;
	left: 25%;
}
