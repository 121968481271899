.main-img {
	position: relative;
	width: 100%;
	height: calc(100vh - 250px);
	min-height: 375px;
	margin: 0 0 50px 0;
	padding: 49px 0 0 0;
	background-color: var(--blue-basic);

	source,
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		opacity: 0.7;
	}

	&__title {
		position: absolute;
		top: 50%;
		left: 50%;
		color: #fff;
		font-family: Poppins-Medium, sans-serif;
		font-size: 52px;
		font-weight: normal;
		transform: translate(-50%, 0);
	}
}

.regist-agent {
	margin: 0 0 50px 0;
	border: 1px solid #ced4da;
	border-radius: 3px;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

	.g-recaptcha {
		width: fit-content;
		margin: 0 auto;
	}

	.success-message {
		margin: 0;
		padding: 40px;
		text-align: center;
	}

	.see__more {
		margin: 10px;
	}

	&__title {
		margin: 0;
		border-bottom: 1px solid #ced4da;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		padding: 15px 20px;
		color: #ffffff;
		background-color: var(--red-basic);
		font-size: 22px;
	}

	.form-alert-danger {
		display: block;
		width: 100%;
		text-align: right;
		font-size: 12px;
	}

	&__form {
		padding: 20px;

		.group-field {
			margin: 0 0 50px 0;

			h3 {
				margin: 0 0 20px 0;
				border-bottom: 1px solid #ced4da;
				padding: 0 0 10px 0;
				font-size: 18px;
				font-weight: bold;
			}
		}

		.field {
			position: relative;
			margin: 0 0 20px 0;

			label {
				display: block;
				margin: 0 0 5px 5px;

				.required {
					color: var(--red-basic);
				}
			}

			input,
			select,
			textarea {
				width: 100%;
				padding: 10px;
				border: 1px solid #ced4da;
				border-radius: 3px;
				color: var(--blue-basic);
				font-size: 14px;

				&:-webkit-autofill {
					color: var(--blue-basic);
				}
			}

			select[name="commission_model"] {
				padding-right: 30px;
			}

			textarea {
				height: 102px;
				resize: none;
			}

			&.phone {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				label {
					width: 100%;
				}

				.intl-tel-input,
				#phone_code {
					width: 10%;
				}

				#phone_number {
					width: 77%;
				}
			}

			&.notification {
				& > p {
					margin: 0 0 5px 0;

					.required {
						color: var(--red-basic);
					}
				}

				.radio-section {
					.radio-box {
						margin: 0 0 5px 0;

						input {
							display: none;

							&:checked ~ .checkmark {
								border: 1px solid var(--green-basic);
								background-color: #118c8f50;

								&:after {
									content: 'checked';
									position: absolute;
									right: 5px;
									bottom: 0;
									color: var(--green-basic);
									font-size: 12px;
								}
							}
						}

						.checkmark {
							position: relative;
							display: block;
							border: 1px solid #ced4da;
							border-radius: 3px;
							padding: 10px;
							background-color: #f4f4f4;
							font-size: 14px;
							cursor: pointer;

							&:hover {
								border: 1px solid var(--green-basic);
								background-color: #118c8f50;
							}
						}
					}
				}
			}

			&.confirm {
				label {
					display: flex;
					flex-direction: row-reverse;
					align-items: center;
					justify-content: flex-end;
					margin: 0;

					&:hover .checkmark {
						border: 2px solid var(--green-basic);
						background-color: #118c8f50;
					}

					.link-to-term-cond {
						color: var(--green-basic);
					}

					input {
						display: none;

						&:checked ~ .checkmark {
							border: 2px solid var(--green-basic);
							background: #118c8f50 url('../../_build/assets/images/cross-icon-small.png') no-repeat
								center;
						}
					}

					.checkmark {
						flex-shrink: 0;
						display: block;
						width: 20px;
						height: 20px;
						margin: 0 10px 0 0;
						border: 2px solid var(--red-basic);
						border-radius: 3px;
					}
				}
			}

			& > i {
				position: absolute;
				top: 55%;
				right: 10px;
				transition: .5s;
				pointer-events: none;
			}
		}

		.save-travel-agent-btn {
			display: block;
			margin: 0 auto;
			border: 2px solid var(--red-basic);
			border-radius: 3px;
			padding: 8px 20px;
			color: var(--red-basic);
			background-color: #ffffff;

			&:hover {
				color: #ffffff;
				background-color: var(--red-basic);
			}
		}

		.message-error {
			width: 100%;
		}
	}
}

@media screen and (min-width: 768px) {
	.main-img {
		min-height: 500px;
		padding: 0;

		&__title {
			font-size: 100px;
		}
	}

	.regist-agent {
		&__title {
			padding: 15px 40px;
			font-size: 24px;
		}

		&__form {
			padding: 40px;

			.group-field {
				h3 {
					width: 100%;
				}

				&.personal,
				&.contact,
				&.account,
				&.commission,
				&.other .radio-section {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
				}

				&.personal {
					.field {
						&:nth-child(2),
						&:nth-child(3) {
							width: 48%;
						}

						&:nth-child(4),
						&:nth-child(5),
						&:nth-child(6) {
							width: 31%;
						}
					}
				}

				&.contact {
					.field {
						&:nth-child(2),
						&:nth-child(3) {
							width: 48%;
						}

						&:nth-child(4) {
							width: 100%;
						}
					}
				}

				&.account {
					.field {
						&:nth-child(2) {
							width: 100%;
						}

						&:nth-child(3),
						&:nth-child(4) {
							width: 48%;
						}
					}
				}

				&.commission {
					width: 100%;

					.field {
						.usa {
							&.full_address {
								min-width: 500px;
							}
						}
						.europe {
							min-width: 350px;
						}
						.china {
							min-width: 350px;
						}
						.rest {
							&.full_address {
								min-width: 500px;
							}
						}
						&:nth-child(2) {
							width: 100%;
						}
					}
				}

				&.other {
					.radio-section {
						justify-content: space-evenly;

						.radio-box {
							&:nth-child(1),
							&:nth-child(2),
							&:nth-child(3) {
								width: 31%;
							}
						}
					}
				}
			}

			.field {
				&.phone {
					#phone_number {
						width: 88%;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.main-img ~ .container {
		max-width: 718px;
	}
}

@media screen and (min-width: 1200px) {
	.regist-agent {
		&__form {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: baseline;

			.personal,
			.contact,
			.account,
			.other {
				width: 48%;
			}

			.save-travel-agent-btn {
				margin: 0 0 0 auto;
			}
		}
	}
}

@media screen and (min-width: 1600px) {
	.regist-agent {
		&__title {
			font-size: 28px;
		}

		&__form {
			font-size: 18px;

			.group-field {
				h3 {
					font-size: 22px;
				}
			}

			.field {
				input,
				select,
				textarea {
					font-size: 16px;
				}

				&.notification {
					.radio-section {
						.radio-box {
							.checkmark {
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}
}
