.back-to-top {
    display: none;
    position: fixed;
    right: 15px;
    top: 25%;
    z-index: 250;
    flex-direction: row-reverse;
    align-items: center;
    transition: 0.3s;

    &__text {
        display: none;
        padding: 0 15px 0 0;
        color: #118c8f;
    }

    &__btn {
        display: block;
        width: 50px;
        height: 50px;
        border: none;
        border-radius: 50%;
        background-color: #118c8f80;
        backdrop-filter: blur(25px);
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);

        &:focus {
            outline: none;
        }

        &:before {
            color: #ffffff;
            font-family: FontAwesome, sans-serif;
            font-size: 32px;
            content: '\f102 ';
        }
    }

    &.active {
        display: flex;
    }
}

@media screen and (min-width: 768px) {
    .back-to-top {
        &__btn {
            &:hover ~ .back-to-top--text {
                display: block;
            }
        }
    }
}
