.wrapper {
  display: flex;
  justify-content: center;
}

.error-page-holder {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: var(--blue-basic);
  padding: 100px 30px 0 30px;
  text-align: center;

  @media screen and (min-width: 768px) {
    padding: 0;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }

  .content-holder {
    margin-bottom: 30px;
    max-width: 400px;

    @media screen and (min-width: 1024px) {
      margin: 35px 0 0 0;
      max-width: 475px;
    }

    .code-holder {
      display: block;
      font-size: 50px;
      margin-bottom: 15px;
      font-family: Poppins-Bold, sans-serif;

      @media screen and (min-width: 1024px) {
        font-size: 80px;
        text-align: center;
        margin-bottom: 30px;
      }
    }

    .error-description, .advice-description {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 30px;
      font-family: Poppins-Regular, sans-serif;

      @media screen and (min-width: 1024px) {
        text-align: center;
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 30px;
      }
    }

    .section__actions {
      .btn {
        font-family: Poppins-Medium,sans-serif;
        display: inline-block;
        width: 100%;
        margin: 0;
        border: 2px solid var(--yellow-basic);
        padding: 10px 20px;
        background-color: var(--yellow-basic);
        color: var(--blue-basic);
        font-size: 18px;
        text-decoration: none;
        text-transform: uppercase;
        transition: .5s;

        &:hover {
          color: var(--yellow-basic);
          background: var(--blue-basic)
        }

        @media screen and (min-width: 1024px) {
          font-size: 21px;
        }
      }
    }
  }

  .picture-holder {
    @media screen and (min-width: 1024px) {
      max-height: 450px;
      margin-right: 100px;
    }

    .sing-picture {
      width: 185px;
      height: 235px;
      margin: 0 auto;
      background: url($page-404 + '#404-sign') center / contain no-repeat;

      @media screen and (min-width: 1024px) {
        width: 312px;
        height: 478px;
      }
    }
  }
}