/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */

.wrapper {
	min-height: 100vh; 
	overflow: hidden; 
	position: relative; 

	&:after {
		content: '';
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
		background: #fff;  
		z-index: 200;
		opacity: 0; 
		visibility: hidden; 
		transition: opacity .4s, visibility .4s; 
	}

	&.active {
		&:after {
			opacity: .75; 
			visibility: visible; 
		}
	}

	/*  Wrapper Primary  */
	&--primary {
		padding-top: 93px; 
	}
}


@media #{$mobile} {
	.wrapper {
		/*  Wrapper Primary  */
		&--primary {
			padding-top: 49px; 
		}

		/*  Wrapper Secondary  */
		&--secondary {
			padding-top: 49px; 
		}	
	}
}
